/** @format */

import {
  faComments,
  faEnvelope,
  faPhoneSlash,
  faPhoneVolume,
} from '@fortawesome/free-solid-svg-icons';
import {
  faMessageCheck,
  faMessageSms,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Avatar,
  ButtonGroup,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { api, getApiUrl } from '../../../api.ts';
import { getEventActionIcon } from '../../../utils/constants';
import { parseFieldError } from '../../../utils/errors';
import BoxLoader from '../../misc/BoxLoader';
import NewTooltip from '../../misc/NewTooltip';

function CommentListTooltip({ events, loading }) {
  return (
    <BoxLoader loading={loading}>
      <Box>
        <Typography variant="h5" px={2} pt={2}>
          Commentaires & évènements :
        </Typography>
        <List
          sx={{
            width: '100%',
            height: 300,
            maxHeight: 300,
            overflowY: 'auto',
            px: 2,
            pb: 2,
          }}
          dense
        >
          {events.map((event) => (
            <Fragment key={event.id}>
              <ListItem
                key={event.id}
                secondaryAction={
                  <FontAwesomeIcon
                    icon={getEventActionIcon(event.action)}
                    size="1x"
                  />
                }
                disableGutters
                sx={{
                  pr: 3,
                  color:
                    event.action &&
                    (event.action.is_mail_tried ||
                      event.action.is_text_message_tried)
                      ? 'error.main'
                      : undefined,
                }}
              >
                <ListItemAvatar
                  sx={{
                    minWidth: 32,
                  }}
                >
                  <NewTooltip
                    content={`${
                      event.user.first_name
                    } ${event.user.last_name.toUpperCase()}`}
                  >
                    <Avatar
                      alt={`${
                        event.user.first_name
                      } ${event.user.last_name.toUpperCase()}`}
                      src={`${getApiUrl()}/avatar/${event.user.id}`}
                      sx={{ width: 24, height: 24 }}
                    />
                  </NewTooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <NewTooltip
                          content={
                            event.action &&
                            (event.action.is_mail_sent ||
                              event.action.is_text_message_sent ||
                              event.action.is_mail_tried ||
                              event.action.is_text_message_tried)
                              ? event.comment
                              : ''
                          }
                        >
                          <Typography
                            sx={{
                              fontStyle:
                                event.action && event.action.is_comment
                                  ? 'italic'
                                  : 'normal',
                            }}
                          >
                            {event.action && event.action.is_comment ? (
                              event.comment
                            ) : (
                              <Typography
                                color={
                                  event.action &&
                                  (event.action.is_mail_tried ||
                                    event.action.is_text_message_tried)
                                    ? 'error'
                                    : 'default'
                                }
                                component="span"
                              >
                                {event.action ? event.action.name : ''}
                              </Typography>
                            )}
                          </Typography>
                        </NewTooltip>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ color: 'text.primary' }}>
                          {moment(event.datetime).format('DD/MM/YYYY LT')}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
          {!loading && (!events || events.length < 1) && (
            <Alert severity="info">
              Aucun commentaire ou évènement n&apos;a été enregistré pour ce
              lead.
            </Alert>
          )}
        </List>
      </Box>
    </BoxLoader>
  );
}

CommentListTooltip.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

function LeadCommentDialog({ lead, setModalOpened }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [currentComment, setCurrentComment] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Loads comments from the API
   */
  const loadComments = () => {
    setLoading(true);
    api
      .get(`/events/?lead=${lead.id}`)
      .then((result) => {
        setComments(result.data.results);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };

  /**
   * Handles dialog opening
   */
  const handleOpen = () => {
    loadComments();
    setOpen(true);
  };

  /**
   * Handles dialog closing
   */
  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  /**
   * Handles comment change
   * @param event {Object} Event
   */
  const handleCommentChange = (event) => {
    setCurrentComment(event.target.value);
  };

  /**
   * Adds a communication event
   * @param action {string} The action
   */
  const addCommunicationEvent = (action) => {
    setLoading(true);
    const data = {
      lead: lead.id,
      action,
    };
    if (
      lead.current_follow_up_status.is_no_follow_up === true ||
      (lead.current_follow_up_status.is_no_follow_up === false &&
        action !== 'communication') ||
      action !== 'voicemail'
    ) {
      api
        .post('/events/communication/', {}, data)
        .then((result) => {
          enqueueSnackbar('Cette information a bien été prise en compte.', {
            variant: 'success',
          });
          setLoading(false);
          loadComments();
        })
        .catch((err) => {
          const { message } = parseFieldError(err);
          if (message) enqueueSnackbar(message, { variant: 'error' });
          setLoading(false);
        });
    }
  };

  /**
   * Adds a comment
   */
  const addComment = () => {
    const data = {
      lead: lead.id,
      comment: currentComment,
    };
    api
      .post('/events/comment/', {}, data)
      .then(() => {
        setCurrentComment('');
        loadComments();
      })
      .catch((err) => {
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
      });
  };

  useEffect(() => {
    if (setModalOpened) {
      setModalOpened(open);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus
      >
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>Commentaires</Grid>
            <Grid item>
              <ButtonGroup
                variant="contained"
                color="secondary"
                fullWidth
                size="lg"
              >
                <NewTooltip content="Appel manqué" key="Appel manqué">
                  <Button onClick={() => addCommunicationEvent('missed_call')}>
                    <Typography sx={{ textAlign: 'center', minWidth: 60 }}>
                      <FontAwesomeIcon icon={faPhoneSlash} />
                    </Typography>
                  </Button>
                </NewTooltip>
                <NewTooltip content="Répondeur" key="Répondeur">
                  <Button onClick={() => addCommunicationEvent('voicemail')}>
                    <Typography sx={{ textAlign: 'center', minWidth: 60 }}>
                      <FontAwesomeIcon icon={faPhoneVolume} />
                    </Typography>
                  </Button>
                </NewTooltip>
                <NewTooltip content="Envoi SMS" key="Envoi SMS">
                  <Button
                    onClick={() => addCommunicationEvent('text_message_sent')}
                  >
                    <Typography sx={{ textAlign: 'center', minWidth: 60 }}>
                      <FontAwesomeIcon icon={faMessageSms} />
                    </Typography>
                  </Button>
                </NewTooltip>
                <NewTooltip content="Envoi mail" key="Envoi mail">
                  <Button onClick={() => addCommunicationEvent('mail_sent')}>
                    <Typography sx={{ textAlign: 'center', minWidth: 60 }}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Typography>
                  </Button>
                </NewTooltip>
                <NewTooltip
                  content="Communication établie"
                  key="Communication établie"
                >
                  <Button
                    onClick={() => addCommunicationEvent('communication')}
                  >
                    <Typography sx={{ textAlign: 'center', minWidth: 60 }}>
                      <FontAwesomeIcon icon={faMessageCheck} />
                    </Typography>
                  </Button>
                </NewTooltip>
              </ButtonGroup>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pl={1} pr={1}>
                <Typography variant="overline">Commentaires</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      {comments.map((comment) => (
                        <Grid item xs={12} key={comment.id}>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={12}>
                              <NewTooltip
                                content={
                                  comment.action &&
                                  (comment.action.is_mail_sent ||
                                    comment.action.is_text_message_sent ||
                                    comment.action.is_mail_tried ||
                                    comment.action.is_text_message_tried)
                                    ? comment.comment
                                    : ''
                                }
                                placement="top-start"
                              >
                                <Typography>
                                  {moment(comment.datetime).format(
                                    'DD/MM/YYYY LT',
                                  )}{' '}
                                  [
                                  {comment.user.first_name
                                    ? comment.user.first_name
                                    : comment.user.last_name}
                                  ] :{' '}
                                  {comment.action &&
                                  comment.action.is_comment ? (
                                    <span style={{ fontStyle: 'italic' }}>
                                      &laquo; {comment.comment} &raquo;
                                    </span>
                                  ) : (
                                    <Typography
                                      color={
                                        comment.action &&
                                        (comment.action.is_mail_tried ||
                                          comment.action.is_text_message_tried)
                                          ? 'error'
                                          : 'default'
                                      }
                                      component="span"
                                    >
                                      {comment.action
                                        ? comment.action.name
                                        : ''}
                                    </Typography>
                                  )}
                                </Typography>
                              </NewTooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid item xs={8}>
                        <TextField
                          variant="outlined"
                          value={currentComment}
                          onChange={handleCommentChange}
                          label="Commentaire"
                          size="small"
                          fullWidth
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && currentComment !== '') {
                              addComment();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addComment()}
                          size="medium"
                          disabled={!currentComment}
                        >
                          Ajouter un commentaire
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <NewTooltip
        placement="bottom"
        content={<CommentListTooltip events={comments} loading={loading} />}
        onOpen={loadComments}
        isCommentTooltip
      >
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpen}
        >
          <FontAwesomeIcon icon={faComments} />
        </IconButton>
      </NewTooltip>
    </>
  );
}

LeadCommentDialog.propTypes = {
  lead: PropTypes.shape().isRequired,
  setModalOpened: PropTypes.func,
};

LeadCommentDialog.defaultProps = {
  setModalOpened: null,
};

export default LeadCommentDialog;
