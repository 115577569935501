/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleReviewRequestStatus } from '../models/GoogleReviewRequestStatus';
import type { PaginatedGoogleReviewRequestStatusList } from '../models/PaginatedGoogleReviewRequestStatusList';
import type { PatchedGoogleReviewRequestStatus } from '../models/PatchedGoogleReviewRequestStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsGoogleReviewRequestStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedGoogleReviewRequestStatusList
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedGoogleReviewRequestStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-google-review-request-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns GoogleReviewRequestStatus
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusCreate({
        requestBody,
    }: {
        requestBody: GoogleReviewRequestStatus,
    }): CancelablePromise<GoogleReviewRequestStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-google-review-request-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GoogleReviewRequestStatus
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this google review request status.
         */
        id: number,
    }): CancelablePromise<GoogleReviewRequestStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-google-review-request-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GoogleReviewRequestStatus
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this google review request status.
         */
        id: number,
        requestBody: GoogleReviewRequestStatus,
    }): CancelablePromise<GoogleReviewRequestStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-google-review-request-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GoogleReviewRequestStatus
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this google review request status.
         */
        id: number,
        requestBody?: PatchedGoogleReviewRequestStatus,
    }): CancelablePromise<GoogleReviewRequestStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-google-review-request-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsGoogleReviewRequestStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this google review request status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-google-review-request-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
