/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { PaginatedBrandList } from '../models/PaginatedBrandList';
import type { PatchedBrand } from '../models/PatchedBrand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrandsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedBrandList
     * @throws ApiError
     */
    public brandsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedBrandList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brands/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public brandsCreate({
        requestBody,
    }: {
        requestBody: Brand,
    }): CancelablePromise<Brand> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brands/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public brandsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this brand.
         */
        id: number,
    }): CancelablePromise<Brand> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brands/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public brandsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this brand.
         */
        id: number,
        requestBody: Brand,
    }): CancelablePromise<Brand> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/brands/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public brandsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this brand.
         */
        id: number,
        requestBody?: PatchedBrand,
    }): CancelablePromise<Brand> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/brands/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public brandsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this brand.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/brands/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
