/** @format */

import { Box, Container, Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/animations/error.json';

class ErrorComponent extends Component {
  render() {
    return (
      <Container>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Box sx={{ marginBottom: -18 }}>
              <Lottie
                animationData={errorAnimation}
                loop
                autoplay
                style={{
                  width: 500,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} />
          <Grid item>
            <Typography
              variant="h2"
              color="primary"
              align="center"
              mt={6}
              gutterBottom
            >
              <strong>Oops !</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item>
            <Typography
              variant="h5"
              color="primary"
              align="center"
              gutterBottom
            >
              Une erreur est survenue lors de votre dernière action !
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
              Veuillez actualiser la page et contacter l&apos;équipe technique
              si le problème persiste.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default ErrorComponent;
