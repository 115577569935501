/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientConstructionDate } from '../models/ClientConstructionDate';
import type { PaginatedClientConstructionDateList } from '../models/PaginatedClientConstructionDateList';
import type { PatchedClientConstructionDate } from '../models/PatchedClientConstructionDate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConstructionDatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientConstructionDateList
     * @throws ApiError
     */
    public clientsConstructionDatesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientConstructionDateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-dates/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientConstructionDate
     * @throws ApiError
     */
    public clientsConstructionDatesCreate({
        requestBody,
    }: {
        requestBody: ClientConstructionDate,
    }): CancelablePromise<ClientConstructionDate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-construction-dates/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConstructionDate
     * @throws ApiError
     */
    public clientsConstructionDatesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this client construction date.
         */
        id: number,
    }): CancelablePromise<ClientConstructionDate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-dates/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientConstructionDate
     * @throws ApiError
     */
    public clientsConstructionDatesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client construction date.
         */
        id: number,
        requestBody: ClientConstructionDate,
    }): CancelablePromise<ClientConstructionDate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-construction-dates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConstructionDate
     * @throws ApiError
     */
    public clientsConstructionDatesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client construction date.
         */
        id: number,
        requestBody?: PatchedClientConstructionDate,
    }): CancelablePromise<ClientConstructionDate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-construction-dates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConstructionDatesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this client construction date.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-construction-dates/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
