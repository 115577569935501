/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventActionSiteCreated } from '../models/EventActionSiteCreated';
import type { PaginatedEventActionSiteCreatedList } from '../models/PaginatedEventActionSiteCreatedList';
import type { PatchedEventActionSiteCreated } from '../models/PatchedEventActionSiteCreated';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsEventActionSiteCreatedService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedEventActionSiteCreatedList
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedEventActionSiteCreatedList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-site-created/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns EventActionSiteCreated
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedCreate({
        requestBody,
    }: {
        requestBody: EventActionSiteCreated,
    }): CancelablePromise<EventActionSiteCreated> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events-event-action-site-created/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionSiteCreated
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event action site created.
         */
        id: number,
    }): CancelablePromise<EventActionSiteCreated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-site-created/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventActionSiteCreated
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action site created.
         */
        id: number,
        requestBody: EventActionSiteCreated,
    }): CancelablePromise<EventActionSiteCreated> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events-event-action-site-created/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionSiteCreated
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action site created.
         */
        id: number,
        requestBody?: PatchedEventActionSiteCreated,
    }): CancelablePromise<EventActionSiteCreated> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events-event-action-site-created/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsEventActionSiteCreatedDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this event action site created.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events-event-action-site-created/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
