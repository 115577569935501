/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CellType } from '../models/CellType';
import type { PaginatedCellTypeList } from '../models/PaginatedCellTypeList';
import type { PatchedCellType } from '../models/PatchedCellType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsCellTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedCellTypeList
     * @throws ApiError
     */
    public productsCellTypesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedCellTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-cell-types/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns CellType
     * @throws ApiError
     */
    public productsCellTypesCreate({
        requestBody,
    }: {
        requestBody: CellType,
    }): CancelablePromise<CellType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-cell-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CellType
     * @throws ApiError
     */
    public productsCellTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this cell type.
         */
        id: number,
    }): CancelablePromise<CellType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-cell-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CellType
     * @throws ApiError
     */
    public productsCellTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this cell type.
         */
        id: number,
        requestBody: CellType,
    }): CancelablePromise<CellType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-cell-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CellType
     * @throws ApiError
     */
    public productsCellTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this cell type.
         */
        id: number,
        requestBody?: PatchedCellType,
    }): CancelablePromise<CellType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-cell-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsCellTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this cell type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-cell-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
