/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientAdditionalDocs } from '../models/ClientAdditionalDocs';
import type { PaginatedClientAdditionalDocsList } from '../models/PaginatedClientAdditionalDocsList';
import type { PatchedClientAdditionalDocs } from '../models/PatchedClientAdditionalDocs';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsAdditionalDocsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientAdditionalDocsList
     * @throws ApiError
     */
    public clientsAdditionalDocsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientAdditionalDocsList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-additional-docs/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientAdditionalDocs
     * @throws ApiError
     */
    public clientsAdditionalDocsCreate({
        requestBody,
    }: {
        requestBody: ClientAdditionalDocs,
    }): CancelablePromise<ClientAdditionalDocs> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-additional-docs/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientAdditionalDocs
     * @throws ApiError
     */
    public clientsAdditionalDocsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this client additional docs.
         */
        id: number,
    }): CancelablePromise<ClientAdditionalDocs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-additional-docs/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientAdditionalDocs
     * @throws ApiError
     */
    public clientsAdditionalDocsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client additional docs.
         */
        id: number,
        requestBody: ClientAdditionalDocs,
    }): CancelablePromise<ClientAdditionalDocs> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-additional-docs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientAdditionalDocs
     * @throws ApiError
     */
    public clientsAdditionalDocsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client additional docs.
         */
        id: number,
        requestBody?: PatchedClientAdditionalDocs,
    }): CancelablePromise<ClientAdditionalDocs> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-additional-docs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsAdditionalDocsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this client additional docs.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-additional-docs/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
