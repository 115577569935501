/** @format */

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledBadgeRed = styled(Badge)(({ theme }) => ({
  '& > .MuiBadge-badge': {
    right: -9,
    top: '10%',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'red',
    color: 'white',
  },
}));

const StyledBadgeYellow = styled(Badge)(({ theme }) => ({
  '& > .MuiBadge-badge': {
    right: -9,
    top: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'yellow',
    color: 'black',
  },
}));

const StyledBadgeGreen = styled(Badge)(({ theme }) => ({
  '& > .MuiBadge-badge': {
    right: -9,
    top: '90%',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'green',
    color: 'white',
  },
}));

const countOverdueLeads = (leads) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return leads.filter((lead) => {
    const dueDate = new Date(lead.follow_up_task?.due_date);
    return (
      dueDate < today && lead.current_follow_up_status?.is_no_follow_up !== true
    );
  }).length;
};

const countSameDayOverdueLeads = (leads) => {
  const today = new Date().toDateString();
  return leads.filter((lead) => {
    const dueDate = new Date(lead.follow_up_task?.due_date).toDateString();
    return (
      dueDate === today &&
      lead.current_follow_up_status?.is_no_follow_up !== true
    );
  }).length;
};

const countDueThisWeekLeads = (leads) => {
  const today = new Date();
  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (7 - today.getDay()));

  return leads.filter((lead) => {
    const dueDate = new Date(lead.follow_up_task?.due_date);
    return (
      dueDate > today &&
      dueDate <= endOfWeek &&
      lead.current_follow_up_status?.is_no_follow_up !== true
    );
  }).length;
};

function FollowUpToggleButtonGroup({ leads, loading, view }) {
  const [viewMode, setViewMode] = useState(view);
  const navigate = useNavigate();

  const overdueLeadsCount = useMemo(() => countOverdueLeads(leads), [leads]);
  const sameDayOverdueLeadsCount = useMemo(
    () => countSameDayOverdueLeads(leads),
    [leads],
  );
  const dueThisWeekLeadsCount = useMemo(
    () => countDueThisWeekLeads(leads),
    [leads],
  );

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode) {
      setViewMode(newViewMode);
      if (newViewMode === 'standard') {
        navigate('/leads');
      } else if (newViewMode === 'follow_up') {
        navigate('/leads-followup');
      }
    }
  };

  return (
    <Grid item container justifyContent="center" sx={{ mb: 2 }}>
      <StyledBadgeRed
        badgeContent={overdueLeadsCount}
        invisible={overdueLeadsCount === 0}
      >
        <StyledBadgeYellow
          badgeContent={sameDayOverdueLeadsCount}
          invisible={sameDayOverdueLeadsCount === 0}
        >
          <StyledBadgeGreen
            badgeContent={dueThisWeekLeadsCount}
            invisible={dueThisWeekLeadsCount === 0}
          >
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
            >
              <ToggleButton
                value="standard"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Standard
              </ToggleButton>
              <ToggleButton
                value="follow_up"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Relance
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledBadgeGreen>
        </StyledBadgeYellow>
      </StyledBadgeRed>
    </Grid>
  );
}

FollowUpToggleButtonGroup.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

export default FollowUpToggleButtonGroup;
