/** @format */
import { Badge } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // left: -30,
    left: 22.5,
    top: '50%',
  },
}));

function BetaBadge({ children, style, ...props }) {
  return (
    <StyledBadge
      badgeContent="BETA"
      color="warning"
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      {cloneElement(children, {
        ...props,
        style: {
          ...style,
          ...{ marginLeft: 50 },
        },
      })}
    </StyledBadge>
  );
}

BetaBadge.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape(),
};

BetaBadge.defaultProps = {
  style: {},
};

export default BetaBadge;
