/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Intervention } from '../models/Intervention';
import type { PaginatedInterventionList } from '../models/PaginatedInterventionList';
import type { PatchedIntervention } from '../models/PatchedIntervention';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsInterventionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedInterventionList
     * @throws ApiError
     */
    public leadsInterventionsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedInterventionList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-interventions/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsCreate({
        requestBody,
    }: {
        requestBody: Intervention,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-interventions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-interventions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
        requestBody: Intervention,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-interventions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
        requestBody?: PatchedIntervention,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-interventions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsInterventionsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-interventions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsAddReportCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
        requestBody: Intervention,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-interventions/{id}/add_report/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsSetTransferredCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this intervention.
         */
        id: number,
        requestBody: Intervention,
    }): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-interventions/{id}/set_transferred/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Intervention
     * @throws ApiError
     */
    public leadsInterventionsMineRetrieve(): CancelablePromise<Intervention> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-interventions/mine/',
        });
    }

}
