/**
 * Gets parent URL of a "match" react-router URL
 *
 * @format
 * @param url
 */

export const parentUrl = (url) => {
  if (url === '/') return '';
  const split = url.split('/').filter(Boolean);
  if (split.length <= 1) {
    return '';
  }
  return `/${split.join('/').replace(/\/(.*?)$/i, '')}`;
};

export const getQueryParams = (url) =>
  new Proxy(new URLSearchParams(url), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

export default null;
