/**
 * @format
 */
/* eslint-disable max-classes-per-file */

import moment from 'moment';
import { api } from '../api.ts';

/**
 * Cache model
 */
class CachedModel {
  constructor(key, apiName) {
    this.key = key;
    this.apiName = apiName;
    this.items = null;
    this.ts = null;
    this.retrieve();
  }

  static asPromise(result) {
    return new Promise((resolve) => {
      resolve(result);
    });
  }

  /**
   * Fetches cache content
   * @returns {Promise<T>}
   */
  fetch() {
    return api
      .get(`/${this.key}/?page_size=999`)
      .then((results) => results.data.results);
  }

  /**
   * Stores items in localStorage
   * @param items
   */
  store(items) {
    this.items = items;
    this.ts = Date.now().toString();
    localStorage.setItem(this.key, JSON.stringify(this.items));
    localStorage.setItem(`${this.key}__ts`, this.ts);
  }

  /**
   * Retrieves items from localStorage
   */
  retrieve() {
    const tmp = localStorage.getItem(this.key);
    if (tmp && tmp !== '' && tmp.length > 0) {
      this.items = JSON.parse(tmp);
      this.ts = localStorage.getItem(`${this.key}__ts`);
    } else {
      // this.fetch().then((results) => this.store(results));
    }
  }

  /**
   * Fetches then store items in localStorage
   */
  update() {
    return this.fetch().then((fetchResults) => this.store(fetchResults));
  }

  /**
   * Lists items
   */
  list() {
    return this.items || [];
  }

  /**
   * Gets item by id
   * @param id {number}
   * @returns {Object} item
   */
  get(id) {
    const filter = this.list().filter((v) => v.id === parseInt(id, 10));
    return filter.length > 0 ? filter[0] : id;
  }

  /**
   * Gets item by attribute
   * @param attrName {string} attribute name
   * @param value {string} value
   * @returns {Object} item
   */
  getByAttr(attrName, value) {
    return this.list().filter((v) => v[attrName] === value);
  }
}

/**
 * Cache manager
 */
class CacheManager {
  static instance = new CacheManager();

  constructor() {
    this.models = {};
  }

  /**
   * Registers model to manage with cache
   * @param key {string} model name in frontend
   * @param apiName {string} model name in backend
   */
  addModel(key, apiName) {
    this.models[key] = new CachedModel(key, apiName);
  }

  /**
   * Gets registered model by key
   * @param key {string} model name in frontend
   * @returns {CachedModel} model
   */
  getModel(key) {
    const match = key.match(/\/(.*?)\/.*/);
    if (match && match.length > 1) {
      return this.models[match[1]] || null;
    }
    return this.models[key] || null;
  }

  /**
   * Updates if control date is newer than cache date
   */
  updateCache() {
    api.get('/cache/').then((results) => {
      if (results.data.results.length < 1) return;
      const cache = results.data.results[0];
      Object.values(this.models).forEach((model) => {
        const date = cache[model.apiName];
        if (date || !model.items) {
          if (!model.items || moment(date).isAfter(moment(model.ts, 'x'))) {
            model.fetch().then((fetchResults) => model.store(fetchResults));
          }
        }
      });
    });
  }

  /**
   * @deprecated
   */
  initialLoading() {
    return api.get('/cache/').then((results) => {
      if (results.data.results.length < 1) return null;
      const cache = results.data.results[0];
      return Promise.all(
        Object.values(this.models).map((model) => {
          const date = cache[model.apiName];
          if (date || !model.items) {
            if (!model.items || moment(date).isAfter(moment(model.ts, 'x'))) {
              return model.update();
            }
          }
          return null;
        }),
      );
    });
  }
}

export default CacheManager;
