/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSheet } from '../models/DataSheet';
import type { PaginatedDataSheetList } from '../models/PaginatedDataSheetList';
import type { PatchedDataSheet } from '../models/PatchedDataSheet';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DatasheetsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedDataSheetList
     * @throws ApiError
     */
    public datasheetsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedDataSheetList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/datasheets/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns DataSheet
     * @throws ApiError
     */
    public datasheetsCreate({
        requestBody,
    }: {
        requestBody: DataSheet,
    }): CancelablePromise<DataSheet> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/datasheets/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DataSheet
     * @throws ApiError
     */
    public datasheetsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this data sheet.
         */
        id: number,
    }): CancelablePromise<DataSheet> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/datasheets/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DataSheet
     * @throws ApiError
     */
    public datasheetsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this data sheet.
         */
        id: number,
        requestBody: DataSheet,
    }): CancelablePromise<DataSheet> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/datasheets/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DataSheet
     * @throws ApiError
     */
    public datasheetsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this data sheet.
         */
        id: number,
        requestBody?: PatchedDataSheet,
    }): CancelablePromise<DataSheet> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/datasheets/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public datasheetsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this data sheet.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/datasheets/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
