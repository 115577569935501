/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Floor } from '../models/Floor';
import type { PaginatedFloorList } from '../models/PaginatedFloorList';
import type { PatchedFloor } from '../models/PatchedFloor';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsFloorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedFloorList
     * @throws ApiError
     */
    public leadsFloorsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedFloorList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-floors/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Floor
     * @throws ApiError
     */
    public leadsFloorsCreate({
        requestBody,
    }: {
        requestBody: Floor,
    }): CancelablePromise<Floor> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-floors/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Floor
     * @throws ApiError
     */
    public leadsFloorsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this floor.
         */
        id: number,
    }): CancelablePromise<Floor> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-floors/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Floor
     * @throws ApiError
     */
    public leadsFloorsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this floor.
         */
        id: number,
        requestBody: Floor,
    }): CancelablePromise<Floor> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-floors/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Floor
     * @throws ApiError
     */
    public leadsFloorsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this floor.
         */
        id: number,
        requestBody?: PatchedFloor,
    }): CancelablePromise<Floor> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-floors/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsFloorsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this floor.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-floors/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
