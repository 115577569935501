/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedTechnicalVisitStatusList } from '../models/PaginatedTechnicalVisitStatusList';
import type { PatchedTechnicalVisitStatus } from '../models/PatchedTechnicalVisitStatus';
import type { TechnicalVisitStatus } from '../models/TechnicalVisitStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsTechnicalVisitStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedTechnicalVisitStatusList
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedTechnicalVisitStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-technical-visit-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns TechnicalVisitStatus
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusCreate({
        requestBody,
    }: {
        requestBody: TechnicalVisitStatus,
    }): CancelablePromise<TechnicalVisitStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-technical-visit-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TechnicalVisitStatus
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this technical visit status.
         */
        id: number,
    }): CancelablePromise<TechnicalVisitStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-technical-visit-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TechnicalVisitStatus
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this technical visit status.
         */
        id: number,
        requestBody: TechnicalVisitStatus,
    }): CancelablePromise<TechnicalVisitStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-technical-visit-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TechnicalVisitStatus
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this technical visit status.
         */
        id: number,
        requestBody?: PatchedTechnicalVisitStatus,
    }): CancelablePromise<TechnicalVisitStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-technical-visit-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsTechnicalVisitStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this technical visit status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-technical-visit-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
