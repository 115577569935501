/** @format */
import Grid from '@mui/material/Grid';
import { IconButton, Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import { genders } from '../../../utils/constants';
import BoxLoader from '../../misc/BoxLoader';
import { ThemeProvider } from '../../../theme';
import { api } from '../../../api.ts';
import { parseFieldError } from '../../../utils/errors';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

function FollowUpDialogMobile({ lead, loadLeads }) {
  const [fieldErrors, setFieldErrors] = useState({});
  const [meetingDate, setMeetingDate] = useState('');
  const [meetingTime, setMeetingTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    setMeetingDate('');
    setMeetingTime('');
  };

  const confirmMeeting = (
    isConfirmed,
    hasPlannedNextMeeting,
    hasSigned,
    isSetR2 = false,
  ) => {
    setLoading(true);
    const data = {
      lead: lead.id,
      is_confirmed: isConfirmed,
      has_planned_next_meeting: hasPlannedNextMeeting,
      has_signed: hasSigned,
      is_set_r2: isSetR2,
      meeting_date: moment(`${meetingDate}T${meetingTime}:00`),
    };

    api
      .post(`/follow-up/${lead.id}/confirm_meeting/`, {}, data)
      .then(() => {
        setLoading(false);
        handleClose();
        enqueueSnackbar('Les informations ont été mises à jour', {
          variant: 'success',
        });
        loadLeads();
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
        setFieldErrors(state || {});
        setLoading(false);
      });
  };

  return (
    <ThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="paper"
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box>
            {' '}
            <span>
              Prendre le prochain rdv avec
              {lead
                ? ` ${genders[lead.gender]} ${lead.last_name} ${
                    lead.first_name || ''
                  }`
                : ''}
            </span>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
        <DialogContent dividers>
          <BoxLoader loading={loading} whiteBackground>
            <Grid item xs={12}>
              <Paper elevation={0}>
                <Box p={3}>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Tooltip title="Entrez la date">
                        <DatePicker
                          label="Date du RDV"
                          format="DD/MM/YYYY"
                          value={moment(meetingDate)}
                          allowSameDateSelection
                          onChange={(newValue) =>
                            setMeetingDate(newValue.format('YYYY-MM-DD'))
                          }
                          slotProps={{
                            textField: {
                              variant: 'outlined',
                              fullWidth: true,
                              error: Boolean(fieldErrors.dateError),
                              helperText: fieldErrors.dateError,
                            },
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <TimePicker
                        label="Heure du RDV"
                        format="HH:mm"
                        value={moment(meetingTime, 'HH:mm')}
                        ampm={false}
                        onChange={(newValue) =>
                          setMeetingTime(newValue.format('HH:mm'))
                        }
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            error: Boolean(fieldErrors.hourError),
                            helperText: fieldErrors.hourError,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </BoxLoader>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-evenly">
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                onClick={handleClose}
                color="inherit"
              >
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => confirmMeeting(true, true, false)}
              >
                Valider
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Button
        size="small"
        variant="text"
        startIcon={<CalendarMonth />}
        onClick={() => setOpen(true)}
      >
        Planifier le prochain rdv
      </Button>
      <Box sx={{ ml: 'auto' }}>
        <FontAwesomeIcon icon={faChevronRight} style={{ color: '#50AE85' }} />
      </Box>
    </ThemeProvider>
  );
}
FollowUpDialogMobile.propTypes = {
  lead: PropTypes.shape().isRequired,
  loadLeads: PropTypes.func.isRequired,
};

export default FollowUpDialogMobile;
