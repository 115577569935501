/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductThermodynamicBalloonList } from '../models/PaginatedProductThermodynamicBalloonList';
import type { PatchedProductThermodynamicBalloon } from '../models/PatchedProductThermodynamicBalloon';
import type { ProductThermodynamicBalloon } from '../models/ProductThermodynamicBalloon';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsThermoBalloonService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductThermodynamicBalloonList
     * @throws ApiError
     */
    public productsThermoBalloonList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductThermodynamicBalloonList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-thermo-balloon/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductThermodynamicBalloon
     * @throws ApiError
     */
    public productsThermoBalloonCreate({
        requestBody,
    }: {
        requestBody: ProductThermodynamicBalloon,
    }): CancelablePromise<ProductThermodynamicBalloon> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-thermo-balloon/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductThermodynamicBalloon
     * @throws ApiError
     */
    public productsThermoBalloonRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product thermodynamic balloon.
         */
        id: number,
    }): CancelablePromise<ProductThermodynamicBalloon> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-thermo-balloon/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductThermodynamicBalloon
     * @throws ApiError
     */
    public productsThermoBalloonUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product thermodynamic balloon.
         */
        id: number,
        requestBody: ProductThermodynamicBalloon,
    }): CancelablePromise<ProductThermodynamicBalloon> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-thermo-balloon/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductThermodynamicBalloon
     * @throws ApiError
     */
    public productsThermoBalloonPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product thermodynamic balloon.
         */
        id: number,
        requestBody?: PatchedProductThermodynamicBalloon,
    }): CancelablePromise<ProductThermodynamicBalloon> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-thermo-balloon/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsThermoBalloonDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product thermodynamic balloon.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-thermo-balloon/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
