/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientConnection } from '../models/ClientConnection';
import type { PaginatedClientConnectionList } from '../models/PaginatedClientConnectionList';
import type { PatchedClientConnection } from '../models/PatchedClientConnection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConnectionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientConnectionList
     * @throws ApiError
     */
    public clientsConnectionsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientConnectionList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-connections/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientConnection
     * @throws ApiError
     */
    public clientsConnectionsCreate({
        requestBody,
    }: {
        requestBody: ClientConnection,
    }): CancelablePromise<ClientConnection> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-connections/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConnection
     * @throws ApiError
     */
    public clientsConnectionsRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client connection.
         */
        client: number,
    }): CancelablePromise<ClientConnection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-connections/{client}/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * @returns ClientConnection
     * @throws ApiError
     */
    public clientsConnectionsUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client connection.
         */
        client: number,
        requestBody: ClientConnection,
    }): CancelablePromise<ClientConnection> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-connections/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConnection
     * @throws ApiError
     */
    public clientsConnectionsPartialUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client connection.
         */
        client: number,
        requestBody?: PatchedClientConnection,
    }): CancelablePromise<ClientConnection> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-connections/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConnectionsDestroy({
        client,
    }: {
        /**
         * A unique value identifying this client connection.
         */
        client: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-connections/{client}/',
            path: {
                'client': client,
            },
        });
    }

}
