/** @format */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdf: null,
};

const slice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    setPDF: (state, action) => {
      state.pdf = action.payload;
    },
    clearPDF: (state, action) => {
      state.pdf = null;
    },
  },
});

export const { setPDF, clearPDF } = slice.actions;

export const selectPDF = (state) => (state && state.pdf ? state.pdf.pdf : null);

export default slice.reducer;
