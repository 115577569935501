/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRoofSideList } from '../models/PaginatedRoofSideList';
import type { PatchedRoofSide } from '../models/PatchedRoofSide';
import type { RoofSide } from '../models/RoofSide';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsRoofSidesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedRoofSideList
     * @throws ApiError
     */
    public leadsRoofSidesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedRoofSideList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-roof-sides/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns RoofSide
     * @throws ApiError
     */
    public leadsRoofSidesCreate({
        requestBody,
    }: {
        requestBody: RoofSide,
    }): CancelablePromise<RoofSide> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-roof-sides/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RoofSide
     * @throws ApiError
     */
    public leadsRoofSidesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this roof side.
         */
        id: number,
    }): CancelablePromise<RoofSide> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-roof-sides/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RoofSide
     * @throws ApiError
     */
    public leadsRoofSidesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this roof side.
         */
        id: number,
        requestBody: RoofSide,
    }): CancelablePromise<RoofSide> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-roof-sides/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RoofSide
     * @throws ApiError
     */
    public leadsRoofSidesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this roof side.
         */
        id: number,
        requestBody?: PatchedRoofSide,
    }): CancelablePromise<RoofSide> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-roof-sides/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsRoofSidesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this roof side.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-roof-sides/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
