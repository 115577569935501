/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferProductAdministrativeProcedures } from '../models/OfferProductAdministrativeProcedures';
import type { PaginatedOfferProductAdministrativeProceduresList } from '../models/PaginatedOfferProductAdministrativeProceduresList';
import type { PatchedOfferProductAdministrativeProcedures } from '../models/PatchedOfferProductAdministrativeProcedures';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersProductAdministrativeProceduresService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferProductAdministrativeProceduresList
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferProductAdministrativeProceduresList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-administrative-procedures/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OfferProductAdministrativeProcedures
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresCreate({
        requestBody,
    }: {
        requestBody: OfferProductAdministrativeProcedures,
    }): CancelablePromise<OfferProductAdministrativeProcedures> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers-product-administrative-procedures/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductAdministrativeProcedures
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product administrative procedures.
         */
        id: number,
    }): CancelablePromise<OfferProductAdministrativeProcedures> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-administrative-procedures/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OfferProductAdministrativeProcedures
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product administrative procedures.
         */
        id: number,
        requestBody: OfferProductAdministrativeProcedures,
    }): CancelablePromise<OfferProductAdministrativeProcedures> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers-product-administrative-procedures/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductAdministrativeProcedures
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product administrative procedures.
         */
        id: number,
        requestBody?: PatchedOfferProductAdministrativeProcedures,
    }): CancelablePromise<OfferProductAdministrativeProcedures> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers-product-administrative-procedures/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersProductAdministrativeProceduresDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product administrative procedures.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers-product-administrative-procedures/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
