/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventActionLeadCreated } from '../models/EventActionLeadCreated';
import type { PaginatedEventActionLeadCreatedList } from '../models/PaginatedEventActionLeadCreatedList';
import type { PatchedEventActionLeadCreated } from '../models/PatchedEventActionLeadCreated';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsEventActionLeadCreatedService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedEventActionLeadCreatedList
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedEventActionLeadCreatedList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-lead-created/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns EventActionLeadCreated
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedCreate({
        requestBody,
    }: {
        requestBody: EventActionLeadCreated,
    }): CancelablePromise<EventActionLeadCreated> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events-event-action-lead-created/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionLeadCreated
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event action lead created.
         */
        id: number,
    }): CancelablePromise<EventActionLeadCreated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-lead-created/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventActionLeadCreated
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action lead created.
         */
        id: number,
        requestBody: EventActionLeadCreated,
    }): CancelablePromise<EventActionLeadCreated> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events-event-action-lead-created/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionLeadCreated
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action lead created.
         */
        id: number,
        requestBody?: PatchedEventActionLeadCreated,
    }): CancelablePromise<EventActionLeadCreated> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events-event-action-lead-created/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsEventActionLeadCreatedDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this event action lead created.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events-event-action-lead-created/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
