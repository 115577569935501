/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ABFStatus } from '../models/ABFStatus';
import type { PaginatedABFStatusList } from '../models/PaginatedABFStatusList';
import type { PatchedABFStatus } from '../models/PatchedABFStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsAbfStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedABFStatusList
     * @throws ApiError
     */
    public clientsAbfStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedABFStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-abf-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ABFStatus
     * @throws ApiError
     */
    public clientsAbfStatusCreate({
        requestBody,
    }: {
        requestBody: ABFStatus,
    }): CancelablePromise<ABFStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-abf-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ABFStatus
     * @throws ApiError
     */
    public clientsAbfStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this abf status.
         */
        id: number,
    }): CancelablePromise<ABFStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-abf-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ABFStatus
     * @throws ApiError
     */
    public clientsAbfStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this abf status.
         */
        id: number,
        requestBody: ABFStatus,
    }): CancelablePromise<ABFStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-abf-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ABFStatus
     * @throws ApiError
     */
    public clientsAbfStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this abf status.
         */
        id: number,
        requestBody?: PatchedABFStatus,
    }): CancelablePromise<ABFStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-abf-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsAbfStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this abf status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-abf-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
