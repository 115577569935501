/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductMaterialList } from '../models/PaginatedProductMaterialList';
import type { PatchedProductMaterial } from '../models/PatchedProductMaterial';
import type { ProductMaterial } from '../models/ProductMaterial';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsMaterialsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductMaterialList
     * @throws ApiError
     */
    public productsMaterialsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedProductMaterialList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-materials/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ProductMaterial
     * @throws ApiError
     */
    public productsMaterialsCreate({
        requestBody,
    }: {
        requestBody: ProductMaterial,
    }): CancelablePromise<ProductMaterial> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-materials/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductMaterial
     * @throws ApiError
     */
    public productsMaterialsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product material.
         */
        id: number,
    }): CancelablePromise<ProductMaterial> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-materials/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductMaterial
     * @throws ApiError
     */
    public productsMaterialsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product material.
         */
        id: number,
        requestBody: ProductMaterial,
    }): CancelablePromise<ProductMaterial> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-materials/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductMaterial
     * @throws ApiError
     */
    public productsMaterialsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product material.
         */
        id: number,
        requestBody?: PatchedProductMaterial,
    }): CancelablePromise<ProductMaterial> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-materials/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsMaterialsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product material.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-materials/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
