/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Offer } from '../models/Offer';
import type { PaginatedOfferList } from '../models/PaginatedOfferList';
import type { PatchedOffer } from '../models/PatchedOffer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferList
     * @throws ApiError
     */
    public offersList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersCreate({
        requestBody,
    }: {
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody?: PatchedOffer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersCreateAndSendQuoteCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/{id}/create_and_send_quote/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersCreateQuoteCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/{id}/create_quote/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersDuplicateAndSendQuoteCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/{id}/duplicate_and_send_quote/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersDuplicateQuoteCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/{id}/duplicate_quote/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersExportCsvRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/export_csv/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersExportQuoteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/export_quote/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersExportStudyRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/export_study/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersGenerateQuoteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/generate_quote/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersPreviewQuoteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/preview_quote/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersPreviewQuoteCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/{id}/preview_quote/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersScenarioRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer.
         */
        id: number,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers/{id}/scenario/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersSendOffersCreate({
        requestBody,
    }: {
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/send_offers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Offer
     * @throws ApiError
     */
    public offersUpdateBaseOffersCreate({
        requestBody,
    }: {
        requestBody: Offer,
    }): CancelablePromise<Offer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers/update_base_offers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
