/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductStructureList } from '../models/PaginatedProductStructureList';
import type { PatchedProductStructure } from '../models/PatchedProductStructure';
import type { ProductStructure } from '../models/ProductStructure';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsStructuresService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductStructureList
     * @throws ApiError
     */
    public productsStructuresList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductStructureList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-structures/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductStructure
     * @throws ApiError
     */
    public productsStructuresCreate({
        requestBody,
    }: {
        requestBody: ProductStructure,
    }): CancelablePromise<ProductStructure> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-structures/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductStructure
     * @throws ApiError
     */
    public productsStructuresRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product structure.
         */
        id: number,
    }): CancelablePromise<ProductStructure> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-structures/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductStructure
     * @throws ApiError
     */
    public productsStructuresUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product structure.
         */
        id: number,
        requestBody: ProductStructure,
    }): CancelablePromise<ProductStructure> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-structures/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductStructure
     * @throws ApiError
     */
    public productsStructuresPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product structure.
         */
        id: number,
        requestBody?: PatchedProductStructure,
    }): CancelablePromise<ProductStructure> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-structures/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsStructuresDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product structure.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-structures/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
