/** @format */

import { cyan, grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export default null;

export const mainTheme = createTheme({
  palette: {
    primary: {
      light: '#72be9d',
      main: '#50AE85',
      dark: '#408b6a',
      contrastText: '#fff',
    },
    primaryDark: {
      light: '#50AE85',
      main: '#408b6a',
      dark: '#275442',
      contrastText: '#fff',
    },
    primaryLight: {
      light: '#8be2be',
      main: '#72be9d',
      dark: '#50AE85',
      contrastText: '#fff',
    },
    secondary: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
      contrastText: '#fff',
    },
    red: {
      light: red[300],
      main: red[500],
      dark: red[700],
      contrastText: '#fff',
    },
    default: {
      dark: grey[500],
      light: grey[100],
      main: grey[300],
      contrastText: '#000000',
    },
    defaultLight: {
      dark: grey[100],
      light: grey[500],
      main: grey[300],
      contrastText: '#000000',
    },
    background: {
      default: grey[100],
    },
  },
});

export const themes = {
  main: mainTheme,
};
