/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElectricityDistributorCity } from '../models/ElectricityDistributorCity';
import type { PaginatedElectricityDistributorCityList } from '../models/PaginatedElectricityDistributorCityList';
import type { PatchedElectricityDistributorCity } from '../models/PatchedElectricityDistributorCity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsElectricityDistributorCitiesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedElectricityDistributorCityList
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedElectricityDistributorCityList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-electricity-distributor-cities/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ElectricityDistributorCity
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesCreate({
        requestBody,
    }: {
        requestBody: ElectricityDistributorCity,
    }): CancelablePromise<ElectricityDistributorCity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-electricity-distributor-cities/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElectricityDistributorCity
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this electricity distributor city.
         */
        id: number,
    }): CancelablePromise<ElectricityDistributorCity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-electricity-distributor-cities/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ElectricityDistributorCity
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this electricity distributor city.
         */
        id: number,
        requestBody: ElectricityDistributorCity,
    }): CancelablePromise<ElectricityDistributorCity> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-electricity-distributor-cities/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElectricityDistributorCity
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this electricity distributor city.
         */
        id: number,
        requestBody?: PatchedElectricityDistributorCity,
    }): CancelablePromise<ElectricityDistributorCity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-electricity-distributor-cities/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsElectricityDistributorCitiesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this electricity distributor city.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-electricity-distributor-cities/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
