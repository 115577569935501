/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRoofImageList } from '../models/PaginatedRoofImageList';
import type { PatchedRoofImage } from '../models/PatchedRoofImage';
import type { RoofImage } from '../models/RoofImage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsSitesRoofImagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedRoofImageList
     * @throws ApiError
     */
    public leadsSitesRoofImagesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedRoofImageList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-roof-images/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns RoofImage
     * @throws ApiError
     */
    public leadsSitesRoofImagesCreate({
        requestBody,
    }: {
        requestBody: RoofImage,
    }): CancelablePromise<RoofImage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites-roof-images/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RoofImage
     * @throws ApiError
     */
    public leadsSitesRoofImagesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this roof image.
         */
        id: number,
    }): CancelablePromise<RoofImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-roof-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RoofImage
     * @throws ApiError
     */
    public leadsSitesRoofImagesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this roof image.
         */
        id: number,
        requestBody: RoofImage,
    }): CancelablePromise<RoofImage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-sites-roof-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RoofImage
     * @throws ApiError
     */
    public leadsSitesRoofImagesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this roof image.
         */
        id: number,
        requestBody?: PatchedRoofImage,
    }): CancelablePromise<RoofImage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-sites-roof-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSitesRoofImagesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this roof image.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-sites-roof-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
