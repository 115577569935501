/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LayoutImage } from '../models/LayoutImage';
import type { PaginatedLayoutImageList } from '../models/PaginatedLayoutImageList';
import type { PatchedLayoutImage } from '../models/PatchedLayoutImage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsSitesLayoutImagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLayoutImageList
     * @throws ApiError
     */
    public leadsSitesLayoutImagesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedLayoutImageList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-layout-images/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns LayoutImage
     * @throws ApiError
     */
    public leadsSitesLayoutImagesCreate({
        requestBody,
    }: {
        requestBody: LayoutImage,
    }): CancelablePromise<LayoutImage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites-layout-images/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LayoutImage
     * @throws ApiError
     */
    public leadsSitesLayoutImagesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this layout image.
         */
        id: number,
    }): CancelablePromise<LayoutImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-layout-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LayoutImage
     * @throws ApiError
     */
    public leadsSitesLayoutImagesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this layout image.
         */
        id: number,
        requestBody: LayoutImage,
    }): CancelablePromise<LayoutImage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-sites-layout-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LayoutImage
     * @throws ApiError
     */
    public leadsSitesLayoutImagesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this layout image.
         */
        id: number,
        requestBody?: PatchedLayoutImage,
    }): CancelablePromise<LayoutImage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-sites-layout-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSitesLayoutImagesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this layout image.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-sites-layout-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
