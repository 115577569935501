/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferProductWorkforce } from '../models/OfferProductWorkforce';
import type { PaginatedOfferProductWorkforceList } from '../models/PaginatedOfferProductWorkforceList';
import type { PatchedOfferProductWorkforce } from '../models/PatchedOfferProductWorkforce';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersProductWorkforcesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferProductWorkforceList
     * @throws ApiError
     */
    public offersProductWorkforcesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferProductWorkforceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-workforces/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OfferProductWorkforce
     * @throws ApiError
     */
    public offersProductWorkforcesCreate({
        requestBody,
    }: {
        requestBody: OfferProductWorkforce,
    }): CancelablePromise<OfferProductWorkforce> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers-product-workforces/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductWorkforce
     * @throws ApiError
     */
    public offersProductWorkforcesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product workforce.
         */
        id: number,
    }): CancelablePromise<OfferProductWorkforce> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-workforces/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OfferProductWorkforce
     * @throws ApiError
     */
    public offersProductWorkforcesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product workforce.
         */
        id: number,
        requestBody: OfferProductWorkforce,
    }): CancelablePromise<OfferProductWorkforce> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers-product-workforces/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductWorkforce
     * @throws ApiError
     */
    public offersProductWorkforcesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product workforce.
         */
        id: number,
        requestBody?: PatchedOfferProductWorkforce,
    }): CancelablePromise<OfferProductWorkforce> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers-product-workforces/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersProductWorkforcesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product workforce.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers-product-workforces/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
