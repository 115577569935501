/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedTechnicalVisitReportList } from '../models/PaginatedTechnicalVisitReportList';
import type { PatchedTechnicalVisitReport } from '../models/PatchedTechnicalVisitReport';
import type { TechnicalVisitReport } from '../models/TechnicalVisitReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsTechnicalVisitReportsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedTechnicalVisitReportList
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedTechnicalVisitReportList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-technical-visit-reports/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns TechnicalVisitReport
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsCreate({
        requestBody,
    }: {
        requestBody: TechnicalVisitReport,
    }): CancelablePromise<TechnicalVisitReport> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-technical-visit-reports/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TechnicalVisitReport
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsRetrieve({
        intervention,
    }: {
        /**
         * A unique value identifying this technical visit report.
         */
        intervention: number,
    }): CancelablePromise<TechnicalVisitReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-technical-visit-reports/{intervention}/',
            path: {
                'intervention': intervention,
            },
        });
    }

    /**
     * @returns TechnicalVisitReport
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsUpdate({
        intervention,
        requestBody,
    }: {
        /**
         * A unique value identifying this technical visit report.
         */
        intervention: number,
        requestBody: TechnicalVisitReport,
    }): CancelablePromise<TechnicalVisitReport> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-technical-visit-reports/{intervention}/',
            path: {
                'intervention': intervention,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TechnicalVisitReport
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsPartialUpdate({
        intervention,
        requestBody,
    }: {
        /**
         * A unique value identifying this technical visit report.
         */
        intervention: number,
        requestBody?: PatchedTechnicalVisitReport,
    }): CancelablePromise<TechnicalVisitReport> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-technical-visit-reports/{intervention}/',
            path: {
                'intervention': intervention,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsTechnicalVisitReportsDestroy({
        intervention,
    }: {
        /**
         * A unique value identifying this technical visit report.
         */
        intervention: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-technical-visit-reports/{intervention}/',
            path: {
                'intervention': intervention,
            },
        });
    }

}
