/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientUnknownInvoice } from '../models/ClientUnknownInvoice';
import type { PaginatedClientUnknownInvoiceList } from '../models/PaginatedClientUnknownInvoiceList';
import type { PatchedClientUnknownInvoice } from '../models/PatchedClientUnknownInvoice';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsUnknownInvoicesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientUnknownInvoiceList
     * @throws ApiError
     */
    public clientsUnknownInvoicesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientUnknownInvoiceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-unknown-invoices/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientUnknownInvoice
     * @throws ApiError
     */
    public clientsUnknownInvoicesCreate({
        requestBody,
    }: {
        requestBody?: ClientUnknownInvoice,
    }): CancelablePromise<ClientUnknownInvoice> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-unknown-invoices/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientUnknownInvoice
     * @throws ApiError
     */
    public clientsUnknownInvoicesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this client unknown invoice.
         */
        id: number,
    }): CancelablePromise<ClientUnknownInvoice> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-unknown-invoices/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientUnknownInvoice
     * @throws ApiError
     */
    public clientsUnknownInvoicesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client unknown invoice.
         */
        id: number,
        requestBody?: ClientUnknownInvoice,
    }): CancelablePromise<ClientUnknownInvoice> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-unknown-invoices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientUnknownInvoice
     * @throws ApiError
     */
    public clientsUnknownInvoicesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client unknown invoice.
         */
        id: number,
        requestBody?: PatchedClientUnknownInvoice,
    }): CancelablePromise<ClientUnknownInvoice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-unknown-invoices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsUnknownInvoicesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this client unknown invoice.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-unknown-invoices/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update unkwnon invoices.
     * :param request: Django request
     * :return: HTTP Response
     * @returns ClientUnknownInvoice
     * @throws ApiError
     */
    public clientsUnknownInvoicesUpdateUnknownInvoicesCreate({
        requestBody,
    }: {
        requestBody?: ClientUnknownInvoice,
    }): CancelablePromise<ClientUnknownInvoice> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-unknown-invoices/update_unknown_invoices/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
