/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessIntroducer } from '../models/BusinessIntroducer';
import type { PaginatedBusinessIntroducerList } from '../models/PaginatedBusinessIntroducerList';
import type { PatchedBusinessIntroducer } from '../models/PatchedBusinessIntroducer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsBusinessIntroducersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedBusinessIntroducerList
     * @throws ApiError
     */
    public leadsBusinessIntroducersList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedBusinessIntroducerList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-business-introducers/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns BusinessIntroducer
     * @throws ApiError
     */
    public leadsBusinessIntroducersCreate({
        requestBody,
    }: {
        requestBody: BusinessIntroducer,
    }): CancelablePromise<BusinessIntroducer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-business-introducers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BusinessIntroducer
     * @throws ApiError
     */
    public leadsBusinessIntroducersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this business introducer.
         */
        id: number,
    }): CancelablePromise<BusinessIntroducer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-business-introducers/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns BusinessIntroducer
     * @throws ApiError
     */
    public leadsBusinessIntroducersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this business introducer.
         */
        id: number,
        requestBody: BusinessIntroducer,
    }): CancelablePromise<BusinessIntroducer> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-business-introducers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BusinessIntroducer
     * @throws ApiError
     */
    public leadsBusinessIntroducersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this business introducer.
         */
        id: number,
        requestBody?: PatchedBusinessIntroducer,
    }): CancelablePromise<BusinessIntroducer> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-business-introducers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsBusinessIntroducersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this business introducer.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-business-introducers/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
