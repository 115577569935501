/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadSideProperties } from '../models/LeadSideProperties';
import type { PaginatedLeadSidePropertiesList } from '../models/PaginatedLeadSidePropertiesList';
import type { PatchedLeadSideProperties } from '../models/PatchedLeadSideProperties';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsSidePropertiesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadSidePropertiesList
     * @throws ApiError
     */
    public leadsSidePropertiesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedLeadSidePropertiesList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-side-properties/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesCreate({
        requestBody,
    }: {
        requestBody: LeadSideProperties,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-side-properties/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesRetrieve({
        site,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-side-properties/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
        requestBody: LeadSideProperties,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-side-properties/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesPartialUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
        requestBody?: PatchedLeadSideProperties,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-side-properties/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSidePropertiesDestroy({
        site,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-side-properties/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesUpdateProductionCreate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
        requestBody: LeadSideProperties,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-side-properties/{site}/update_production/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSideProperties
     * @throws ApiError
     */
    public leadsSidePropertiesUpdateShadowValuesCreate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this side properties.
         */
        site: number,
        requestBody: LeadSideProperties,
    }): CancelablePromise<LeadSideProperties> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-side-properties/{site}/update_shadow_values/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
