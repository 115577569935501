/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Deal } from '../models/Deal';
import type { PaginatedDealList } from '../models/PaginatedDealList';
import type { PatchedDeal } from '../models/PatchedDeal';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DealsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedDealList
     * @throws ApiError
     */
    public dealsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedDealList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/deals/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Deal
     * @throws ApiError
     */
    public dealsCreate({
        requestBody,
    }: {
        requestBody: Deal,
    }): CancelablePromise<Deal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/deals/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Deal
     * @throws ApiError
     */
    public dealsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this deal.
         */
        id: number,
    }): CancelablePromise<Deal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/deals/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Deal
     * @throws ApiError
     */
    public dealsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this deal.
         */
        id: number,
        requestBody: Deal,
    }): CancelablePromise<Deal> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/deals/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Deal
     * @throws ApiError
     */
    public dealsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this deal.
         */
        id: number,
        requestBody?: PatchedDeal,
    }): CancelablePromise<Deal> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/deals/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public dealsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this deal.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/deals/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Deal
     * @throws ApiError
     */
    public dealsBeogaOffersCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this deal.
         */
        id: number,
        requestBody: Deal,
    }): CancelablePromise<Deal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/deals/{id}/beoga_offers/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
