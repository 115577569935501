/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedSolarEdgeManagementAlertStatusList } from '../models/PaginatedSolarEdgeManagementAlertStatusList';
import type { PatchedSolarEdgeManagementAlertStatus } from '../models/PatchedSolarEdgeManagementAlertStatus';
import type { SolarEdgeManagementAlertStatus } from '../models/SolarEdgeManagementAlertStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsSolarEdgeManagementAlertStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedSolarEdgeManagementAlertStatusList
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedSolarEdgeManagementAlertStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-solar-edge-management-alert-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns SolarEdgeManagementAlertStatus
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusCreate({
        requestBody,
    }: {
        requestBody: SolarEdgeManagementAlertStatus,
    }): CancelablePromise<SolarEdgeManagementAlertStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-solar-edge-management-alert-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SolarEdgeManagementAlertStatus
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this solar edge management alert status.
         */
        id: number,
    }): CancelablePromise<SolarEdgeManagementAlertStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-solar-edge-management-alert-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SolarEdgeManagementAlertStatus
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this solar edge management alert status.
         */
        id: number,
        requestBody: SolarEdgeManagementAlertStatus,
    }): CancelablePromise<SolarEdgeManagementAlertStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-solar-edge-management-alert-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SolarEdgeManagementAlertStatus
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this solar edge management alert status.
         */
        id: number,
        requestBody?: PatchedSolarEdgeManagementAlertStatus,
    }): CancelablePromise<SolarEdgeManagementAlertStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-solar-edge-management-alert-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsSolarEdgeManagementAlertStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this solar edge management alert status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-solar-edge-management-alert-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
