/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ApiService } from './services/ApiService';
import { AsanaHistoryEntriesService } from './services/AsanaHistoryEntriesService';
import { AvatarService } from './services/AvatarService';
import { BigchangeConfigsService } from './services/BigchangeConfigsService';
import { BigchangeInfoService } from './services/BigchangeInfoService';
import { BigchangeJobTagsService } from './services/BigchangeJobTagsService';
import { BigchangeJobTypesService } from './services/BigchangeJobTypesService';
import { BigchangeNoteTypesService } from './services/BigchangeNoteTypesService';
import { BigchangeResourcesService } from './services/BigchangeResourcesService';
import { BigchangeStyleService } from './services/BigchangeStyleService';
import { BigchangeWebhookService } from './services/BigchangeWebhookService';
import { BigchangeWebusersService } from './services/BigchangeWebusersService';
import { BrandsService } from './services/BrandsService';
import { CacheService } from './services/CacheService';
import { CalendarService } from './services/CalendarService';
import { CategoriesService } from './services/CategoriesService';
import { ClientsService } from './services/ClientsService';
import { ClientsAbfStatusService } from './services/ClientsAbfStatusService';
import { ClientsAdditionalDocsService } from './services/ClientsAdditionalDocsService';
import { ClientsAppealTypesService } from './services/ClientsAppealTypesService';
import { ClientsCheckSentStatusService } from './services/ClientsCheckSentStatusService';
import { ClientsConnectionsService } from './services/ClientsConnectionsService';
import { ClientsConstructionDatesService } from './services/ClientsConstructionDatesService';
import { ClientsConstructionHyperTypesService } from './services/ClientsConstructionHyperTypesService';
import { ClientsConstructionStatusService } from './services/ClientsConstructionStatusService';
import { ClientsConstructionSubTypesService } from './services/ClientsConstructionSubTypesService';
import { ClientsConstructionTypesService } from './services/ClientsConstructionTypesService';
import { ClientsConsuelsService } from './services/ClientsConsuelsService';
import { ClientsConsuelStatusService } from './services/ClientsConsuelStatusService';
import { ClientsEdfOaContractActionStatusService } from './services/ClientsEdfOaContractActionStatusService';
import { ClientsEdfOasService } from './services/ClientsEdfOasService';
import { ClientsElecInstallPhotoStatusService } from './services/ClientsElecInstallPhotoStatusService';
import { ClientsEliosPhotoStatusService } from './services/ClientsEliosPhotoStatusService';
import { ClientsEliosTypesService } from './services/ClientsEliosTypesService';
import { ClientsEnedisStatusService } from './services/ClientsEnedisStatusService';
import { ClientsFilesService } from './services/ClientsFilesService';
import { ClientsGoogleReviewRequestStatusService } from './services/ClientsGoogleReviewRequestStatusService';
import { ClientsInstallPhotoStatusService } from './services/ClientsInstallPhotoStatusService';
import { ClientsInvoicesService } from './services/ClientsInvoicesService';
import { ClientsInvoiceStatusService } from './services/ClientsInvoiceStatusService';
import { ClientsLayoutPhotoStatusService } from './services/ClientsLayoutPhotoStatusService';
import { ClientsMandateStatusService } from './services/ClientsMandateStatusService';
import { ClientsPhotoStatusService } from './services/ClientsPhotoStatusService';
import { ClientsPlanningStatusService } from './services/ClientsPlanningStatusService';
import { ClientsPriorRequestStatusService } from './services/ClientsPriorRequestStatusService';
import { ClientsProductsService } from './services/ClientsProductsService';
import { ClientsQuoteProductsService } from './services/ClientsQuoteProductsService';
import { ClientsReceiptStatusService } from './services/ClientsReceiptStatusService';
import { ClientsReferencesContactStatusService } from './services/ClientsReferencesContactStatusService';
import { ClientsRefusalTypesService } from './services/ClientsRefusalTypesService';
import { ClientsSignedTranscriptStatusService } from './services/ClientsSignedTranscriptStatusService';
import { ClientsSolarEdgeManagementAlertStatusService } from './services/ClientsSolarEdgeManagementAlertStatusService';
import { ClientsTownHallsService } from './services/ClientsTownHallsService';
import { ClientsUnknownInvoicesService } from './services/ClientsUnknownInvoicesService';
import { ConfirmEmailService } from './services/ConfirmEmailService';
import { DatasheetsService } from './services/DatasheetsService';
import { DealsService } from './services/DealsService';
import { EventsService } from './services/EventsService';
import { EventsActionsService } from './services/EventsActionsService';
import { EventsEventActionDealCreatedService } from './services/EventsEventActionDealCreatedService';
import { EventsEventActionLeadCreatedService } from './services/EventsEventActionLeadCreatedService';
import { EventsEventActionLeadStatusChangeService } from './services/EventsEventActionLeadStatusChangeService';
import { EventsEventActionOfferSentService } from './services/EventsEventActionOfferSentService';
import { EventsEventActionQuoteCreatedService } from './services/EventsEventActionQuoteCreatedService';
import { EventsEventActionSiteCreatedService } from './services/EventsEventActionSiteCreatedService';
import { GifConfigService } from './services/GifConfigService';
import { GoogleService } from './services/GoogleService';
import { LeadsService } from './services/LeadsService';
import { LeadsAirConditioningHabitsService } from './services/LeadsAirConditioningHabitsService';
import { LeadsAlternatingCurrentTypesService } from './services/LeadsAlternatingCurrentTypesService';
import { LeadsBusinessIntroducersService } from './services/LeadsBusinessIntroducersService';
import { LeadsConsumptionsService } from './services/LeadsConsumptionsService';
import { LeadsDomesticHotWatersService } from './services/LeadsDomesticHotWatersService';
import { LeadsElectricityDistributorCitiesService } from './services/LeadsElectricityDistributorCitiesService';
import { LeadsElectricityDistributorsService } from './services/LeadsElectricityDistributorsService';
import { LeadsFloorsService } from './services/LeadsFloorsService';
import { LeadsHeatingTypesService } from './services/LeadsHeatingTypesService';
import { LeadsHousingInsulationsService } from './services/LeadsHousingInsulationsService';
import { LeadsHousingsService } from './services/LeadsHousingsService';
import { LeadsInterventionsService } from './services/LeadsInterventionsService';
import { LeadsLostSalesService } from './services/LeadsLostSalesService';
import { LeadsProvenancesService } from './services/LeadsProvenancesService';
import { LeadsPvgisService } from './services/LeadsPvgisService';
import { LeadsRemoteSiteDevicesService } from './services/LeadsRemoteSiteDevicesService';
import { LeadsRemoteSitesService } from './services/LeadsRemoteSitesService';
import { LeadsRoofSidesService } from './services/LeadsRoofSidesService';
import { LeadsSidePropertiesService } from './services/LeadsSidePropertiesService';
import { LeadsSitesService } from './services/LeadsSitesService';
import { LeadsSitesElectricalPanelImagesService } from './services/LeadsSitesElectricalPanelImagesService';
import { LeadsSitesImagesService } from './services/LeadsSitesImagesService';
import { LeadsSitesLayoutImagesService } from './services/LeadsSitesLayoutImagesService';
import { LeadsSitesRoofImagesService } from './services/LeadsSitesRoofImagesService';
import { LeadsSitesVtReportImagesService } from './services/LeadsSitesVtReportImagesService';
import { LeadsStatusService } from './services/LeadsStatusService';
import { LeadsTaskDifficultiesService } from './services/LeadsTaskDifficultiesService';
import { LeadsTechnicalVisitReportsService } from './services/LeadsTechnicalVisitReportsService';
import { LeadsTechnicalVisitStatusService } from './services/LeadsTechnicalVisitStatusService';
import { LeadsTypesService } from './services/LeadsTypesService';
import { LeadsVatRatesService } from './services/LeadsVatRatesService';
import { LoginService } from './services/LoginService';
import { LogoutService } from './services/LogoutService';
import { OffersService } from './services/OffersService';
import { OffersProductAdministrativeProceduresService } from './services/OffersProductAdministrativeProceduresService';
import { OffersProductBatteriesService } from './services/OffersProductBatteriesService';
import { OffersProductCablesService } from './services/OffersProductCablesService';
import { OffersProductElectricalProtectionsService } from './services/OffersProductElectricalProtectionsService';
import { OffersProductInvertersService } from './services/OffersProductInvertersService';
import { OffersProductMonitoringsService } from './services/OffersProductMonitoringsService';
import { OffersProductOptimisersService } from './services/OffersProductOptimisersService';
import { OffersProductSolarPanelsService } from './services/OffersProductSolarPanelsService';
import { OffersProductStructuresService } from './services/OffersProductStructuresService';
import { OffersProductWorkforcesService } from './services/OffersProductWorkforcesService';
import { PasswordResetService } from './services/PasswordResetService';
import { ProductsService } from './services/ProductsService';
import { ProductsAdministrativeProceduresService } from './services/ProductsAdministrativeProceduresService';
import { ProductsAirConditioningService } from './services/ProductsAirConditioningService';
import { ProductsAirConditioningSupplyService } from './services/ProductsAirConditioningSupplyService';
import { ProductsBatteriesService } from './services/ProductsBatteriesService';
import { ProductsBatteryHubsService } from './services/ProductsBatteryHubsService';
import { ProductsCablesService } from './services/ProductsCablesService';
import { ProductsCellTypesService } from './services/ProductsCellTypesService';
import { ProductsChangeInverterService } from './services/ProductsChangeInverterService';
import { ProductsDependenciesService } from './services/ProductsDependenciesService';
import { ProductsElectricalProtectionsService } from './services/ProductsElectricalProtectionsService';
import { ProductsHeatPumpService } from './services/ProductsHeatPumpService';
import { ProductsHeatPumpSupplyService } from './services/ProductsHeatPumpSupplyService';
import { ProductsInverterAccessoriesService } from './services/ProductsInverterAccessoriesService';
import { ProductsInvertersService } from './services/ProductsInvertersService';
import { ProductsMaterialsService } from './services/ProductsMaterialsService';
import { ProductsMonitoringsService } from './services/ProductsMonitoringsService';
import { ProductsOptimisersService } from './services/ProductsOptimisersService';
import { ProductsOtherMaterialService } from './services/ProductsOtherMaterialService';
import { ProductsOtherServiceService } from './services/ProductsOtherServiceService';
import { ProductsRegulatorsService } from './services/ProductsRegulatorsService';
import { ProductsServicesService } from './services/ProductsServicesService';
import { ProductsSolarPanelsService } from './services/ProductsSolarPanelsService';
import { ProductsStructuresService } from './services/ProductsStructuresService';
import { ProductsThermoBalloonService } from './services/ProductsThermoBalloonService';
import { ProductsWorkforcesService } from './services/ProductsWorkforcesService';
import { ProductsWorkforcesNoPvService } from './services/ProductsWorkforcesNoPvService';
import { QuotesService } from './services/QuotesService';
import { QuotesProductsService } from './services/QuotesProductsService';
import { RealtimeService } from './services/RealtimeService';
import { SlackService } from './services/SlackService';
import { UserConfigsService } from './services/UserConfigsService';
import { UsersService } from './services/UsersService';
import { VersionService } from './services/VersionService';
import { WhoamiService } from './services/WhoamiService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ManywattClient {

    public readonly api: ApiService;
    public readonly asanaHistoryEntries: AsanaHistoryEntriesService;
    public readonly avatar: AvatarService;
    public readonly bigchangeConfigs: BigchangeConfigsService;
    public readonly bigchangeInfo: BigchangeInfoService;
    public readonly bigchangeJobTags: BigchangeJobTagsService;
    public readonly bigchangeJobTypes: BigchangeJobTypesService;
    public readonly bigchangeNoteTypes: BigchangeNoteTypesService;
    public readonly bigchangeResources: BigchangeResourcesService;
    public readonly bigchangeStyle: BigchangeStyleService;
    public readonly bigchangeWebhook: BigchangeWebhookService;
    public readonly bigchangeWebusers: BigchangeWebusersService;
    public readonly brands: BrandsService;
    public readonly cache: CacheService;
    public readonly calendar: CalendarService;
    public readonly categories: CategoriesService;
    public readonly clients: ClientsService;
    public readonly clientsAbfStatus: ClientsAbfStatusService;
    public readonly clientsAdditionalDocs: ClientsAdditionalDocsService;
    public readonly clientsAppealTypes: ClientsAppealTypesService;
    public readonly clientsCheckSentStatus: ClientsCheckSentStatusService;
    public readonly clientsConnections: ClientsConnectionsService;
    public readonly clientsConstructionDates: ClientsConstructionDatesService;
    public readonly clientsConstructionHyperTypes: ClientsConstructionHyperTypesService;
    public readonly clientsConstructionStatus: ClientsConstructionStatusService;
    public readonly clientsConstructionSubTypes: ClientsConstructionSubTypesService;
    public readonly clientsConstructionTypes: ClientsConstructionTypesService;
    public readonly clientsConsuels: ClientsConsuelsService;
    public readonly clientsConsuelStatus: ClientsConsuelStatusService;
    public readonly clientsEdfOaContractActionStatus: ClientsEdfOaContractActionStatusService;
    public readonly clientsEdfOas: ClientsEdfOasService;
    public readonly clientsElecInstallPhotoStatus: ClientsElecInstallPhotoStatusService;
    public readonly clientsEliosPhotoStatus: ClientsEliosPhotoStatusService;
    public readonly clientsEliosTypes: ClientsEliosTypesService;
    public readonly clientsEnedisStatus: ClientsEnedisStatusService;
    public readonly clientsFiles: ClientsFilesService;
    public readonly clientsGoogleReviewRequestStatus: ClientsGoogleReviewRequestStatusService;
    public readonly clientsInstallPhotoStatus: ClientsInstallPhotoStatusService;
    public readonly clientsInvoices: ClientsInvoicesService;
    public readonly clientsInvoiceStatus: ClientsInvoiceStatusService;
    public readonly clientsLayoutPhotoStatus: ClientsLayoutPhotoStatusService;
    public readonly clientsMandateStatus: ClientsMandateStatusService;
    public readonly clientsPhotoStatus: ClientsPhotoStatusService;
    public readonly clientsPlanningStatus: ClientsPlanningStatusService;
    public readonly clientsPriorRequestStatus: ClientsPriorRequestStatusService;
    public readonly clientsProducts: ClientsProductsService;
    public readonly clientsQuoteProducts: ClientsQuoteProductsService;
    public readonly clientsReceiptStatus: ClientsReceiptStatusService;
    public readonly clientsReferencesContactStatus: ClientsReferencesContactStatusService;
    public readonly clientsRefusalTypes: ClientsRefusalTypesService;
    public readonly clientsSignedTranscriptStatus: ClientsSignedTranscriptStatusService;
    public readonly clientsSolarEdgeManagementAlertStatus: ClientsSolarEdgeManagementAlertStatusService;
    public readonly clientsTownHalls: ClientsTownHallsService;
    public readonly clientsUnknownInvoices: ClientsUnknownInvoicesService;
    public readonly confirmEmail: ConfirmEmailService;
    public readonly datasheets: DatasheetsService;
    public readonly deals: DealsService;
    public readonly events: EventsService;
    public readonly eventsActions: EventsActionsService;
    public readonly eventsEventActionDealCreated: EventsEventActionDealCreatedService;
    public readonly eventsEventActionLeadCreated: EventsEventActionLeadCreatedService;
    public readonly eventsEventActionLeadStatusChange: EventsEventActionLeadStatusChangeService;
    public readonly eventsEventActionOfferSent: EventsEventActionOfferSentService;
    public readonly eventsEventActionQuoteCreated: EventsEventActionQuoteCreatedService;
    public readonly eventsEventActionSiteCreated: EventsEventActionSiteCreatedService;
    public readonly gifConfig: GifConfigService;
    public readonly google: GoogleService;
    public readonly leads: LeadsService;
    public readonly leadsAirConditioningHabits: LeadsAirConditioningHabitsService;
    public readonly leadsAlternatingCurrentTypes: LeadsAlternatingCurrentTypesService;
    public readonly leadsBusinessIntroducers: LeadsBusinessIntroducersService;
    public readonly leadsConsumptions: LeadsConsumptionsService;
    public readonly leadsDomesticHotWaters: LeadsDomesticHotWatersService;
    public readonly leadsElectricityDistributorCities: LeadsElectricityDistributorCitiesService;
    public readonly leadsElectricityDistributors: LeadsElectricityDistributorsService;
    public readonly leadsFloors: LeadsFloorsService;
    public readonly leadsHeatingTypes: LeadsHeatingTypesService;
    public readonly leadsHousingInsulations: LeadsHousingInsulationsService;
    public readonly leadsHousings: LeadsHousingsService;
    public readonly leadsInterventions: LeadsInterventionsService;
    public readonly leadsLostSales: LeadsLostSalesService;
    public readonly leadsProvenances: LeadsProvenancesService;
    public readonly leadsPvgis: LeadsPvgisService;
    public readonly leadsRemoteSiteDevices: LeadsRemoteSiteDevicesService;
    public readonly leadsRemoteSites: LeadsRemoteSitesService;
    public readonly leadsRoofSides: LeadsRoofSidesService;
    public readonly leadsSideProperties: LeadsSidePropertiesService;
    public readonly leadsSites: LeadsSitesService;
    public readonly leadsSitesElectricalPanelImages: LeadsSitesElectricalPanelImagesService;
    public readonly leadsSitesImages: LeadsSitesImagesService;
    public readonly leadsSitesLayoutImages: LeadsSitesLayoutImagesService;
    public readonly leadsSitesRoofImages: LeadsSitesRoofImagesService;
    public readonly leadsSitesVtReportImages: LeadsSitesVtReportImagesService;
    public readonly leadsStatus: LeadsStatusService;
    public readonly leadsTaskDifficulties: LeadsTaskDifficultiesService;
    public readonly leadsTechnicalVisitReports: LeadsTechnicalVisitReportsService;
    public readonly leadsTechnicalVisitStatus: LeadsTechnicalVisitStatusService;
    public readonly leadsTypes: LeadsTypesService;
    public readonly leadsVatRates: LeadsVatRatesService;
    public readonly login: LoginService;
    public readonly logout: LogoutService;
    public readonly offers: OffersService;
    public readonly offersProductAdministrativeProcedures: OffersProductAdministrativeProceduresService;
    public readonly offersProductBatteries: OffersProductBatteriesService;
    public readonly offersProductCables: OffersProductCablesService;
    public readonly offersProductElectricalProtections: OffersProductElectricalProtectionsService;
    public readonly offersProductInverters: OffersProductInvertersService;
    public readonly offersProductMonitorings: OffersProductMonitoringsService;
    public readonly offersProductOptimisers: OffersProductOptimisersService;
    public readonly offersProductSolarPanels: OffersProductSolarPanelsService;
    public readonly offersProductStructures: OffersProductStructuresService;
    public readonly offersProductWorkforces: OffersProductWorkforcesService;
    public readonly passwordReset: PasswordResetService;
    public readonly products: ProductsService;
    public readonly productsAdministrativeProcedures: ProductsAdministrativeProceduresService;
    public readonly productsAirConditioning: ProductsAirConditioningService;
    public readonly productsAirConditioningSupply: ProductsAirConditioningSupplyService;
    public readonly productsBatteries: ProductsBatteriesService;
    public readonly productsBatteryHubs: ProductsBatteryHubsService;
    public readonly productsCables: ProductsCablesService;
    public readonly productsCellTypes: ProductsCellTypesService;
    public readonly productsChangeInverter: ProductsChangeInverterService;
    public readonly productsDependencies: ProductsDependenciesService;
    public readonly productsElectricalProtections: ProductsElectricalProtectionsService;
    public readonly productsHeatPump: ProductsHeatPumpService;
    public readonly productsHeatPumpSupply: ProductsHeatPumpSupplyService;
    public readonly productsInverterAccessories: ProductsInverterAccessoriesService;
    public readonly productsInverters: ProductsInvertersService;
    public readonly productsMaterials: ProductsMaterialsService;
    public readonly productsMonitorings: ProductsMonitoringsService;
    public readonly productsOptimisers: ProductsOptimisersService;
    public readonly productsOtherMaterial: ProductsOtherMaterialService;
    public readonly productsOtherService: ProductsOtherServiceService;
    public readonly productsRegulators: ProductsRegulatorsService;
    public readonly productsServices: ProductsServicesService;
    public readonly productsSolarPanels: ProductsSolarPanelsService;
    public readonly productsStructures: ProductsStructuresService;
    public readonly productsThermoBalloon: ProductsThermoBalloonService;
    public readonly productsWorkforces: ProductsWorkforcesService;
    public readonly productsWorkforcesNoPv: ProductsWorkforcesNoPvService;
    public readonly quotes: QuotesService;
    public readonly quotesProducts: QuotesProductsService;
    public readonly realtime: RealtimeService;
    public readonly slack: SlackService;
    public readonly userConfigs: UserConfigsService;
    public readonly users: UsersService;
    public readonly version: VersionService;
    public readonly whoami: WhoamiService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.api = new ApiService(this.request);
        this.asanaHistoryEntries = new AsanaHistoryEntriesService(this.request);
        this.avatar = new AvatarService(this.request);
        this.bigchangeConfigs = new BigchangeConfigsService(this.request);
        this.bigchangeInfo = new BigchangeInfoService(this.request);
        this.bigchangeJobTags = new BigchangeJobTagsService(this.request);
        this.bigchangeJobTypes = new BigchangeJobTypesService(this.request);
        this.bigchangeNoteTypes = new BigchangeNoteTypesService(this.request);
        this.bigchangeResources = new BigchangeResourcesService(this.request);
        this.bigchangeStyle = new BigchangeStyleService(this.request);
        this.bigchangeWebhook = new BigchangeWebhookService(this.request);
        this.bigchangeWebusers = new BigchangeWebusersService(this.request);
        this.brands = new BrandsService(this.request);
        this.cache = new CacheService(this.request);
        this.calendar = new CalendarService(this.request);
        this.categories = new CategoriesService(this.request);
        this.clients = new ClientsService(this.request);
        this.clientsAbfStatus = new ClientsAbfStatusService(this.request);
        this.clientsAdditionalDocs = new ClientsAdditionalDocsService(this.request);
        this.clientsAppealTypes = new ClientsAppealTypesService(this.request);
        this.clientsCheckSentStatus = new ClientsCheckSentStatusService(this.request);
        this.clientsConnections = new ClientsConnectionsService(this.request);
        this.clientsConstructionDates = new ClientsConstructionDatesService(this.request);
        this.clientsConstructionHyperTypes = new ClientsConstructionHyperTypesService(this.request);
        this.clientsConstructionStatus = new ClientsConstructionStatusService(this.request);
        this.clientsConstructionSubTypes = new ClientsConstructionSubTypesService(this.request);
        this.clientsConstructionTypes = new ClientsConstructionTypesService(this.request);
        this.clientsConsuels = new ClientsConsuelsService(this.request);
        this.clientsConsuelStatus = new ClientsConsuelStatusService(this.request);
        this.clientsEdfOaContractActionStatus = new ClientsEdfOaContractActionStatusService(this.request);
        this.clientsEdfOas = new ClientsEdfOasService(this.request);
        this.clientsElecInstallPhotoStatus = new ClientsElecInstallPhotoStatusService(this.request);
        this.clientsEliosPhotoStatus = new ClientsEliosPhotoStatusService(this.request);
        this.clientsEliosTypes = new ClientsEliosTypesService(this.request);
        this.clientsEnedisStatus = new ClientsEnedisStatusService(this.request);
        this.clientsFiles = new ClientsFilesService(this.request);
        this.clientsGoogleReviewRequestStatus = new ClientsGoogleReviewRequestStatusService(this.request);
        this.clientsInstallPhotoStatus = new ClientsInstallPhotoStatusService(this.request);
        this.clientsInvoices = new ClientsInvoicesService(this.request);
        this.clientsInvoiceStatus = new ClientsInvoiceStatusService(this.request);
        this.clientsLayoutPhotoStatus = new ClientsLayoutPhotoStatusService(this.request);
        this.clientsMandateStatus = new ClientsMandateStatusService(this.request);
        this.clientsPhotoStatus = new ClientsPhotoStatusService(this.request);
        this.clientsPlanningStatus = new ClientsPlanningStatusService(this.request);
        this.clientsPriorRequestStatus = new ClientsPriorRequestStatusService(this.request);
        this.clientsProducts = new ClientsProductsService(this.request);
        this.clientsQuoteProducts = new ClientsQuoteProductsService(this.request);
        this.clientsReceiptStatus = new ClientsReceiptStatusService(this.request);
        this.clientsReferencesContactStatus = new ClientsReferencesContactStatusService(this.request);
        this.clientsRefusalTypes = new ClientsRefusalTypesService(this.request);
        this.clientsSignedTranscriptStatus = new ClientsSignedTranscriptStatusService(this.request);
        this.clientsSolarEdgeManagementAlertStatus = new ClientsSolarEdgeManagementAlertStatusService(this.request);
        this.clientsTownHalls = new ClientsTownHallsService(this.request);
        this.clientsUnknownInvoices = new ClientsUnknownInvoicesService(this.request);
        this.confirmEmail = new ConfirmEmailService(this.request);
        this.datasheets = new DatasheetsService(this.request);
        this.deals = new DealsService(this.request);
        this.events = new EventsService(this.request);
        this.eventsActions = new EventsActionsService(this.request);
        this.eventsEventActionDealCreated = new EventsEventActionDealCreatedService(this.request);
        this.eventsEventActionLeadCreated = new EventsEventActionLeadCreatedService(this.request);
        this.eventsEventActionLeadStatusChange = new EventsEventActionLeadStatusChangeService(this.request);
        this.eventsEventActionOfferSent = new EventsEventActionOfferSentService(this.request);
        this.eventsEventActionQuoteCreated = new EventsEventActionQuoteCreatedService(this.request);
        this.eventsEventActionSiteCreated = new EventsEventActionSiteCreatedService(this.request);
        this.gifConfig = new GifConfigService(this.request);
        this.google = new GoogleService(this.request);
        this.leads = new LeadsService(this.request);
        this.leadsAirConditioningHabits = new LeadsAirConditioningHabitsService(this.request);
        this.leadsAlternatingCurrentTypes = new LeadsAlternatingCurrentTypesService(this.request);
        this.leadsBusinessIntroducers = new LeadsBusinessIntroducersService(this.request);
        this.leadsConsumptions = new LeadsConsumptionsService(this.request);
        this.leadsDomesticHotWaters = new LeadsDomesticHotWatersService(this.request);
        this.leadsElectricityDistributorCities = new LeadsElectricityDistributorCitiesService(this.request);
        this.leadsElectricityDistributors = new LeadsElectricityDistributorsService(this.request);
        this.leadsFloors = new LeadsFloorsService(this.request);
        this.leadsHeatingTypes = new LeadsHeatingTypesService(this.request);
        this.leadsHousingInsulations = new LeadsHousingInsulationsService(this.request);
        this.leadsHousings = new LeadsHousingsService(this.request);
        this.leadsInterventions = new LeadsInterventionsService(this.request);
        this.leadsLostSales = new LeadsLostSalesService(this.request);
        this.leadsProvenances = new LeadsProvenancesService(this.request);
        this.leadsPvgis = new LeadsPvgisService(this.request);
        this.leadsRemoteSiteDevices = new LeadsRemoteSiteDevicesService(this.request);
        this.leadsRemoteSites = new LeadsRemoteSitesService(this.request);
        this.leadsRoofSides = new LeadsRoofSidesService(this.request);
        this.leadsSideProperties = new LeadsSidePropertiesService(this.request);
        this.leadsSites = new LeadsSitesService(this.request);
        this.leadsSitesElectricalPanelImages = new LeadsSitesElectricalPanelImagesService(this.request);
        this.leadsSitesImages = new LeadsSitesImagesService(this.request);
        this.leadsSitesLayoutImages = new LeadsSitesLayoutImagesService(this.request);
        this.leadsSitesRoofImages = new LeadsSitesRoofImagesService(this.request);
        this.leadsSitesVtReportImages = new LeadsSitesVtReportImagesService(this.request);
        this.leadsStatus = new LeadsStatusService(this.request);
        this.leadsTaskDifficulties = new LeadsTaskDifficultiesService(this.request);
        this.leadsTechnicalVisitReports = new LeadsTechnicalVisitReportsService(this.request);
        this.leadsTechnicalVisitStatus = new LeadsTechnicalVisitStatusService(this.request);
        this.leadsTypes = new LeadsTypesService(this.request);
        this.leadsVatRates = new LeadsVatRatesService(this.request);
        this.login = new LoginService(this.request);
        this.logout = new LogoutService(this.request);
        this.offers = new OffersService(this.request);
        this.offersProductAdministrativeProcedures = new OffersProductAdministrativeProceduresService(this.request);
        this.offersProductBatteries = new OffersProductBatteriesService(this.request);
        this.offersProductCables = new OffersProductCablesService(this.request);
        this.offersProductElectricalProtections = new OffersProductElectricalProtectionsService(this.request);
        this.offersProductInverters = new OffersProductInvertersService(this.request);
        this.offersProductMonitorings = new OffersProductMonitoringsService(this.request);
        this.offersProductOptimisers = new OffersProductOptimisersService(this.request);
        this.offersProductSolarPanels = new OffersProductSolarPanelsService(this.request);
        this.offersProductStructures = new OffersProductStructuresService(this.request);
        this.offersProductWorkforces = new OffersProductWorkforcesService(this.request);
        this.passwordReset = new PasswordResetService(this.request);
        this.products = new ProductsService(this.request);
        this.productsAdministrativeProcedures = new ProductsAdministrativeProceduresService(this.request);
        this.productsAirConditioning = new ProductsAirConditioningService(this.request);
        this.productsAirConditioningSupply = new ProductsAirConditioningSupplyService(this.request);
        this.productsBatteries = new ProductsBatteriesService(this.request);
        this.productsBatteryHubs = new ProductsBatteryHubsService(this.request);
        this.productsCables = new ProductsCablesService(this.request);
        this.productsCellTypes = new ProductsCellTypesService(this.request);
        this.productsChangeInverter = new ProductsChangeInverterService(this.request);
        this.productsDependencies = new ProductsDependenciesService(this.request);
        this.productsElectricalProtections = new ProductsElectricalProtectionsService(this.request);
        this.productsHeatPump = new ProductsHeatPumpService(this.request);
        this.productsHeatPumpSupply = new ProductsHeatPumpSupplyService(this.request);
        this.productsInverterAccessories = new ProductsInverterAccessoriesService(this.request);
        this.productsInverters = new ProductsInvertersService(this.request);
        this.productsMaterials = new ProductsMaterialsService(this.request);
        this.productsMonitorings = new ProductsMonitoringsService(this.request);
        this.productsOptimisers = new ProductsOptimisersService(this.request);
        this.productsOtherMaterial = new ProductsOtherMaterialService(this.request);
        this.productsOtherService = new ProductsOtherServiceService(this.request);
        this.productsRegulators = new ProductsRegulatorsService(this.request);
        this.productsServices = new ProductsServicesService(this.request);
        this.productsSolarPanels = new ProductsSolarPanelsService(this.request);
        this.productsStructures = new ProductsStructuresService(this.request);
        this.productsThermoBalloon = new ProductsThermoBalloonService(this.request);
        this.productsWorkforces = new ProductsWorkforcesService(this.request);
        this.productsWorkforcesNoPv = new ProductsWorkforcesNoPvService(this.request);
        this.quotes = new QuotesService(this.request);
        this.quotesProducts = new QuotesProductsService(this.request);
        this.realtime = new RealtimeService(this.request);
        this.slack = new SlackService(this.request);
        this.userConfigs = new UserConfigsService(this.request);
        this.users = new UsersService(this.request);
        this.version = new VersionService(this.request);
        this.whoami = new WhoamiService(this.request);
    }
}

