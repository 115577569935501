/** @format */

import withStyles from '@mui/styles/withStyles';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';

const styles = () => ({});

/**
 * @deprecated Use NewTooltip instead
 */
class Tooltip extends Component {
  render() {
    const {
      children,
      content,
      placement,
      useHTML,
      onShow,
      onHide,
      onClickOutside,
      interactive,
      visible,
      tooltipTheme,
      maxWidth,
      hideOnClick,
      trigger,
      onTrigger,
      noSpan,
      funcChildren,
    } = this.props;
    return (
      <Tippy
        content={content}
        disabled={!content}
        placement={placement}
        animation="scale-subtle"
        allowHTML={useHTML}
        onShow={onShow}
        onHide={onHide}
        onClickOutside={onClickOutside}
        interactive={interactive}
        interactiveBorder={interactive ? 10 : undefined}
        interactiveDebounce={interactive ? 75 : undefined}
        visible={visible}
        theme={tooltipTheme}
        maxWidth={maxWidth}
        appendTo={document.body}
        hideOnClick={hideOnClick}
        trigger={trigger}
        onTrigger={onTrigger}
      >
        {noSpan ? (
          <>{funcChildren ? children(this.props) : children}</>
        ) : (
          <span>{children}</span>
        )}
      </Tippy>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  placement: PropTypes.string,
  useHTML: PropTypes.bool,
  visible: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  onClickOutside: PropTypes.func,
  interactive: PropTypes.bool,
  tooltipTheme: PropTypes.oneOf([undefined, 'light', 'light-border']),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideOnClick: PropTypes.bool,
  trigger: PropTypes.string,
  onTrigger: PropTypes.func,
  noSpan: PropTypes.bool,
  funcChildren: PropTypes.bool,
};

Tooltip.defaultProps = {
  content: null,
  placement: 'top',
  useHTML: false,
  visible: undefined,
  onShow: () => null,
  onHide: () => null,
  onClickOutside: () => null,
  interactive: false,
  tooltipTheme: undefined,
  maxWidth: undefined,
  hideOnClick: true,
  trigger: 'mouseenter focus',
  onTrigger: () => null,
  noSpan: false,
  funcChildren: false,
};

export default withStyles(styles, { withTheme: true })(Tooltip);
