/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Action } from '../models/Action';
import type { PaginatedActionList } from '../models/PaginatedActionList';
import type { PatchedAction } from '../models/PatchedAction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsActionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedActionList
     * @throws ApiError
     */
    public eventsActionsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedActionList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-actions/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Action
     * @throws ApiError
     */
    public eventsActionsCreate({
        requestBody,
    }: {
        requestBody: Action,
    }): CancelablePromise<Action> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events-actions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Action
     * @throws ApiError
     */
    public eventsActionsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this action.
         */
        id: number,
    }): CancelablePromise<Action> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-actions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Action
     * @throws ApiError
     */
    public eventsActionsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this action.
         */
        id: number,
        requestBody: Action,
    }): CancelablePromise<Action> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events-actions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Action
     * @throws ApiError
     */
    public eventsActionsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this action.
         */
        id: number,
        requestBody?: PatchedAction,
    }): CancelablePromise<Action> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events-actions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsActionsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this action.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events-actions/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
