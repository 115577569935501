/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheDate } from '../models/CacheDate';
import type { PaginatedCacheDateList } from '../models/PaginatedCacheDateList';
import type { PatchedCacheDate } from '../models/PatchedCacheDate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CacheService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedCacheDateList
     * @throws ApiError
     */
    public cacheList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedCacheDateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cache/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns CacheDate
     * @throws ApiError
     */
    public cacheCreate({
        requestBody,
    }: {
        requestBody?: CacheDate,
    }): CancelablePromise<CacheDate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/cache/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CacheDate
     * @throws ApiError
     */
    public cacheRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this cache date.
         */
        id: number,
    }): CancelablePromise<CacheDate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cache/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CacheDate
     * @throws ApiError
     */
    public cacheUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this cache date.
         */
        id: number,
        requestBody?: CacheDate,
    }): CancelablePromise<CacheDate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/cache/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CacheDate
     * @throws ApiError
     */
    public cachePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this cache date.
         */
        id: number,
        requestBody?: PatchedCacheDate,
    }): CancelablePromise<CacheDate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/cache/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public cacheDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this cache date.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/cache/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
