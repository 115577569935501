/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientFiles } from '../models/ClientFiles';
import type { PaginatedClientFilesList } from '../models/PaginatedClientFilesList';
import type { PatchedClientFiles } from '../models/PatchedClientFiles';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsFilesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientFilesList
     * @throws ApiError
     */
    public clientsFilesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientFilesList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-files/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesCreate({
        requestBody,
    }: {
        requestBody: ClientFiles,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-files/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-files/{client}/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
        requestBody: ClientFiles,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-files/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesPartialUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
        requestBody?: PatchedClientFiles,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-files/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsFilesDestroy({
        client,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-files/{client}/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * Get a file present in the client's folder (img or pdf).
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response with the file's data
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesGetFilePreviewRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-files/{client}/get_file_preview/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * Send a message on Slack to alert when there is an issue with a file to the project manager.
     * :param request: Django request
     * :param pk: Primary key
     * :return: HTTP Response
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesGetQuoteRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-files/{client}/get_quote/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * Send a message on Slack to alert when there is an issue with a file to the project manager.
     * :param request: Django request
     * :param pk: Primary key
     * :return: HTTP Response
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesSendFileWarningCreate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
        requestBody: ClientFiles,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-files/{client}/send_file_warning/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update the dropbox folder of the client, and update the information in
     * database according to the changes.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Reponse
     * @returns ClientFiles
     * @throws ApiError
     */
    public clientsFilesUpdateFilesCreate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client files.
         */
        client: number,
        requestBody: ClientFiles,
    }): CancelablePromise<ClientFiles> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-files/{client}/update_files/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
