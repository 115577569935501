/** @format */

import {
  faClipboardListCheck,
  faMessagePlus,
  faScrewdriverWrench,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ThemeProvider } from '../../../theme';

const items = [
  {
    title: 'Demande diverse',
    icon: faMessagePlus,
    color: 'secondary',
    id: 0,
  },
  {
    title: 'Ticket SAV',
    icon: faScrewdriverWrench,
    color: 'error',
    id: 1,
  },
  {
    title: 'Visite technique',
    icon: faClipboardListCheck,
    color: 'primary',
    id: 2,
  },
];

function UITest() {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(0);

  return (
    <ThemeProvider>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Nouvelle demande</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={4} key={item.title}>
                <Card
                  variant="outlined"
                  sx={{
                    border: 0,
                    boxShadow: 0,
                    bgcolor:
                      active === item.id ? `${item.color}.lighter` : undefined,
                    cursor: 'pointer',
                  }}
                  onClick={() => setActive(item.id)}
                >
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item>
                        <Avatar
                          sx={{
                            width: 130,
                            height: 130,
                            bgcolor: `${item.color}.main`,
                          }}
                        >
                          <FontAwesomeIcon icon={item.icon} size="4x" />
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} />
                      <Grid item>
                        <Typography
                          variant="h5"
                          sx={{ color: `${item.color}.main` }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Nouvelle demande
      </Button>
    </ThemeProvider>
  );
}

export default UITest;
