/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConstructionType } from '../models/ConstructionType';
import type { PaginatedConstructionTypeList } from '../models/PaginatedConstructionTypeList';
import type { PatchedConstructionType } from '../models/PatchedConstructionType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConstructionTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedConstructionTypeList
     * @throws ApiError
     */
    public clientsConstructionTypesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedConstructionTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-types/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ConstructionType
     * @throws ApiError
     */
    public clientsConstructionTypesCreate({
        requestBody,
    }: {
        requestBody: ConstructionType,
    }): CancelablePromise<ConstructionType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-construction-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConstructionType
     * @throws ApiError
     */
    public clientsConstructionTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this construction type.
         */
        id: number,
    }): CancelablePromise<ConstructionType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConstructionType
     * @throws ApiError
     */
    public clientsConstructionTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this construction type.
         */
        id: number,
        requestBody: ConstructionType,
    }): CancelablePromise<ConstructionType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-construction-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConstructionType
     * @throws ApiError
     */
    public clientsConstructionTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this construction type.
         */
        id: number,
        requestBody?: PatchedConstructionType,
    }): CancelablePromise<ConstructionType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-construction-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConstructionTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this construction type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-construction-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
