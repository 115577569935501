/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductMonitoringList } from '../models/PaginatedProductMonitoringList';
import type { PatchedProductMonitoring } from '../models/PatchedProductMonitoring';
import type { ProductMonitoring } from '../models/ProductMonitoring';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsMonitoringsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductMonitoringList
     * @throws ApiError
     */
    public productsMonitoringsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductMonitoringList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-monitorings/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductMonitoring
     * @throws ApiError
     */
    public productsMonitoringsCreate({
        requestBody,
    }: {
        requestBody: ProductMonitoring,
    }): CancelablePromise<ProductMonitoring> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-monitorings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductMonitoring
     * @throws ApiError
     */
    public productsMonitoringsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product monitoring.
         */
        id: number,
    }): CancelablePromise<ProductMonitoring> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-monitorings/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductMonitoring
     * @throws ApiError
     */
    public productsMonitoringsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product monitoring.
         */
        id: number,
        requestBody: ProductMonitoring,
    }): CancelablePromise<ProductMonitoring> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-monitorings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductMonitoring
     * @throws ApiError
     */
    public productsMonitoringsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product monitoring.
         */
        id: number,
        requestBody?: PatchedProductMonitoring,
    }): CancelablePromise<ProductMonitoring> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-monitorings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsMonitoringsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product monitoring.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-monitorings/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
