/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LostSale } from '../models/LostSale';
import type { PaginatedLostSaleList } from '../models/PaginatedLostSaleList';
import type { PatchedLostSale } from '../models/PatchedLostSale';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsLostSalesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLostSaleList
     * @throws ApiError
     */
    public leadsLostSalesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedLostSaleList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-lost-sales/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns LostSale
     * @throws ApiError
     */
    public leadsLostSalesCreate({
        requestBody,
    }: {
        requestBody: LostSale,
    }): CancelablePromise<LostSale> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-lost-sales/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LostSale
     * @throws ApiError
     */
    public leadsLostSalesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lost sale.
         */
        id: number,
    }): CancelablePromise<LostSale> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-lost-sales/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LostSale
     * @throws ApiError
     */
    public leadsLostSalesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lost sale.
         */
        id: number,
        requestBody: LostSale,
    }): CancelablePromise<LostSale> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-lost-sales/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LostSale
     * @throws ApiError
     */
    public leadsLostSalesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lost sale.
         */
        id: number,
        requestBody?: PatchedLostSale,
    }): CancelablePromise<LostSale> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-lost-sales/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsLostSalesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lost sale.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-lost-sales/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
