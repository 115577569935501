/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductRegulatorList } from '../models/PaginatedProductRegulatorList';
import type { PatchedProductRegulator } from '../models/PatchedProductRegulator';
import type { ProductRegulator } from '../models/ProductRegulator';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsRegulatorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductRegulatorList
     * @throws ApiError
     */
    public productsRegulatorsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductRegulatorList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-regulators/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductRegulator
     * @throws ApiError
     */
    public productsRegulatorsCreate({
        requestBody,
    }: {
        requestBody: ProductRegulator,
    }): CancelablePromise<ProductRegulator> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-regulators/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductRegulator
     * @throws ApiError
     */
    public productsRegulatorsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product regulator.
         */
        id: number,
    }): CancelablePromise<ProductRegulator> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-regulators/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductRegulator
     * @throws ApiError
     */
    public productsRegulatorsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product regulator.
         */
        id: number,
        requestBody: ProductRegulator,
    }): CancelablePromise<ProductRegulator> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-regulators/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductRegulator
     * @throws ApiError
     */
    public productsRegulatorsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product regulator.
         */
        id: number,
        requestBody?: PatchedProductRegulator,
    }): CancelablePromise<ProductRegulator> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-regulators/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsRegulatorsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product regulator.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-regulators/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
