/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AirConditioningHabits } from '../models/AirConditioningHabits';
import type { PaginatedAirConditioningHabitsList } from '../models/PaginatedAirConditioningHabitsList';
import type { PatchedAirConditioningHabits } from '../models/PatchedAirConditioningHabits';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsAirConditioningHabitsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedAirConditioningHabitsList
     * @throws ApiError
     */
    public leadsAirConditioningHabitsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedAirConditioningHabitsList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-air-conditioning-habits/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns AirConditioningHabits
     * @throws ApiError
     */
    public leadsAirConditioningHabitsCreate({
        requestBody,
    }: {
        requestBody: AirConditioningHabits,
    }): CancelablePromise<AirConditioningHabits> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-air-conditioning-habits/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AirConditioningHabits
     * @throws ApiError
     */
    public leadsAirConditioningHabitsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this air conditioning habit.
         */
        id: number,
    }): CancelablePromise<AirConditioningHabits> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-air-conditioning-habits/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AirConditioningHabits
     * @throws ApiError
     */
    public leadsAirConditioningHabitsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this air conditioning habit.
         */
        id: number,
        requestBody: AirConditioningHabits,
    }): CancelablePromise<AirConditioningHabits> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-air-conditioning-habits/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AirConditioningHabits
     * @throws ApiError
     */
    public leadsAirConditioningHabitsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this air conditioning habit.
         */
        id: number,
        requestBody?: PatchedAirConditioningHabits,
    }): CancelablePromise<AirConditioningHabits> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-air-conditioning-habits/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsAirConditioningHabitsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this air conditioning habit.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-air-conditioning-habits/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
