/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPVGISList } from '../models/PaginatedPVGISList';
import type { PatchedPVGIS } from '../models/PatchedPVGIS';
import type { PVGIS } from '../models/PVGIS';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsPvgisService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedPVGISList
     * @throws ApiError
     */
    public leadsPvgisList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedPVGISList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-pvgis/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns PVGIS
     * @throws ApiError
     */
    public leadsPvgisCreate({
        requestBody,
    }: {
        requestBody: PVGIS,
    }): CancelablePromise<PVGIS> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-pvgis/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PVGIS
     * @throws ApiError
     */
    public leadsPvgisRetrieve({
        site,
    }: {
        /**
         * A unique value identifying this PVGIS.
         */
        site: number,
    }): CancelablePromise<PVGIS> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-pvgis/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns PVGIS
     * @throws ApiError
     */
    public leadsPvgisUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this PVGIS.
         */
        site: number,
        requestBody: PVGIS,
    }): CancelablePromise<PVGIS> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-pvgis/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PVGIS
     * @throws ApiError
     */
    public leadsPvgisPartialUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this PVGIS.
         */
        site: number,
        requestBody?: PatchedPVGIS,
    }): CancelablePromise<PVGIS> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-pvgis/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsPvgisDestroy({
        site,
    }: {
        /**
         * A unique value identifying this PVGIS.
         */
        site: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-pvgis/{site}/',
            path: {
                'site': site,
            },
        });
    }

}
