/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RealtimeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public realtimeAdagesC9235Ccadc50Ff06A552D846Fc479966Retrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/realtime/adages/c9235ccadc50ff06a552d846fc479966/',
        });
    }

}
