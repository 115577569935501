/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductElectricalProtectionList } from '../models/PaginatedProductElectricalProtectionList';
import type { PatchedProductElectricalProtection } from '../models/PatchedProductElectricalProtection';
import type { ProductElectricalProtection } from '../models/ProductElectricalProtection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsElectricalProtectionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductElectricalProtectionList
     * @throws ApiError
     */
    public productsElectricalProtectionsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductElectricalProtectionList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-electrical-protections/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductElectricalProtection
     * @throws ApiError
     */
    public productsElectricalProtectionsCreate({
        requestBody,
    }: {
        requestBody: ProductElectricalProtection,
    }): CancelablePromise<ProductElectricalProtection> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-electrical-protections/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductElectricalProtection
     * @throws ApiError
     */
    public productsElectricalProtectionsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product electrical protection.
         */
        id: number,
    }): CancelablePromise<ProductElectricalProtection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-electrical-protections/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductElectricalProtection
     * @throws ApiError
     */
    public productsElectricalProtectionsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product electrical protection.
         */
        id: number,
        requestBody: ProductElectricalProtection,
    }): CancelablePromise<ProductElectricalProtection> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-electrical-protections/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductElectricalProtection
     * @throws ApiError
     */
    public productsElectricalProtectionsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product electrical protection.
         */
        id: number,
        requestBody?: PatchedProductElectricalProtection,
    }): CancelablePromise<ProductElectricalProtection> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-electrical-protections/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsElectricalProtectionsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product electrical protection.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-electrical-protections/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
