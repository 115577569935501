/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';
import type { PaginatedClientList } from '../models/PaginatedClientList';
import type { PatchedClient } from '../models/PatchedClient';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientList
     * @throws ApiError
     */
    public clientsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedClientList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * Create a client in database.
     * This method is used only when a quote is signed. It creates a ClientQuote and
     * ClientQuoteProducts based on signed quote information.
     * It also creates client, quote and invoice in Sellsy.
     * :param request: Django request
     * :param args: args
     * :param kwargs: kwargs
     * :return: Response with Client created
     * @returns Client
     * @throws ApiError
     */
    public clientsCreate({
        requestBody,
    }: {
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Client
     * @throws ApiError
     */
    public clientsRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Client
     * @throws ApiError
     */
    public clientsPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedClient,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Add construction dates for a client.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsAddConstructionDatesCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/add_construction_dates/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete all the construction dates of a client.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns void
     * @throws ApiError
     */
    public clientsDeleteConstructionDatesDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients/{id}/delete_construction_dates/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Create the prior request file from client's information
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsGeneratePriorRequestCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/generate_prior_request/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the plot's image from geoportal
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response with the plot's image
     * @returns Client
     * @throws ApiError
     */
    public clientsGeoportalImagesRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{id}/geoportal_images/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get the products to display in the planning mail to the technicians.
     * :param request: Django request
     * :param pk: primary key of the client
     * :return: HTTP Response with products information
     * @returns Client
     * @throws ApiError
     */
    public clientsPlanningProductsRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{id}/planning_products/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get the prior requets file.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Reponse with prior request file
     * @returns Client
     * @throws ApiError
     */
    public clientsPriorRequestRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{id}/prior_request/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Send the 'DP mail' when the prior request is done.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendDpMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_DP_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send the 'billing done mail' when an edf_oa invoice is done.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendBillingDoneMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_billing_done_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send the 'billing mail' to remind the client to send his injection index to us.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendBillingMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_billing_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send the 'planning mail' to the client when his construction is planned.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendPlanningClientMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_planning_client_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send a mail to the technicians when a construction is planned.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendPlanningMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_planning_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send the prior request file by mail.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendPriorRequestCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_prior_request/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send the 'service start mail' when the connection service is effective.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsSendServiceStartMailCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/send_service_start_mail/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update client's additional documents and update the status according to the new information.
     * :param request: Django request
     * :param pk: Primary key of the client.
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdateAdditionalDocsCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/update_additional_docs/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update client's edf_oa bills and updating the status according to the new information.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdateEdfOaBillsCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/update_edf_oa_bills/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update the Google calendar used for the construction planning.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdateGoogleCalendarCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/update_google_calendar/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update client's invoices and updating the status according to the new information.
     * This method is used only for clients who don't have invoice in Sellsy.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdateInvoicesCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/update_invoices/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update all the information of a client final quote. Both products and discounts information.
     * Update also the quote and the invoice of Sellsy if existing.
     * :param request: Django request
     * :param pk: Primary key of the client
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsUpdateProductsCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{id}/update_products/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get stats used to verify clients data.
     * The datas are filtered if it's a project manager who use this request.
     * :param request: Django request
     * :return: HTTP Reponse with data
     * @returns Client
     * @throws ApiError
     */
    public clientsCheckStatsRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/check_stats/',
        });
    }

    /**
     * Export edf_oa tab in a csv file.
     * :param request: Django request
     * :return: A csv file with datas of edf_oa tab
     * @returns Client
     * @throws ApiError
     */
    public clientsExportEdfOaTabRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/export_edf_oa_tab/',
        });
    }

    /**
     * Export data clients data related to a project manager.
     * :param request: Django request
     * :return: A csv file with datas
     * @returns Client
     * @throws ApiError
     */
    public clientsExportPmStatsRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/export_pm_stats/',
        });
    }

    /**
     * Get general stats.
     * The datas are filtered if it's a project manager who use this request.
     * :param request: Django request
     * :return: HTTP Response with stats
     * @returns Client
     * @throws ApiError
     */
    public clientsGeneralStatsRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/general_stats/',
        });
    }

    /**
     * Get the number of clients includes in balance filter 1, 2 and 3:
     * - balance_filter_1: Clients who have not paid their invoice 7 days after the construction
     * - balance_filter_2: Clients who have not paid their invoice 14 days after the construction
     * - balance_filter_3: Clients who have not paid their invoice and their construction is done
     * :param request: Django request
     * :return: HTTP Response with number of clients for every balance filter
     * @returns Client
     * @throws ApiError
     */
    public clientsGetBalanceStatusCountRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/get_balance_status_count/',
        });
    }

    /**
     * Get all status by a tab to be able to filter clients by status.
     * :param request: Django request
     * :return: HTTP Response with status data
     * @returns Client
     * @throws ApiError
     */
    public clientsGetStatusRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/get_status/',
        });
    }

    /**
     * Synchronize the order sheet.
     * :param request: Django request
     * :return: HTTP Response
     * @returns Client
     * @throws ApiError
     */
    public clientsOrdersForceSyncCreate({
        requestBody,
    }: {
        requestBody: Client,
    }): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/orders_force_sync/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get stats about clients by project manager.
     * The datas are filtered if it's a project manager who use this request.
     * :param request: Django request
     * :return: HTTP Response with stats
     * @returns Client
     * @throws ApiError
     */
    public clientsPmStatsRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/pm_stats/',
        });
    }

    /**
     * Get global stats about clients. Request allowed only for superusers and admin leaders.
     * :param request: Django request
     * :return: HTTP Response with global stats
     * @returns Client
     * @throws ApiError
     */
    public clientsStatsRetrieve(): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/stats/',
        });
    }

}
