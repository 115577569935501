/** @format */

import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Box,
  CardHeader,
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { genders } from '../../../utils/constants';
import FollowUpDialogMobile from './FollowUpDialogMobile';
import { api } from '../../../api';
import { parseFieldError } from '../../../utils/errors';
import { selectAuth } from '../../../redux/authSlice';

function FollowUpMobileMeetingPage() {
  const [leads, setLeads] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(selectAuth);

  /**
   * Loads leads from the API filtered by project_manager=user.id
   * @returns {Promise<void>}
   */
  const loadLeads = () => {
    setLoading(true);
    return api
      .get(
        `leads/?filter=True&project_manager=${user.id}&is_test=false&is_follow_up=true`,
      )
      .then((result) => {
        const today = new Date().toISOString().split('T')[0];
        const filteredLeads = result.data.results.filter((lead) => {
          const scheduledDate =
            lead.follow_up_meeting?.scheduled_date?.split('T')[0];
          return (
            scheduledDate === today &&
            (lead.current_follow_up_status.is_follow_up_r2 ||
              lead.current_follow_up_status.is_confirmation_r2)
          );
        });
        setLeads(filteredLeads);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };

  useEffect(() => {
    loadLeads();
  }, []);

  return (
    <Box mx={3} mb={3} loading={loading}>
      {leads && leads.length > 0 ? (
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: 'bold', color: 'primary.main' }}
              >
                Vous avez {leads.length} rendez-vous aujourd&apos;hui
              </Typography>
            </Grid>
            {leads.length > 0 &&
              leads.map((lead) => (
                <Grid key={lead.id} item xs={12} sm={6}>
                  <Card sx={{ borderRadius: '16px', width: '100%' }}>
                    <CardHeader
                      sx={{ bgcolor: 'primary.main' }}
                      title={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <AccessTimeIcon sx={{ color: 'white' }} />
                            <Typography
                              variant="subtitle1"
                              sx={{
                                marginLeft: 1,
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                            >
                              RDV à{' '}
                              {new Date(lead.follow_up_meeting?.scheduled_date)
                                .toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })
                                .replace(':', 'h')}
                              {/* RDV à {lead.follow_up_meeting?.scheduled_date}/ */}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                    <CardContent sx={{ paddingX: 2 }}>
                      <Grid container spacing={4} flexDirection="column">
                        <Grid item xs={12} container>
                          <Avatar sx={{ bgcolor: '#c5e1a5', marginRight: 2 }}>
                            {`${lead.first_name[0]}${lead.last_name[0]}`}
                          </Avatar>
                          <Stack>
                            <Typography variant="h6">
                              {genders[lead.gender]} {lead.first_name}{' '}
                              {lead.last_name}
                            </Typography>
                            <Typography variant="body2">
                              {lead.phone_1 ?? lead.phone_2}
                            </Typography>
                            <Typography variant="body2">
                              {lead.address_1}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Box>
                      <Divider sx={{ mx: 1.5 }} />
                    </Box>
                    <CardActions sx={{ paddingX: 2 }}>
                      <FollowUpDialogMobile lead={lead} loadLeads={loadLeads} />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <Box mb={3}>
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            Bonjour, pas de rendez-vous aujourd&apos;hui
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FollowUpMobileMeetingPage;
