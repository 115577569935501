/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventActionOfferSent } from '../models/EventActionOfferSent';
import type { PaginatedEventActionOfferSentList } from '../models/PaginatedEventActionOfferSentList';
import type { PatchedEventActionOfferSent } from '../models/PatchedEventActionOfferSent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsEventActionOfferSentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedEventActionOfferSentList
     * @throws ApiError
     */
    public eventsEventActionOfferSentList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedEventActionOfferSentList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-offer-sent/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns EventActionOfferSent
     * @throws ApiError
     */
    public eventsEventActionOfferSentCreate({
        requestBody,
    }: {
        requestBody: EventActionOfferSent,
    }): CancelablePromise<EventActionOfferSent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events-event-action-offer-sent/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionOfferSent
     * @throws ApiError
     */
    public eventsEventActionOfferSentRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event action offer sent.
         */
        id: number,
    }): CancelablePromise<EventActionOfferSent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-offer-sent/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventActionOfferSent
     * @throws ApiError
     */
    public eventsEventActionOfferSentUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action offer sent.
         */
        id: number,
        requestBody: EventActionOfferSent,
    }): CancelablePromise<EventActionOfferSent> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events-event-action-offer-sent/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionOfferSent
     * @throws ApiError
     */
    public eventsEventActionOfferSentPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action offer sent.
         */
        id: number,
        requestBody?: PatchedEventActionOfferSent,
    }): CancelablePromise<EventActionOfferSent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events-event-action-offer-sent/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsEventActionOfferSentDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this event action offer sent.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events-event-action-offer-sent/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
