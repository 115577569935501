/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HousingInsulation } from '../models/HousingInsulation';
import type { PaginatedHousingInsulationList } from '../models/PaginatedHousingInsulationList';
import type { PatchedHousingInsulation } from '../models/PatchedHousingInsulation';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsHousingInsulationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedHousingInsulationList
     * @throws ApiError
     */
    public leadsHousingInsulationsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedHousingInsulationList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-housing-insulations/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns HousingInsulation
     * @throws ApiError
     */
    public leadsHousingInsulationsCreate({
        requestBody,
    }: {
        requestBody: HousingInsulation,
    }): CancelablePromise<HousingInsulation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-housing-insulations/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HousingInsulation
     * @throws ApiError
     */
    public leadsHousingInsulationsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this housing insulation.
         */
        id: number,
    }): CancelablePromise<HousingInsulation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-housing-insulations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HousingInsulation
     * @throws ApiError
     */
    public leadsHousingInsulationsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this housing insulation.
         */
        id: number,
        requestBody: HousingInsulation,
    }): CancelablePromise<HousingInsulation> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-housing-insulations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HousingInsulation
     * @throws ApiError
     */
    public leadsHousingInsulationsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this housing insulation.
         */
        id: number,
        requestBody?: PatchedHousingInsulation,
    }): CancelablePromise<HousingInsulation> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-housing-insulations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsHousingInsulationsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this housing insulation.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-housing-insulations/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
