/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConstructionSubType } from '../models/ConstructionSubType';
import type { PaginatedConstructionSubTypeList } from '../models/PaginatedConstructionSubTypeList';
import type { PatchedConstructionSubType } from '../models/PatchedConstructionSubType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConstructionSubTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedConstructionSubTypeList
     * @throws ApiError
     */
    public clientsConstructionSubTypesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedConstructionSubTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-sub-types/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ConstructionSubType
     * @throws ApiError
     */
    public clientsConstructionSubTypesCreate({
        requestBody,
    }: {
        requestBody: ConstructionSubType,
    }): CancelablePromise<ConstructionSubType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-construction-sub-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConstructionSubType
     * @throws ApiError
     */
    public clientsConstructionSubTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this construction sub type.
         */
        id: number,
    }): CancelablePromise<ConstructionSubType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-construction-sub-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConstructionSubType
     * @throws ApiError
     */
    public clientsConstructionSubTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this construction sub type.
         */
        id: number,
        requestBody: ConstructionSubType,
    }): CancelablePromise<ConstructionSubType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-construction-sub-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConstructionSubType
     * @throws ApiError
     */
    public clientsConstructionSubTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this construction sub type.
         */
        id: number,
        requestBody?: PatchedConstructionSubType,
    }): CancelablePromise<ConstructionSubType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-construction-sub-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConstructionSubTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this construction sub type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-construction-sub-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
