/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReferencesContactStatusList } from '../models/PaginatedReferencesContactStatusList';
import type { PatchedReferencesContactStatus } from '../models/PatchedReferencesContactStatus';
import type { ReferencesContactStatus } from '../models/ReferencesContactStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsReferencesContactStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedReferencesContactStatusList
     * @throws ApiError
     */
    public clientsReferencesContactStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedReferencesContactStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-references-contact-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ReferencesContactStatus
     * @throws ApiError
     */
    public clientsReferencesContactStatusCreate({
        requestBody,
    }: {
        requestBody: ReferencesContactStatus,
    }): CancelablePromise<ReferencesContactStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-references-contact-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReferencesContactStatus
     * @throws ApiError
     */
    public clientsReferencesContactStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this references contact status.
         */
        id: number,
    }): CancelablePromise<ReferencesContactStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-references-contact-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ReferencesContactStatus
     * @throws ApiError
     */
    public clientsReferencesContactStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this references contact status.
         */
        id: number,
        requestBody: ReferencesContactStatus,
    }): CancelablePromise<ReferencesContactStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-references-contact-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReferencesContactStatus
     * @throws ApiError
     */
    public clientsReferencesContactStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this references contact status.
         */
        id: number,
        requestBody?: PatchedReferencesContactStatus,
    }): CancelablePromise<ReferencesContactStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-references-contact-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsReferencesContactStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this references contact status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-references-contact-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
