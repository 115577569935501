/** @format */

import {
  faGarageCar,
  faRotateExclamation,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Container, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { api } from '../../api.ts';
import { parseFieldError } from '../../utils/errors';
import BoxLoader from './BoxLoader';

const GATE_LOCATION = {
  lat: 43.620831,
  lng: 3.819784,
};

/**
 * Convert degrees to radians
 * @param deg {number}
 * @returns {number}
 */
const degToRad = (deg) => deg * (Math.PI / 180);

/**
 * Get distance between two points (in KM)
 * @param point1 {lat: number, lng: number}
 * @returns {number}
 */
const getDistance = (point1) => {
  const point2 = GATE_LOCATION;
  const R = 6371;
  const dLat = degToRad(point2.lat - point1.lat);
  const dLon = degToRad(point2.lng - point1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(point1.lat)) *
      Math.cos(degToRad(point2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  return R * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
};

function Gate() {
  const [loading, setLoading] = useState(true);
  const [isGeolocationAvailable, setIsGeolocationAvailable] = useState(false);
  const [isGeoAllowed, setIsGeoAllowed] = useState(false);
  const [isGeoCorrect, setIsGeoCorrect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getLocation = () => {
    if ('geolocation' in navigator) {
      setIsGeolocationAvailable(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setIsGeoAllowed(true);
          const distance = getDistance({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          if (typeof distance === 'number' && distance <= 1) {
            setIsGeoCorrect(true);
          } else {
            setIsGeoCorrect(false);
          }
          setLoading(false);
        },
        (err) => {
          console.error(err);
          setIsGeoAllowed(false);
          setIsGeoCorrect(false);
          setLoading(false);
        },
      );
    } else {
      setIsGeolocationAvailable(false);
      setIsGeoAllowed(false);
      setIsGeoCorrect(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleClick = () => {
    setLoading(true);
    api
      .post('/int/gate/')
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Ouverture demandée !', { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        const { message, statusCode } = parseFieldError(err);
        if (message) {
          enqueueSnackbar(message, {
            variant: statusCode === 429 ? 'warning' : 'error',
          });
        }
        setLoading(false);
      });
  };

  return (
    <Container>
      <BoxLoader loading={loading}>
        {isGeoCorrect ? (
          <Button
            variant="contained"
            color="error"
            size="large"
            fullWidth
            onClick={handleClick}
            sx={{
              fontSize: 30,
              padding: 10,
            }}
            disabled={loading || !isGeoCorrect}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <FontAwesomeIcon icon={faGarageCar} size="3x" />
              </Grid>
              <Grid item xs={12}>
                OUVRIR LE PORTAIL
              </Grid>
            </Grid>
          </Button>
        ) : (
          <>
            {isGeolocationAvailable && !isGeoAllowed && (
              <Alert severity="error">
                Merci d&apos;accorder l&apos;accès à la localisation de votre
                téléphone.
              </Alert>
            )}
            {isGeolocationAvailable && isGeoAllowed && !isGeoCorrect && (
              <Alert severity="error">
                L&apos;ouverture du portail à distance n&apos;est pas autorisée.
              </Alert>
            )}
          </>
        )}
      </BoxLoader>
    </Container>
  );
}

export default Gate;
