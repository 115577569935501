/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductList } from '../models/PaginatedProductList';
import type { PatchedProduct } from '../models/PatchedProduct';
import type { Product } from '../models/Product';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductList
     * @throws ApiError
     */
    public productsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedProductList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Product
     * @throws ApiError
     */
    public productsCreate({
        requestBody,
    }: {
        requestBody: Product,
    }): CancelablePromise<Product> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Product
     * @throws ApiError
     */
    public productsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<Product> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Product
     * @throws ApiError
     */
    public productsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: Product,
    }): CancelablePromise<Product> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Product
     * @throws ApiError
     */
    public productsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody?: PatchedProduct,
    }): CancelablePromise<Product> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
