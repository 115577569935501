/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { PaginatedEventList } from '../models/PaginatedEventList';
import type { PatchedEvent } from '../models/PatchedEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedEventList
     * @throws ApiError
     */
    public eventsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedEventList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event.
         */
        id: number,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event.
         */
        id: number,
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event.
         */
        id: number,
        requestBody?: PatchedEvent,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this event.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsCommentCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/comment/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsCommunicationCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/communication/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsMailSentCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/mail_sent/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsMissedCallCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/missed_call/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Event
     * @throws ApiError
     */
    public eventsVoicemailCreate({
        requestBody,
    }: {
        requestBody?: Event,
    }): CancelablePromise<Event> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/voicemail/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
