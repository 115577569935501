/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventActionLeadStatusChange } from '../models/EventActionLeadStatusChange';
import type { PaginatedEventActionLeadStatusChangeList } from '../models/PaginatedEventActionLeadStatusChangeList';
import type { PatchedEventActionLeadStatusChange } from '../models/PatchedEventActionLeadStatusChange';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsEventActionLeadStatusChangeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedEventActionLeadStatusChangeList
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangeList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedEventActionLeadStatusChangeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-lead-status-change/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns EventActionLeadStatusChange
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangeCreate({
        requestBody,
    }: {
        requestBody: EventActionLeadStatusChange,
    }): CancelablePromise<EventActionLeadStatusChange> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events-event-action-lead-status-change/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionLeadStatusChange
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangeRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event action lead status change.
         */
        id: number,
    }): CancelablePromise<EventActionLeadStatusChange> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events-event-action-lead-status-change/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventActionLeadStatusChange
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangeUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action lead status change.
         */
        id: number,
        requestBody: EventActionLeadStatusChange,
    }): CancelablePromise<EventActionLeadStatusChange> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/events-event-action-lead-status-change/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventActionLeadStatusChange
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event action lead status change.
         */
        id: number,
        requestBody?: PatchedEventActionLeadStatusChange,
    }): CancelablePromise<EventActionLeadStatusChange> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events-event-action-lead-status-change/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public eventsEventActionLeadStatusChangeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this event action lead status change.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events-event-action-lead-status-change/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
