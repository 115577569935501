/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEDFOA } from '../models/ClientEDFOA';
import type { PaginatedClientEDFOAList } from '../models/PaginatedClientEDFOAList';
import type { PatchedClientEDFOA } from '../models/PatchedClientEDFOA';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsEdfOasService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientEDFOAList
     * @throws ApiError
     */
    public clientsEdfOasList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientEDFOAList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-edf-oas/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientEDFOA
     * @throws ApiError
     */
    public clientsEdfOasCreate({
        requestBody,
    }: {
        requestBody: ClientEDFOA,
    }): CancelablePromise<ClientEDFOA> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-edf-oas/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientEDFOA
     * @throws ApiError
     */
    public clientsEdfOasRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client edfoa.
         */
        client: number,
    }): CancelablePromise<ClientEDFOA> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-edf-oas/{client}/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * @returns ClientEDFOA
     * @throws ApiError
     */
    public clientsEdfOasUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client edfoa.
         */
        client: number,
        requestBody: ClientEDFOA,
    }): CancelablePromise<ClientEDFOA> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-edf-oas/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientEDFOA
     * @throws ApiError
     */
    public clientsEdfOasPartialUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client edfoa.
         */
        client: number,
        requestBody?: PatchedClientEDFOA,
    }): CancelablePromise<ClientEDFOA> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-edf-oas/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsEdfOasDestroy({
        client,
    }: {
        /**
         * A unique value identifying this client edfoa.
         */
        client: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-edf-oas/{client}/',
            path: {
                'client': client,
            },
        });
    }

}
