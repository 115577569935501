/** @format */

import {
  faFile,
  faFileImage,
  faFilePdf,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import fileSize from 'filesize';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  selectedArea: {
    textAlign: 'center',
  },
}));

const uploadTypes = {
  file: 'un/des fichier(s)',
  image: 'une/des image(s)',
  pdf: 'un/des fichier(s) PDF',
  dataFile: 'des fichiers CSV ou JSON',
};

const acceptedFileTypes = {
  image: ['image/png', 'image/jpeg', 'image/gif', 'image/tiff'],
  pdf: ['application/pdf'],
  dataFile: ['text/csv', 'application/json'],
  get file() {
    return [].concat(this.image, this.pdf);
  },
};

const getIconByType = (type) => {
  if (acceptedFileTypes.image.includes(type)) {
    return faFileImage;
  }
  if (acceptedFileTypes.pdf.includes(type)) {
    return faFilePdf;
  }
  return faFile;
};

function MultiDropzone({ type, selectedFiles, disabled, onFileChange }) {
  const classes = useStyles();

  const onChange = (files) => {
    const newFiles = [].concat(selectedFiles, files);
    onFileChange(newFiles);
  };

  const onInputChange = (e) => {
    // nothing
  };

  const removeFile = (path) => {
    const files = selectedFiles.filter((file) => file.path !== path);
    onFileChange(files);
  };

  return (
    <Grid container spacing={1}>
      {selectedFiles && selectedFiles.length > 0 && (
        <Grid item xs={12}>
          <List dense>
            {selectedFiles.map((file) => (
              <ListItem
                key={file.path}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeFile(file.path)}
                    sx={{ fontSize: '100%' }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <FontAwesomeIcon icon={getIconByType(file.type)} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  secondary={fileSize(file.size, { locale: 'fr' })}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      <Grid item xs={12}>
        <Dropzone
          onDrop={onChange}
          disabled={disabled}
          accept={acceptedFileTypes[type]}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onChange: onInputChange,
              })}
            >
              <div className={classes.dropzone}>
                <input {...getInputProps()} />
                <div className={classes.selectedArea}>
                  Faites glisser et déposez {uploadTypes[type]} ici, ou cliquez
                  pour sélectionner {uploadTypes[type]}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </Grid>
    </Grid>
  );
}

MultiDropzone.propTypes = {
  type: PropTypes.oneOf(['file', 'image', 'pdf']).isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
};

MultiDropzone.defaultProps = {
  selectedFiles: [],
  disabled: false,
};

export default MultiDropzone;
