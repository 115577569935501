/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductWorkforceNoPVList } from '../models/PaginatedProductWorkforceNoPVList';
import type { PatchedProductWorkforceNoPV } from '../models/PatchedProductWorkforceNoPV';
import type { ProductWorkforceNoPV } from '../models/ProductWorkforceNoPV';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsWorkforcesNoPvService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductWorkforceNoPVList
     * @throws ApiError
     */
    public productsWorkforcesNoPvList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductWorkforceNoPVList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-workforces-no-pv/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductWorkforceNoPV
     * @throws ApiError
     */
    public productsWorkforcesNoPvCreate({
        requestBody,
    }: {
        requestBody: ProductWorkforceNoPV,
    }): CancelablePromise<ProductWorkforceNoPV> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-workforces-no-pv/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductWorkforceNoPV
     * @throws ApiError
     */
    public productsWorkforcesNoPvRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product workforce no pv.
         */
        id: number,
    }): CancelablePromise<ProductWorkforceNoPV> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-workforces-no-pv/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductWorkforceNoPV
     * @throws ApiError
     */
    public productsWorkforcesNoPvUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product workforce no pv.
         */
        id: number,
        requestBody: ProductWorkforceNoPV,
    }): CancelablePromise<ProductWorkforceNoPV> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-workforces-no-pv/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductWorkforceNoPV
     * @throws ApiError
     */
    public productsWorkforcesNoPvPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product workforce no pv.
         */
        id: number,
        requestBody?: PatchedProductWorkforceNoPV,
    }): CancelablePromise<ProductWorkforceNoPV> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-workforces-no-pv/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsWorkforcesNoPvDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product workforce no pv.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-workforces-no-pv/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
