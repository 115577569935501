/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadConsumption } from '../models/LeadConsumption';
import type { PaginatedLeadConsumptionList } from '../models/PaginatedLeadConsumptionList';
import type { PatchedLeadConsumption } from '../models/PatchedLeadConsumption';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsConsumptionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadConsumptionList
     * @throws ApiError
     */
    public leadsConsumptionsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedLeadConsumptionList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-consumptions/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns LeadConsumption
     * @throws ApiError
     */
    public leadsConsumptionsCreate({
        requestBody,
    }: {
        requestBody: LeadConsumption,
    }): CancelablePromise<LeadConsumption> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-consumptions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadConsumption
     * @throws ApiError
     */
    public leadsConsumptionsRetrieve({
        site,
    }: {
        /**
         * A unique value identifying this consumption.
         */
        site: number,
    }): CancelablePromise<LeadConsumption> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-consumptions/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns LeadConsumption
     * @throws ApiError
     */
    public leadsConsumptionsUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this consumption.
         */
        site: number,
        requestBody: LeadConsumption,
    }): CancelablePromise<LeadConsumption> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-consumptions/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadConsumption
     * @throws ApiError
     */
    public leadsConsumptionsPartialUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this consumption.
         */
        site: number,
        requestBody?: PatchedLeadConsumption,
    }): CancelablePromise<LeadConsumption> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-consumptions/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsConsumptionsDestroy({
        site,
    }: {
        /**
         * A unique value identifying this consumption.
         */
        site: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-consumptions/{site}/',
            path: {
                'site': site,
            },
        });
    }

}
