/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientConsuel } from '../models/ClientConsuel';
import type { PaginatedClientConsuelList } from '../models/PaginatedClientConsuelList';
import type { PatchedClientConsuel } from '../models/PatchedClientConsuel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConsuelsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientConsuelList
     * @throws ApiError
     */
    public clientsConsuelsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientConsuelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-consuels/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientConsuel
     * @throws ApiError
     */
    public clientsConsuelsCreate({
        requestBody,
    }: {
        requestBody: ClientConsuel,
    }): CancelablePromise<ClientConsuel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-consuels/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConsuel
     * @throws ApiError
     */
    public clientsConsuelsRetrieve({
        client,
    }: {
        /**
         * A unique value identifying this client consuel.
         */
        client: number,
    }): CancelablePromise<ClientConsuel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-consuels/{client}/',
            path: {
                'client': client,
            },
        });
    }

    /**
     * @returns ClientConsuel
     * @throws ApiError
     */
    public clientsConsuelsUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client consuel.
         */
        client: number,
        requestBody: ClientConsuel,
    }): CancelablePromise<ClientConsuel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-consuels/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientConsuel
     * @throws ApiError
     */
    public clientsConsuelsPartialUpdate({
        client,
        requestBody,
    }: {
        /**
         * A unique value identifying this client consuel.
         */
        client: number,
        requestBody?: PatchedClientConsuel,
    }): CancelablePromise<ClientConsuel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-consuels/{client}/',
            path: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConsuelsDestroy({
        client,
    }: {
        /**
         * A unique value identifying this client consuel.
         */
        client: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-consuels/{client}/',
            path: {
                'client': client,
            },
        });
    }

}
