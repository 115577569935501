/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MandateStatus } from '../models/MandateStatus';
import type { PaginatedMandateStatusList } from '../models/PaginatedMandateStatusList';
import type { PatchedMandateStatus } from '../models/PatchedMandateStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsMandateStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedMandateStatusList
     * @throws ApiError
     */
    public clientsMandateStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedMandateStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-mandate-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns MandateStatus
     * @throws ApiError
     */
    public clientsMandateStatusCreate({
        requestBody,
    }: {
        requestBody: MandateStatus,
    }): CancelablePromise<MandateStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-mandate-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MandateStatus
     * @throws ApiError
     */
    public clientsMandateStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this mandate status.
         */
        id: number,
    }): CancelablePromise<MandateStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-mandate-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns MandateStatus
     * @throws ApiError
     */
    public clientsMandateStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this mandate status.
         */
        id: number,
        requestBody: MandateStatus,
    }): CancelablePromise<MandateStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-mandate-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MandateStatus
     * @throws ApiError
     */
    public clientsMandateStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this mandate status.
         */
        id: number,
        requestBody?: PatchedMandateStatus,
    }): CancelablePromise<MandateStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-mandate-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsMandateStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this mandate status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-mandate-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
