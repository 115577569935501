/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigchangeInfoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all bigchange info.
     * :param request: Django request
     * :return: HTTP Response
     * @returns any No response body
     * @throws ApiError
     */
    public bigchangeInfoRetrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-info/',
        });
    }

    /**
     * Get all bigchange info.
     * :param request: Django request
     * :return: HTTP Response
     * @returns any No response body
     * @throws ApiError
     */
    public bigchangeInfoRetrieve2({
        info,
    }: {
        info: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-info/{info}/',
            path: {
                'info': info,
            },
        });
    }

}
