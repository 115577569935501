/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadType } from '../models/LeadType';
import type { PaginatedLeadTypeList } from '../models/PaginatedLeadTypeList';
import type { PatchedLeadType } from '../models/PatchedLeadType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadTypeList
     * @throws ApiError
     */
    public leadsTypesList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLeadTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-types/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns LeadType
     * @throws ApiError
     */
    public leadsTypesCreate({
        requestBody,
    }: {
        requestBody: LeadType,
    }): CancelablePromise<LeadType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadType
     * @throws ApiError
     */
    public leadsTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead type.
         */
        id: number,
    }): CancelablePromise<LeadType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadType
     * @throws ApiError
     */
    public leadsTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead type.
         */
        id: number,
        requestBody: LeadType,
    }): CancelablePromise<LeadType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadType
     * @throws ApiError
     */
    public leadsTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead type.
         */
        id: number,
        requestBody?: PatchedLeadType,
    }): CancelablePromise<LeadType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lead type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
