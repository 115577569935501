/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import CacheManager from '../../../utils/cache';
import { getProvenanceIcon } from '../../../utils/constants';

function ProvenanceIcon({ provenanceId }) {
  const provenance = useMemo(
    () =>
      CacheManager.instance.getModel('/leads-provenances/').get(provenanceId),
    [provenanceId],
  );
  const icon = useMemo(() => getProvenanceIcon(provenance), [provenance]);
  return (
    <FontAwesomeIcon
      icon={icon.icon}
      style={{ color: icon.color, fontSize: 20 }}
    />
  );
}

ProvenanceIcon.propTypes = {
  provenanceId: PropTypes.number.isRequired,
};

export default ProvenanceIcon;
