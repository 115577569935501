/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Lead } from '../models/Lead';
import type { PaginatedLeadList } from '../models/PaginatedLeadList';
import type { PatchedLead } from '../models/PatchedLead';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadList
     * @throws ApiError
     */
    public leadsList({
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLeadList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsCreate({
        requestBody,
    }: {
        requestBody: Lead,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead.
         */
        id: number,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead.
         */
        id: number,
        requestBody: Lead,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead.
         */
        id: number,
        requestBody?: PatchedLead,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lead.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsStatusCreate2({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead.
         */
        id: number,
        requestBody: Lead,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads/{id}/status/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsCountRetrieve(): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads/count/',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsCountsRetrieve(): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads/counts/',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsCreateFromWordpressCreate({
        requestBody,
    }: {
        requestBody: Lead,
    }): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads/create_from_wordpress/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Lead
     * @throws ApiError
     */
    public leadsGetCountLeadsByPmRetrieve(): CancelablePromise<Lead> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads/get_count_leads_by_pm/',
        });
    }

}
