/** @format */

import { useEffect, useRef } from 'react';

/**
 * Hook used to replace "prevProps" and "prevState" behaviour in class components
 * @param value {any} State or prop value
 * @returns {any} State or prop previous value
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export { usePrevious };

export default null;
