/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckSentStatus } from '../models/CheckSentStatus';
import type { PaginatedCheckSentStatusList } from '../models/PaginatedCheckSentStatusList';
import type { PatchedCheckSentStatus } from '../models/PatchedCheckSentStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsCheckSentStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedCheckSentStatusList
     * @throws ApiError
     */
    public clientsCheckSentStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedCheckSentStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-check-sent-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns CheckSentStatus
     * @throws ApiError
     */
    public clientsCheckSentStatusCreate({
        requestBody,
    }: {
        requestBody: CheckSentStatus,
    }): CancelablePromise<CheckSentStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-check-sent-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CheckSentStatus
     * @throws ApiError
     */
    public clientsCheckSentStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Friends program status.
         */
        id: number,
    }): CancelablePromise<CheckSentStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-check-sent-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CheckSentStatus
     * @throws ApiError
     */
    public clientsCheckSentStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Friends program status.
         */
        id: number,
        requestBody: CheckSentStatus,
    }): CancelablePromise<CheckSentStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-check-sent-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CheckSentStatus
     * @throws ApiError
     */
    public clientsCheckSentStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Friends program status.
         */
        id: number,
        requestBody?: PatchedCheckSentStatus,
    }): CancelablePromise<CheckSentStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-check-sent-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsCheckSentStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Friends program status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-check-sent-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
