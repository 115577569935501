/** @format */

import { ClickAwayListener, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { getApiUrl } from '../../../api.ts';
import NewTooltip from '../../misc/NewTooltip';
import AutoSaveField from '../field/AutoSaveField';
import CacheManager from '../../../utils/cache';

function stringToColor(str) {
  let hash = 0;
  let i;

  if (!str) return '#000000';

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name ? name.split(' ')[0][0] : '—'}${
      name ? name.split(' ')[1][0] : ''
    }`,
  };
}

function ProjectManagerIcon({ lead, onChange, hover }) {
  const [open, setOpen] = useState(false);

  const projectManager = useMemo(
    () =>
      lead
        ? CacheManager.instance.getModel('users').get(lead.project_manager)
        : null,
    [lead],
  );

  return (
    <NewTooltip
      content={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
          >
            <Grid item xs={12}>
              <AutoSaveField
                parent={lead}
                parentType="lead"
                field="project_manager"
                label="Chargé de projet"
                onChange={(result) => {
                  setOpen(false);
                  onChange(result);
                }}
                autoComplete
                autoCompleteProps={{
                  endpoint: (input) =>
                    `/users/?search=${
                      input === 0 || input === undefined ? '' : input
                    }&is_project_manager=True`,
                  getOptionLabel: (o) => (o ? o.full_name : ''),
                  cache: true,
                }}
              />
            </Grid>
          </Grid>
        </ClickAwayListener>
      }
      placement="top"
      light
      width={250}
      csx={{
        paddingTop: 10,
      }}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <IconButton
        variant="contained"
        color="primary"
        onClick={() => setOpen(!open)}
      >
        {projectManager && projectManager !== lead.project_manager ? (
          <>
            {projectManager.has_avatar ? (
              <Avatar src={`${getApiUrl()}/avatar/${lead.project_manager}`} />
            ) : (
              <Avatar {...stringAvatar(projectManager.full_name)} />
            )}
          </>
        ) : (
          <Avatar
            sx={{
              color: 'text.primary',
              bgcolor: 'grey.300',
            }}
          >
            <FontAwesomeIcon icon={faUserTie} />
          </Avatar>
        )}
      </IconButton>
    </NewTooltip>
  );
}

ProjectManagerIcon.propTypes = {
  lead: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  hover: PropTypes.bool.isRequired,
};

export default ProjectManagerIcon;
