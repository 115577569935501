/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigchangeWebhookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Handle bigchange webhook
     * :param request: Django request
     * :return: HTTP Reponse
     * @returns any No response body
     * @throws ApiError
     */
    public bigchangeWebhookCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/bigchange-webhook/',
        });
    }

}
