/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppealType } from '../models/AppealType';
import type { PaginatedAppealTypeList } from '../models/PaginatedAppealTypeList';
import type { PatchedAppealType } from '../models/PatchedAppealType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsAppealTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedAppealTypeList
     * @throws ApiError
     */
    public clientsAppealTypesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedAppealTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-appeal-types/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns AppealType
     * @throws ApiError
     */
    public clientsAppealTypesCreate({
        requestBody,
    }: {
        requestBody: AppealType,
    }): CancelablePromise<AppealType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-appeal-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppealType
     * @throws ApiError
     */
    public clientsAppealTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this appeal type.
         */
        id: number,
    }): CancelablePromise<AppealType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-appeal-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AppealType
     * @throws ApiError
     */
    public clientsAppealTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this appeal type.
         */
        id: number,
        requestBody: AppealType,
    }): CancelablePromise<AppealType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-appeal-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppealType
     * @throws ApiError
     */
    public clientsAppealTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this appeal type.
         */
        id: number,
        requestBody?: PatchedAppealType,
    }): CancelablePromise<AppealType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-appeal-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsAppealTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this appeal type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-appeal-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
