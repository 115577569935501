/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HeatingType } from '../models/HeatingType';
import type { PaginatedHeatingTypeList } from '../models/PaginatedHeatingTypeList';
import type { PatchedHeatingType } from '../models/PatchedHeatingType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsHeatingTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedHeatingTypeList
     * @throws ApiError
     */
    public leadsHeatingTypesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedHeatingTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-heating-types/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns HeatingType
     * @throws ApiError
     */
    public leadsHeatingTypesCreate({
        requestBody,
    }: {
        requestBody: HeatingType,
    }): CancelablePromise<HeatingType> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-heating-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HeatingType
     * @throws ApiError
     */
    public leadsHeatingTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this heating type.
         */
        id: number,
    }): CancelablePromise<HeatingType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-heating-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HeatingType
     * @throws ApiError
     */
    public leadsHeatingTypesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this heating type.
         */
        id: number,
        requestBody: HeatingType,
    }): CancelablePromise<HeatingType> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-heating-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HeatingType
     * @throws ApiError
     */
    public leadsHeatingTypesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this heating type.
         */
        id: number,
        requestBody?: PatchedHeatingType,
    }): CancelablePromise<HeatingType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-heating-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsHeatingTypesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this heating type.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-heating-types/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
