/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferProductInverter } from '../models/OfferProductInverter';
import type { PaginatedOfferProductInverterList } from '../models/PaginatedOfferProductInverterList';
import type { PatchedOfferProductInverter } from '../models/PatchedOfferProductInverter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersProductInvertersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferProductInverterList
     * @throws ApiError
     */
    public offersProductInvertersList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferProductInverterList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-inverters/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OfferProductInverter
     * @throws ApiError
     */
    public offersProductInvertersCreate({
        requestBody,
    }: {
        requestBody: OfferProductInverter,
    }): CancelablePromise<OfferProductInverter> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers-product-inverters/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductInverter
     * @throws ApiError
     */
    public offersProductInvertersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product inverter.
         */
        id: number,
    }): CancelablePromise<OfferProductInverter> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-inverters/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OfferProductInverter
     * @throws ApiError
     */
    public offersProductInvertersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product inverter.
         */
        id: number,
        requestBody: OfferProductInverter,
    }): CancelablePromise<OfferProductInverter> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers-product-inverters/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductInverter
     * @throws ApiError
     */
    public offersProductInvertersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product inverter.
         */
        id: number,
        requestBody?: PatchedOfferProductInverter,
    }): CancelablePromise<OfferProductInverter> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers-product-inverters/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersProductInvertersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product inverter.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers-product-inverters/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
