/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPlanningStatusList } from '../models/PaginatedPlanningStatusList';
import type { PatchedPlanningStatus } from '../models/PatchedPlanningStatus';
import type { PlanningStatus } from '../models/PlanningStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsPlanningStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedPlanningStatusList
     * @throws ApiError
     */
    public clientsPlanningStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedPlanningStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-planning-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns PlanningStatus
     * @throws ApiError
     */
    public clientsPlanningStatusCreate({
        requestBody,
    }: {
        requestBody: PlanningStatus,
    }): CancelablePromise<PlanningStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-planning-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PlanningStatus
     * @throws ApiError
     */
    public clientsPlanningStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this planning status.
         */
        id: number,
    }): CancelablePromise<PlanningStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-planning-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PlanningStatus
     * @throws ApiError
     */
    public clientsPlanningStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this planning status.
         */
        id: number,
        requestBody: PlanningStatus,
    }): CancelablePromise<PlanningStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-planning-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PlanningStatus
     * @throws ApiError
     */
    public clientsPlanningStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this planning status.
         */
        id: number,
        requestBody?: PatchedPlanningStatus,
    }): CancelablePromise<PlanningStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-planning-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsPlanningStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this planning status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-planning-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
