/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OtherProductService } from '../models/OtherProductService';
import type { PaginatedOtherProductServiceList } from '../models/PaginatedOtherProductServiceList';
import type { PatchedOtherProductService } from '../models/PatchedOtherProductService';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsOtherServiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOtherProductServiceList
     * @throws ApiError
     */
    public productsOtherServiceList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOtherProductServiceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-other-service/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OtherProductService
     * @throws ApiError
     */
    public productsOtherServiceCreate({
        requestBody,
    }: {
        requestBody: OtherProductService,
    }): CancelablePromise<OtherProductService> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-other-service/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OtherProductService
     * @throws ApiError
     */
    public productsOtherServiceRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this other product service.
         */
        id: number,
    }): CancelablePromise<OtherProductService> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-other-service/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OtherProductService
     * @throws ApiError
     */
    public productsOtherServiceUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this other product service.
         */
        id: number,
        requestBody: OtherProductService,
    }): CancelablePromise<OtherProductService> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-other-service/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OtherProductService
     * @throws ApiError
     */
    public productsOtherServicePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this other product service.
         */
        id: number,
        requestBody?: PatchedOtherProductService,
    }): CancelablePromise<OtherProductService> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-other-service/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsOtherServiceDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this other product service.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-other-service/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
