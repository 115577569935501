/** @format */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFilesByType: {},
  currentFilesValidateMethods: {},
  preLoadFilesLoading: {},
};

const slice = createSlice({
  name: 'clientFiles',
  initialState,
  reducers: {
    validateAllFiles: (state, action) => {},
    setCurrentFilesByType: (state, action) => {
      const { fileType, files } = action.payload;
      state.currentFilesByType[fileType] = files;
    },
    setCurrentFileValidateMethod: (state, action) => {
      const { fileType, method } = action.payload;
      state.currentFilesValidateMethods[fileType] = method;
    },
    resetCurrentFileValidateMethod: (state, action) => {
      const { fileType } = action.payload;
      delete state.currentFilesValidateMethods[fileType];
    },
    resetClientFiles: (state, action) => {
      state.currentFilesByType = {};
    },
    setPreLoadFilesLoading: (state, action) => {
      const { fileType, loading } = action.payload;
      state.preLoadFilesLoading[fileType] = loading;
    },
    resetPreLoadFilesLoading: (state, action) => {
      state.preLoadFilesLoading = {};
    },
  },
});

export const {
  validateAllFiles,
  setCurrentFilesByType,
  setCurrentFileValidateMethod,
  resetCurrentFileValidateMethod,
  resetClientFiles,
  setPreLoadFilesLoading,
  resetPreLoadFilesLoading,
} = slice.actions;

export const selectClientFiles = (state) => state.clientFiles;

export default slice.reducer;
