/** @format */

import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { cloneElement, useMemo } from 'react';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import Lottie from 'lottie-react';
import sun from '../../assets/animations/sun.json';

const StyledLoadingOverlay = styled(LoadingOverlay, {
  shouldForwardProp: (propName) =>
    ![
      'noBackground',
      'whiteBackground',
      'whiteLoader',
      'small',
      'offer',
      'clientFiles',
      'prefix',
    ].includes(propName),
})(
  ({
    theme,
    prefix,
    noBackground,
    whiteBackground,
    whiteLoader,
    small,
    offer,
    clientFiles,
  }) => ({
    [`& .${prefix}overlay`]: {
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: noBackground
        ? 'transparent'
        : whiteBackground
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(245, 245, 245, 0.8)',
      zIndex: clientFiles ? 10000 : undefined,
    },
    [`& .${prefix}content`]: {
      // eslint-disable-next-line no-nested-ternary
      transform: offer
        ? 'translateY(-50%)'
        : clientFiles
        ? 'translate(-50%, -50%)'
        : undefined,
      color: whiteBackground ? theme.palette.primary.main : undefined,
      position: clientFiles ? 'fixed' : undefined,
      top: clientFiles ? '50%' : undefined,
      left: clientFiles ? '50%' : undefined,
    },
    [`& .${prefix}spinner`]: {
      width: small ? 24 : 50,
      marginBottom: small ? 0 : undefined,
      '& svg > circle': {
        stroke: theme.palette.primary.main,
        strokeWidth: 5,
      },
    },
    [`& .${prefix}spinner svg > circle`]: {
      stroke: whiteLoader ? '#ffffff' : undefined,
    },
  }),
);

function BoxLoader({
  children,
  loading,
  text,
  noBackground,
  whiteBackground,
  whiteLoader,
  small,
  offer,
  clientFiles,
}) {
  const prefix = useMemo(
    () => `loader-${Math.random().toString(36).slice(2)}-`,
    [],
  );
  return (
    <StyledLoadingOverlay
      active={loading}
      spinner={
        <Lottie
          animationData={sun}
          loop
          autoplay
          style={{
            height: small ? 24 : 50,
            width: small ? 24 : 50,
          }}
        />
      }
      text={text}
      noBackground={noBackground}
      whiteBackground={whiteBackground}
      whiteLoader={whiteLoader}
      small={small}
      offer={offer}
      clientFiles={clientFiles}
      prefix={prefix}
      classNamePrefix={prefix}
    >
      {children}
    </StyledLoadingOverlay>
  );
}

BoxLoader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.node,
  noBackground: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  whiteLoader: PropTypes.bool,
  small: PropTypes.bool,
  offer: PropTypes.bool,
  clientFiles: PropTypes.bool,
};

BoxLoader.defaultProps = {
  text: undefined,
  noBackground: false,
  whiteBackground: false,
  whiteLoader: false,
  small: false,
  offer: false,
  clientFiles: false,
};

export default BoxLoader;
