/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedUserConfigList } from '../models/PaginatedUserConfigList';
import type { UserConfig } from '../models/UserConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserConfigsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedUserConfigList
     * @throws ApiError
     */
    public userConfigsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedUserConfigList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user-configs/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns UserConfig
     * @throws ApiError
     */
    public userConfigsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this User config.
         */
        id: number,
    }): CancelablePromise<UserConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user-configs/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
