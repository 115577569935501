/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigchangeJobTagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all bigchange job tags.
     * :param request: Django request
     * :return: HTTP Response with the list of job tags
     * @returns any No response body
     * @throws ApiError
     */
    public bigchangeJobTagsRetrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-job-tags/',
        });
    }

}
