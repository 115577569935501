/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsuelStatus } from '../models/ConsuelStatus';
import type { PaginatedConsuelStatusList } from '../models/PaginatedConsuelStatusList';
import type { PatchedConsuelStatus } from '../models/PatchedConsuelStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsConsuelStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedConsuelStatusList
     * @throws ApiError
     */
    public clientsConsuelStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedConsuelStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-consuel-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ConsuelStatus
     * @throws ApiError
     */
    public clientsConsuelStatusCreate({
        requestBody,
    }: {
        requestBody: ConsuelStatus,
    }): CancelablePromise<ConsuelStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-consuel-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConsuelStatus
     * @throws ApiError
     */
    public clientsConsuelStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this consuel status.
         */
        id: number,
    }): CancelablePromise<ConsuelStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-consuel-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConsuelStatus
     * @throws ApiError
     */
    public clientsConsuelStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this consuel status.
         */
        id: number,
        requestBody: ConsuelStatus,
    }): CancelablePromise<ConsuelStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-consuel-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConsuelStatus
     * @throws ApiError
     */
    public clientsConsuelStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this consuel status.
         */
        id: number,
        requestBody?: PatchedConsuelStatus,
    }): CancelablePromise<ConsuelStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-consuel-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsConsuelStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this consuel status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-consuel-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
