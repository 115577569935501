/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadHousing } from '../models/LeadHousing';
import type { PaginatedLeadHousingList } from '../models/PaginatedLeadHousingList';
import type { PatchedLeadHousing } from '../models/PatchedLeadHousing';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsHousingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadHousingList
     * @throws ApiError
     */
    public leadsHousingsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedLeadHousingList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-housings/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns LeadHousing
     * @throws ApiError
     */
    public leadsHousingsCreate({
        requestBody,
    }: {
        requestBody: LeadHousing,
    }): CancelablePromise<LeadHousing> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-housings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadHousing
     * @throws ApiError
     */
    public leadsHousingsRetrieve({
        site,
    }: {
        /**
         * A unique value identifying this housing.
         */
        site: number,
    }): CancelablePromise<LeadHousing> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-housings/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns LeadHousing
     * @throws ApiError
     */
    public leadsHousingsUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this housing.
         */
        site: number,
        requestBody: LeadHousing,
    }): CancelablePromise<LeadHousing> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-housings/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadHousing
     * @throws ApiError
     */
    public leadsHousingsPartialUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this housing.
         */
        site: number,
        requestBody?: PatchedLeadHousing,
    }): CancelablePromise<LeadHousing> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-housings/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsHousingsDestroy({
        site,
    }: {
        /**
         * A unique value identifying this housing.
         */
        site: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-housings/{site}/',
            path: {
                'site': site,
            },
        });
    }

}
