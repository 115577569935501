/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElectricalPanelImage } from '../models/ElectricalPanelImage';
import type { PaginatedElectricalPanelImageList } from '../models/PaginatedElectricalPanelImageList';
import type { PatchedElectricalPanelImage } from '../models/PatchedElectricalPanelImage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsSitesElectricalPanelImagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedElectricalPanelImageList
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedElectricalPanelImageList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-electrical-panel-images/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ElectricalPanelImage
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesCreate({
        requestBody,
    }: {
        requestBody: ElectricalPanelImage,
    }): CancelablePromise<ElectricalPanelImage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites-electrical-panel-images/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElectricalPanelImage
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this electrical panel image.
         */
        id: number,
    }): CancelablePromise<ElectricalPanelImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites-electrical-panel-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ElectricalPanelImage
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this electrical panel image.
         */
        id: number,
        requestBody: ElectricalPanelImage,
    }): CancelablePromise<ElectricalPanelImage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-sites-electrical-panel-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElectricalPanelImage
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this electrical panel image.
         */
        id: number,
        requestBody?: PatchedElectricalPanelImage,
    }): CancelablePromise<ElectricalPanelImage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-sites-electrical-panel-images/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSitesElectricalPanelImagesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this electrical panel image.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-sites-electrical-panel-images/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
