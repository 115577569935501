/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductAirConditioningSupplyList } from '../models/PaginatedProductAirConditioningSupplyList';
import type { PatchedProductAirConditioningSupply } from '../models/PatchedProductAirConditioningSupply';
import type { ProductAirConditioningSupply } from '../models/ProductAirConditioningSupply';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsAirConditioningSupplyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductAirConditioningSupplyList
     * @throws ApiError
     */
    public productsAirConditioningSupplyList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductAirConditioningSupplyList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-air-conditioning_supply/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductAirConditioningSupply
     * @throws ApiError
     */
    public productsAirConditioningSupplyCreate({
        requestBody,
    }: {
        requestBody: ProductAirConditioningSupply,
    }): CancelablePromise<ProductAirConditioningSupply> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-air-conditioning_supply/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductAirConditioningSupply
     * @throws ApiError
     */
    public productsAirConditioningSupplyRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product air conditioning supply.
         */
        id: number,
    }): CancelablePromise<ProductAirConditioningSupply> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-air-conditioning_supply/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductAirConditioningSupply
     * @throws ApiError
     */
    public productsAirConditioningSupplyUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product air conditioning supply.
         */
        id: number,
        requestBody: ProductAirConditioningSupply,
    }): CancelablePromise<ProductAirConditioningSupply> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-air-conditioning_supply/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductAirConditioningSupply
     * @throws ApiError
     */
    public productsAirConditioningSupplyPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product air conditioning supply.
         */
        id: number,
        requestBody?: PatchedProductAirConditioningSupply,
    }): CancelablePromise<ProductAirConditioningSupply> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-air-conditioning_supply/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsAirConditioningSupplyDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product air conditioning supply.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-air-conditioning_supply/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
