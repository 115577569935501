/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedVATRateList } from '../models/PaginatedVATRateList';
import type { PatchedVATRate } from '../models/PatchedVATRate';
import type { VATRate } from '../models/VATRate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsVatRatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedVATRateList
     * @throws ApiError
     */
    public leadsVatRatesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedVATRateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-vat-rates/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns VATRate
     * @throws ApiError
     */
    public leadsVatRatesCreate({
        requestBody,
    }: {
        requestBody: VATRate,
    }): CancelablePromise<VATRate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-vat-rates/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VATRate
     * @throws ApiError
     */
    public leadsVatRatesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this VAT rate.
         */
        id: number,
    }): CancelablePromise<VATRate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-vat-rates/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VATRate
     * @throws ApiError
     */
    public leadsVatRatesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this VAT rate.
         */
        id: number,
        requestBody: VATRate,
    }): CancelablePromise<VATRate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-vat-rates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VATRate
     * @throws ApiError
     */
    public leadsVatRatesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this VAT rate.
         */
        id: number,
        requestBody?: PatchedVATRate,
    }): CancelablePromise<VATRate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-vat-rates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsVatRatesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this VAT rate.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-vat-rates/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
