/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigChangeConfig } from '../models/BigChangeConfig';
import type { PaginatedBigChangeConfigList } from '../models/PaginatedBigChangeConfigList';
import type { PatchedBigChangeConfig } from '../models/PatchedBigChangeConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigchangeConfigsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedBigChangeConfigList
     * @throws ApiError
     */
    public bigchangeConfigsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedBigChangeConfigList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-configs/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns BigChangeConfig
     * @throws ApiError
     */
    public bigchangeConfigsCreate({
        requestBody,
    }: {
        requestBody?: BigChangeConfig,
    }): CancelablePromise<BigChangeConfig> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/bigchange-configs/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BigChangeConfig
     * @throws ApiError
     */
    public bigchangeConfigsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this big change config.
         */
        id: number,
    }): CancelablePromise<BigChangeConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-configs/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns BigChangeConfig
     * @throws ApiError
     */
    public bigchangeConfigsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this big change config.
         */
        id: number,
        requestBody?: BigChangeConfig,
    }): CancelablePromise<BigChangeConfig> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/bigchange-configs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BigChangeConfig
     * @throws ApiError
     */
    public bigchangeConfigsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this big change config.
         */
        id: number,
        requestBody?: PatchedBigChangeConfig,
    }): CancelablePromise<BigChangeConfig> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/bigchange-configs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public bigchangeConfigsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this big change config.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/bigchange-configs/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
