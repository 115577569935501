/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigchangeResourcesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all bigchange resources.
     * :param request: Django request
     * :return: HTTP Response with the list of resources
     * @returns any No response body
     * @throws ApiError
     */
    public bigchangeResourcesRetrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bigchange-resources/',
        });
    }

}
