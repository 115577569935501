/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OtherProductMaterial } from '../models/OtherProductMaterial';
import type { PaginatedOtherProductMaterialList } from '../models/PaginatedOtherProductMaterialList';
import type { PatchedOtherProductMaterial } from '../models/PatchedOtherProductMaterial';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsOtherMaterialService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOtherProductMaterialList
     * @throws ApiError
     */
    public productsOtherMaterialList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOtherProductMaterialList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-other-material/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OtherProductMaterial
     * @throws ApiError
     */
    public productsOtherMaterialCreate({
        requestBody,
    }: {
        requestBody: OtherProductMaterial,
    }): CancelablePromise<OtherProductMaterial> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-other-material/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OtherProductMaterial
     * @throws ApiError
     */
    public productsOtherMaterialRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this other product material.
         */
        id: number,
    }): CancelablePromise<OtherProductMaterial> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-other-material/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OtherProductMaterial
     * @throws ApiError
     */
    public productsOtherMaterialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this other product material.
         */
        id: number,
        requestBody: OtherProductMaterial,
    }): CancelablePromise<OtherProductMaterial> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-other-material/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OtherProductMaterial
     * @throws ApiError
     */
    public productsOtherMaterialPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this other product material.
         */
        id: number,
        requestBody?: PatchedOtherProductMaterial,
    }): CancelablePromise<OtherProductMaterial> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-other-material/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsOtherMaterialDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this other product material.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-other-material/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
