/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRemoteSiteList } from '../models/PaginatedRemoteSiteList';
import type { PatchedRemoteSite } from '../models/PatchedRemoteSite';
import type { RemoteSite } from '../models/RemoteSite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsRemoteSitesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedRemoteSiteList
     * @throws ApiError
     */
    public leadsRemoteSitesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedRemoteSiteList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-remote-sites/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns RemoteSite
     * @throws ApiError
     */
    public leadsRemoteSitesCreate({
        requestBody,
    }: {
        requestBody: RemoteSite,
    }): CancelablePromise<RemoteSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-remote-sites/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemoteSite
     * @throws ApiError
     */
    public leadsRemoteSitesRetrieve({
        site,
    }: {
        /**
         * A unique value identifying this remote site.
         */
        site: number,
    }): CancelablePromise<RemoteSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-remote-sites/{site}/',
            path: {
                'site': site,
            },
        });
    }

    /**
     * @returns RemoteSite
     * @throws ApiError
     */
    public leadsRemoteSitesUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this remote site.
         */
        site: number,
        requestBody: RemoteSite,
    }): CancelablePromise<RemoteSite> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-remote-sites/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemoteSite
     * @throws ApiError
     */
    public leadsRemoteSitesPartialUpdate({
        site,
        requestBody,
    }: {
        /**
         * A unique value identifying this remote site.
         */
        site: number,
        requestBody?: PatchedRemoteSite,
    }): CancelablePromise<RemoteSite> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-remote-sites/{site}/',
            path: {
                'site': site,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsRemoteSitesDestroy({
        site,
    }: {
        /**
         * A unique value identifying this remote site.
         */
        site: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-remote-sites/{site}/',
            path: {
                'site': site,
            },
        });
    }

}
