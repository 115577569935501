/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomesticHotWater } from '../models/DomesticHotWater';
import type { PaginatedDomesticHotWaterList } from '../models/PaginatedDomesticHotWaterList';
import type { PatchedDomesticHotWater } from '../models/PatchedDomesticHotWater';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsDomesticHotWatersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedDomesticHotWaterList
     * @throws ApiError
     */
    public leadsDomesticHotWatersList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedDomesticHotWaterList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-domestic-hot-waters/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns DomesticHotWater
     * @throws ApiError
     */
    public leadsDomesticHotWatersCreate({
        requestBody,
    }: {
        requestBody: DomesticHotWater,
    }): CancelablePromise<DomesticHotWater> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-domestic-hot-waters/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DomesticHotWater
     * @throws ApiError
     */
    public leadsDomesticHotWatersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this domestic hot water.
         */
        id: number,
    }): CancelablePromise<DomesticHotWater> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-domestic-hot-waters/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DomesticHotWater
     * @throws ApiError
     */
    public leadsDomesticHotWatersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this domestic hot water.
         */
        id: number,
        requestBody: DomesticHotWater,
    }): CancelablePromise<DomesticHotWater> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-domestic-hot-waters/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DomesticHotWater
     * @throws ApiError
     */
    public leadsDomesticHotWatersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this domestic hot water.
         */
        id: number,
        requestBody?: PatchedDomesticHotWater,
    }): CancelablePromise<DomesticHotWater> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-domestic-hot-waters/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsDomesticHotWatersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this domestic hot water.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-domestic-hot-waters/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
