/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferProductOptimiser } from '../models/OfferProductOptimiser';
import type { PaginatedOfferProductOptimiserList } from '../models/PaginatedOfferProductOptimiserList';
import type { PatchedOfferProductOptimiser } from '../models/PatchedOfferProductOptimiser';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersProductOptimisersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferProductOptimiserList
     * @throws ApiError
     */
    public offersProductOptimisersList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferProductOptimiserList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-optimisers/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OfferProductOptimiser
     * @throws ApiError
     */
    public offersProductOptimisersCreate({
        requestBody,
    }: {
        requestBody: OfferProductOptimiser,
    }): CancelablePromise<OfferProductOptimiser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers-product-optimisers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductOptimiser
     * @throws ApiError
     */
    public offersProductOptimisersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product optimiser.
         */
        id: number,
    }): CancelablePromise<OfferProductOptimiser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-optimisers/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OfferProductOptimiser
     * @throws ApiError
     */
    public offersProductOptimisersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product optimiser.
         */
        id: number,
        requestBody: OfferProductOptimiser,
    }): CancelablePromise<OfferProductOptimiser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers-product-optimisers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductOptimiser
     * @throws ApiError
     */
    public offersProductOptimisersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product optimiser.
         */
        id: number,
        requestBody?: PatchedOfferProductOptimiser,
    }): CancelablePromise<OfferProductOptimiser> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers-product-optimisers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersProductOptimisersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product optimiser.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers-product-optimisers/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
