/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPhotoStatusList } from '../models/PaginatedPhotoStatusList';
import type { PatchedPhotoStatus } from '../models/PatchedPhotoStatus';
import type { PhotoStatus } from '../models/PhotoStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsPhotoStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedPhotoStatusList
     * @throws ApiError
     */
    public clientsPhotoStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedPhotoStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-photo-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns PhotoStatus
     * @throws ApiError
     */
    public clientsPhotoStatusCreate({
        requestBody,
    }: {
        requestBody: PhotoStatus,
    }): CancelablePromise<PhotoStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-photo-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PhotoStatus
     * @throws ApiError
     */
    public clientsPhotoStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this photo status.
         */
        id: number,
    }): CancelablePromise<PhotoStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-photo-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PhotoStatus
     * @throws ApiError
     */
    public clientsPhotoStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this photo status.
         */
        id: number,
        requestBody: PhotoStatus,
    }): CancelablePromise<PhotoStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-photo-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PhotoStatus
     * @throws ApiError
     */
    public clientsPhotoStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this photo status.
         */
        id: number,
        requestBody?: PatchedPhotoStatus,
    }): CancelablePromise<PhotoStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-photo-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsPhotoStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this photo status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-photo-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
