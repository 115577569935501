/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductWorkforceList } from '../models/PaginatedProductWorkforceList';
import type { PatchedProductWorkforce } from '../models/PatchedProductWorkforce';
import type { ProductWorkforce } from '../models/ProductWorkforce';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsWorkforcesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductWorkforceList
     * @throws ApiError
     */
    public productsWorkforcesList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductWorkforceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-workforces/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductWorkforce
     * @throws ApiError
     */
    public productsWorkforcesCreate({
        requestBody,
    }: {
        requestBody: ProductWorkforce,
    }): CancelablePromise<ProductWorkforce> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-workforces/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductWorkforce
     * @throws ApiError
     */
    public productsWorkforcesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product workforce.
         */
        id: number,
    }): CancelablePromise<ProductWorkforce> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-workforces/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductWorkforce
     * @throws ApiError
     */
    public productsWorkforcesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product workforce.
         */
        id: number,
        requestBody: ProductWorkforce,
    }): CancelablePromise<ProductWorkforce> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-workforces/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductWorkforce
     * @throws ApiError
     */
    public productsWorkforcesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product workforce.
         */
        id: number,
        requestBody?: PatchedProductWorkforce,
    }): CancelablePromise<ProductWorkforce> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-workforces/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsWorkforcesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product workforce.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-workforces/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
