/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceStatus } from '../models/InvoiceStatus';
import type { PaginatedInvoiceStatusList } from '../models/PaginatedInvoiceStatusList';
import type { PatchedInvoiceStatus } from '../models/PatchedInvoiceStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsInvoiceStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedInvoiceStatusList
     * @throws ApiError
     */
    public clientsInvoiceStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedInvoiceStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-invoice-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns InvoiceStatus
     * @throws ApiError
     */
    public clientsInvoiceStatusCreate({
        requestBody,
    }: {
        requestBody: InvoiceStatus,
    }): CancelablePromise<InvoiceStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-invoice-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceStatus
     * @throws ApiError
     */
    public clientsInvoiceStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this invoice status.
         */
        id: number,
    }): CancelablePromise<InvoiceStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-invoice-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns InvoiceStatus
     * @throws ApiError
     */
    public clientsInvoiceStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this invoice status.
         */
        id: number,
        requestBody: InvoiceStatus,
    }): CancelablePromise<InvoiceStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-invoice-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceStatus
     * @throws ApiError
     */
    public clientsInvoiceStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this invoice status.
         */
        id: number,
        requestBody?: PatchedInvoiceStatus,
    }): CancelablePromise<InvoiceStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-invoice-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsInvoiceStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this invoice status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-invoice-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
