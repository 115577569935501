/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientQuoteProduct } from '../models/ClientQuoteProduct';
import type { PaginatedClientQuoteProductList } from '../models/PaginatedClientQuoteProductList';
import type { PatchedClientQuoteProduct } from '../models/PatchedClientQuoteProduct';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsQuoteProductsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientQuoteProductList
     * @throws ApiError
     */
    public clientsQuoteProductsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientQuoteProductList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-quote-products/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientQuoteProduct
     * @throws ApiError
     */
    public clientsQuoteProductsCreate({
        requestBody,
    }: {
        requestBody: ClientQuoteProduct,
    }): CancelablePromise<ClientQuoteProduct> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-quote-products/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientQuoteProduct
     * @throws ApiError
     */
    public clientsQuoteProductsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this client quote product.
         */
        id: number,
    }): CancelablePromise<ClientQuoteProduct> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-quote-products/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientQuoteProduct
     * @throws ApiError
     */
    public clientsQuoteProductsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client quote product.
         */
        id: number,
        requestBody: ClientQuoteProduct,
    }): CancelablePromise<ClientQuoteProduct> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-quote-products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientQuoteProduct
     * @throws ApiError
     */
    public clientsQuoteProductsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client quote product.
         */
        id: number,
        requestBody?: PatchedClientQuoteProduct,
    }): CancelablePromise<ClientQuoteProduct> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-quote-products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsQuoteProductsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this client quote product.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-quote-products/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
