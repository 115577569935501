/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductSolarPanelList } from '../models/PaginatedProductSolarPanelList';
import type { PatchedProductSolarPanel } from '../models/PatchedProductSolarPanel';
import type { ProductSolarPanel } from '../models/ProductSolarPanel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsSolarPanelsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductSolarPanelList
     * @throws ApiError
     */
    public productsSolarPanelsList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductSolarPanelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-solar-panels/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductSolarPanel
     * @throws ApiError
     */
    public productsSolarPanelsCreate({
        requestBody,
    }: {
        requestBody: ProductSolarPanel,
    }): CancelablePromise<ProductSolarPanel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-solar-panels/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSolarPanel
     * @throws ApiError
     */
    public productsSolarPanelsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product solar panel.
         */
        id: number,
    }): CancelablePromise<ProductSolarPanel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-solar-panels/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSolarPanel
     * @throws ApiError
     */
    public productsSolarPanelsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product solar panel.
         */
        id: number,
        requestBody: ProductSolarPanel,
    }): CancelablePromise<ProductSolarPanel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-solar-panels/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSolarPanel
     * @throws ApiError
     */
    public productsSolarPanelsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product solar panel.
         */
        id: number,
        requestBody?: PatchedProductSolarPanel,
    }): CancelablePromise<ProductSolarPanel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-solar-panels/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsSolarPanelsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product solar panel.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-solar-panels/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
