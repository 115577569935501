/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Email } from '../models/Email';
import type { PasswordToken } from '../models/PasswordToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PasswordResetService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * An Api View which provides a method to reset a password based on a unique token
     * @returns PasswordToken
     * @throws ApiError
     */
    public passwordResetConfirmCreate({
        requestBody,
    }: {
        requestBody: PasswordToken,
    }): CancelablePromise<PasswordToken> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/password-reset/confirm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * An Api View which provides a method to request a password reset token based on an e-mail address
     *
     * Sends a signal reset_password_token_created when a reset token was created
     * @returns Email
     * @throws ApiError
     */
    public passwordResetRequestCreate({
        requestBody,
    }: {
        requestBody: Email,
    }): CancelablePromise<Email> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/password-reset/request/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
