/** @format */

import {
  faChartLine,
  faCheck,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../misc/Tooltip';

const icons = {
  quote: faFileInvoice,
  study: faChartLine,
  valid: faCheck,
};

const color = {
  valid: 'green',
};

const text = {
  quote: 'Champ requis pour un devis',
  study: 'Champ requis pour une étude',
};

function RequiredFieldAdornment({ type }) {
  return (
    <InputAdornment position="end">
      <Tooltip content={text[type]}>
        <FontAwesomeIcon icon={icons[type]} color={color[type]} />
      </Tooltip>
    </InputAdornment>
  );
}

RequiredFieldAdornment.propTypes = {
  type: PropTypes.oneOf(['quote', 'study', 'valid']).isRequired,
};

export default RequiredFieldAdornment;
