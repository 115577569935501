/** @format */

const formatter = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const noZeroFormatter = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

/**
 * Formats a number to a string with money format
 * @param n {number} The number to format
 * @returns {string} The formatted number
 */
export const moneyFormatter = (n) => formatter.format(n);

/**
 * Formats a number to a string with percent format
 * @param n {number} The number to format
 * @returns {string} The formatted number
 */
export const percentFormatter = moneyFormatter;

/**
 * Formats a number to a string with energy format
 * @param n {number} The number to format
 * @returns {string} The formatted number
 */
export const energyFormatter = moneyFormatter;

/**
 * Formats a number to a string with VAT format
 * @param n {number} The number to format
 * @returns {string} The formatted number
 */
export const vatFormatter = (n) => noZeroFormatter.format(n);

/**
 * Formats a number to a string with phone format
 * @param n {number} The number to format
 * @returns {string} The formatted number
 */
export const phoneFormatter = (p) =>
  p
    ? p
        .replace('+33', '0')
        .replace(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5')
    : p;

export default null;
