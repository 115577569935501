/** @format */

import {
  Alert,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import { api } from '../../../api.ts';
import CacheManager from '../../../utils/cache';
import { parseFieldError } from '../../../utils/errors';
import { energyFormatter, moneyFormatter } from '../../../utils/numbers';
import BoxLoader from '../../misc/BoxLoader';
import NewTooltip from '../../misc/NewTooltip';
import ProvenanceIcon from './DashboardInlineProvenanceIcon';

const provenanceFieldMapping = {
  created_at: 'date',
  url: 'url',
  page_name: 'string',
  creator: 'string',
  referrer_last_name: 'string',
  referrer_first_name: 'string',
  referrer_zip_code: 'string',
  referrer_city: 'string',
  referrer_installations: 'installations',
  business_introducer: 'string',
  comments: 'string_array',
};

const provenanceFieldNames = {
  created_at: 'Date de création',
  url: 'URL',
  page_name: 'Page',
  creator: 'Créateur',
  referrer_last_name: 'Nom',
  referrer_first_name: 'Prénom',
  referrer_zip_code: 'Code postal',
  referrer_city: 'Ville',
  referrer_installations: 'Installations',
  business_introducer: "Apporteur d'affaire",
  comments: 'Commentaires',
};

function ProvenanceInfoValue({ name, value }) {
  const fieldType = useMemo(
    () => provenanceFieldMapping[name] || 'string',
    [name],
  );
  const parsedValue = useMemo(() => {
    switch (fieldType) {
      case 'date':
        return moment(value).format('DD/MM/YYYY - HH:mm');
      case 'url':
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        );
      case 'string':
        return value === 'Non renseigné' ? (
          <span style={{ fontStyle: 'italic' }}>{value}</span>
        ) : (
          value
        );
      case 'string_array':
        return (
          <List
            sx={{
              width: '100%',
              px: 2,
              pb: 2,
            }}
            dense
          >
            {value.map((comment) => (
              <ListItem key={comment}>
                <ListItemText primary={comment} />
              </ListItem>
            ))}
          </List>
        );
      case 'installations':
        return (
          <List
            sx={{
              width: '100%',
              px: 2,
              pb: 2,
            }}
            dense
          >
            {value.map((client) => (
              <Fragment
                key={`${client.signature_date}_${client.installation_price}`}
              >
                <ListItem>
                  <ListItemText
                    primary={
                      <div>
                        <div>
                          Installation{' '}
                          {client.kwc
                            ? `${energyFormatter(
                                client.kwc.replace(',', '.'),
                              )} kWc `
                            : ''}
                        </div>
                        <div>
                          signée le{' '}
                          {moment(client.signature_date).format('DD/MM/YYYY')}{' '}
                          par {client.signature_project_manager}
                        </div>
                        <div>
                          [{moneyFormatter(client.installation_price)} € TTC]
                        </div>
                      </div>
                    }
                    secondary={
                      <div style={{ fontStyle: 'italic' }}>
                        {client.solar_panel_name && (
                          <div>
                            Panneaux : {client.solar_panel_count}x{' '}
                            {client.solar_panel_name}
                          </div>
                        )}
                        {client.inverter_name && (
                          <div>Onduleur : {client.inverter_name}</div>
                        )}
                        {client.battery_name && (
                          <div>Batterie : {client.battery_name}</div>
                        )}
                      </div>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        );

      default:
        return value;
    }
  }, [fieldType, value]);
  return (
    <ListItem disableGutters>
      <ListItemText
        primary={provenanceFieldNames[name] || name}
        secondary={parsedValue}
      />
    </ListItem>
  );
}

ProvenanceInfoValue.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape().isRequired,
};

function ProvenanceTooltip({ provenance, provenanceInfo, loading, label }) {
  return (
    <BoxLoader loading={loading}>
      <Box>
        <Typography variant="h6" px={2} pt={2}>
          {label}
        </Typography>
        <List
          sx={{
            width: '100%',
            height: 300,
            maxHeight: 300,
            overflowY: 'auto',
            px: 2,
            pb: 2,
          }}
          dense
        >
          <ListItem disableGutters>
            <ListItemText
              primary="Date de création du lead"
              secondary={moment(provenanceInfo.created_at).format(
                'DD/MM/YYYY - HH:mm',
              )}
            />
          </ListItem>
          <Divider component="li" />
          {provenance.type === 'referrer' && (
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: 'bold' }}>
                    Informations sur le parrain :
                  </Typography>
                }
              />
            </ListItem>
          )}
          {Object.entries(provenanceInfo)
            .filter(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                value.length > 0 &&
                name !== 'created_at',
            )
            .map(([name, value]) => (
              <Fragment key={name}>
                <ProvenanceInfoValue name={name} value={value} />
                <Divider component="li" />
              </Fragment>
            ))}
          {!loading && !provenanceInfo && (
            <Alert severity="info">
              Aucune information de provenance n&apos;a été enregistrée pour ce
              lead.
            </Alert>
          )}
        </List>
      </Box>
    </BoxLoader>
  );
}

ProvenanceTooltip.propTypes = {
  provenance: PropTypes.shape().isRequired,
  provenanceInfo: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

function getProvenanceLabel(provenance) {
  if (!provenance) return '';
  if (provenance.type === 'internet') return 'Internet';
  if (provenance.type === 'business_introducer') return "Apporteur d'affaire";
  return provenance.title;
}

function DashboardInlineProvenanceInfo({ lead, onChange }) {
  const [loading, setLoading] = useState(false);
  const [provenanceInfo, setProvenanceInfo] = useState({});

  const provenance = useMemo(
    () =>
      CacheManager.instance
        .getModel('/leads-provenances/')
        .get(lead.provenance),
    [lead.provenance],
  );

  const provenanceLabel = useMemo(
    () => getProvenanceLabel(provenance),
    [provenance],
  );

  const { enqueueSnackbar } = useSnackbar();

  const loadProvenanceInfo = () => {
    setLoading(true);
    api
      .get(`/leads/${lead.id}/provenance_info/`)
      .then((result) => {
        setProvenanceInfo(result.data);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <NewTooltip
      placement="bottom"
      content={
        <ProvenanceTooltip
          provenance={provenance}
          provenanceInfo={provenanceInfo}
          loading={loading}
          label={provenanceLabel}
        />
      }
      onOpen={loadProvenanceInfo}
      isProvenanceTooltip
    >
      <div style={{ display: 'inline-block' }}>
        <NewTooltip
          placement="top"
          content={provenanceLabel}
          key={provenanceLabel}
        >
          <div style={{ display: 'inline-block' }}>
            <ProvenanceIcon provenanceId={lead.provenance} />
          </div>
        </NewTooltip>
      </div>
    </NewTooltip>
  );
}

DashboardInlineProvenanceInfo.propTypes = {
  lead: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DashboardInlineProvenanceInfo;
