/** @format */

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import clientFilesReducer from './clientFilesSlice';
import { listenerMiddleware } from './listenerMiddleware';
import pdfReducer from './pdfSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    pdf: pdfReducer,
    clientFiles: clientFilesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      listenerMiddleware.middleware,
    ),
});

export { store };

export default null;
