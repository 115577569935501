/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferProductMonitoring } from '../models/OfferProductMonitoring';
import type { PaginatedOfferProductMonitoringList } from '../models/PaginatedOfferProductMonitoringList';
import type { PatchedOfferProductMonitoring } from '../models/PatchedOfferProductMonitoring';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OffersProductMonitoringsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedOfferProductMonitoringList
     * @throws ApiError
     */
    public offersProductMonitoringsList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedOfferProductMonitoringList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-monitorings/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns OfferProductMonitoring
     * @throws ApiError
     */
    public offersProductMonitoringsCreate({
        requestBody,
    }: {
        requestBody: OfferProductMonitoring,
    }): CancelablePromise<OfferProductMonitoring> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/offers-product-monitorings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductMonitoring
     * @throws ApiError
     */
    public offersProductMonitoringsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product monitoring.
         */
        id: number,
    }): CancelablePromise<OfferProductMonitoring> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/offers-product-monitorings/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OfferProductMonitoring
     * @throws ApiError
     */
    public offersProductMonitoringsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product monitoring.
         */
        id: number,
        requestBody: OfferProductMonitoring,
    }): CancelablePromise<OfferProductMonitoring> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/offers-product-monitorings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OfferProductMonitoring
     * @throws ApiError
     */
    public offersProductMonitoringsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this offer product monitoring.
         */
        id: number,
        requestBody?: PatchedOfferProductMonitoring,
    }): CancelablePromise<OfferProductMonitoring> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/offers-product-monitorings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public offersProductMonitoringsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this offer product monitoring.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/offers-product-monitorings/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
