/**
 * eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import {
  faComments,
  faEdit,
  faFileInvoice,
  faHelmetSafety,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';

import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import {
  blue,
  green,
  teal,
  amber,
  cyan,
  red,
  deepOrange,
  lime,
  indigo,
} from '@mui/material/colors';
import {
  faAddressBook,
  faAlarmExclamation,
  faArrowProgress,
  faCheckDouble,
  faCircle1,
  faCircle2,
  faCircle3,
  faEmptySet,
  faFileContract,
  faEnvelope,
  faEnvelopeCircleCheck,
  faHandshake,
  faHandshakeSlash,
  faHourglassClock,
  faHourglassStart,
  faMessageCheck,
  faMessageSms,
  faPaperPlane,
  faPencilMechanical,
  faPhoneArrowUpRight,
  faPhoneSlash,
  faPhoneVolume,
  faCalendarAlt,
  faIdCard,
  faClipboardList,
  faFileSignature,
  faQuestionCircle,
  faSignInAlt,
  faTimesCircle,
  faFileSpreadsheet,
  faCalendarCheck,
  faCalendarLinesPen,
  faPhone,
  faPhonePlus,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clear } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { api, getApiUrl } from '../../../api.ts';
import { selectAuth } from '../../../redux/authSlice';
import { ThemeProvider } from '../../../theme';
import CacheManager from '../../../utils/cache';
import { leadInfo, MEETING_TYPE_CHOICES } from '../../../utils/constants';
import { parseFieldError } from '../../../utils/errors';
import { usePrevious } from '../../../utils/hooks';
import BoxLoader from '../../misc/BoxLoader';
import CustomAutocomplete from '../../misc/CustomAutocomplete';
import NewTooltip from '../../misc/NewTooltip';
import DashboardInlineEventManager, {
  CommunicationBadge,
} from './DashboardInlineEventManager';
import ProvenanceIcon from './DashboardInlineProvenanceIcon';
import DashboardInlineProvenanceInfo from './DashboardInlineProvenanceInfo';
import LeadCommentDialog from './LeadCommentDialog';
import ProjectManagerIcon, { stringAvatar } from './ProjectManagerIcon';
import FollowUpDialog from '../followUp/FollowUpDialog';
import FollowUpToggleButtonGroup from '../followUp/FollowUpToggleButtonGroup';

const DashboardContext = createContext(null);
const RowContext = createContext(null);

const RowComponent = forwardRef((props, ref) => {
  const [hover, setHover] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [toBeClosed, setToBeClosed] = useState(false);
  const contextData = useMemo(() => ({ hover, setModalOpened }), [hover]);

  useEffect(() => {
    if (toBeClosed && !modalOpened) {
      setModalOpened(false);
      setToBeClosed(false);
      setHover(false);
    }
  }, [toBeClosed, modalOpened]);

  return (
    <RowContext.Provider value={contextData}>
      <TableRow
        {...props}
        ref={ref}
        onMouseOver={() => setHover(true)}
        // onMouseOut={() => (modalOpened ? setToBeClosed(true) : setHover(false))}
        sx={{
          height: 80,
          // bgcolor: bgColorMapping(props.item.current_follow_up_status),
          bgcolor: props.item.project_manager === 1 ? 'error.light' : undefined,
          '&:hover': {
            bgcolor:
              props.item.project_manager === 1
                ? 'error.lighter'
                : 'background.default',
          },
        }}
      />
    </RowContext.Provider>
  );
});

RowComponent.propTypes = {
  item: PropTypes.shape().isRequired,
};

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      style={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      sx={{
        '& .MuiTableCell-root': {
          fontSize: (theme) => ({
            xs: '0.8rem',
            sm: '0.875rem',
            md: '0.875rem',
            lg: '0.875rem',
            xl: '0.875rem',
          }),
        },
      }}
      size="small"
    />
  ),
  TableHead: (props) => (
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    <TableHead {...props} style={{ ...props.style, zIndex: 10 }} />
  ),
  TableRow: RowComponent,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const columnsWidth = {
  id: 80,
  provenance: 80,
  status: 270,
  action: 160,
  action_result: 260,
  city: 200,
  last_name: 270,
  lost: 80,
  contact: 135,
  project_manager: 56,
  zip_code: 80,
  last_offer_kwc: 84,
  category: 120,
  delay: 150,
};

const isStatusActive = (currentSorting, columnName) =>
  [columnName, `-${columnName}`].includes(currentSorting);

const getSortingDirection = (currentSorting, columnName) => {
  if (isStatusActive(currentSorting, columnName)) {
    return currentSorting.startsWith('-') ? 'desc' : 'asc';
  }
  return undefined;
};

const FixedHeaderContent = React.memo(() => {
  const { sorting, handleSortingChange } = useContext(DashboardContext);
  return (
    <TableRow
      sx={{
        bgcolor: 'background.paper',
      }}
    >
      <TableCell sx={{ width: columnsWidth.id }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          ID
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.provenance }}>
        <NewTooltip content="Provenance">
          <Typography
            noWrap
            sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}
          >
            Provenance
          </Typography>
        </NewTooltip>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.last_name }}>
        <TableSortLabel
          active={isStatusActive(sorting, 'last_name')}
          direction={getSortingDirection(sorting, 'last_name')}
          onClick={() => handleSortingChange('last_name')}
        >
          <Typography
            noWrap
            sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}
          >
            Nom & Prénom
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.category }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Statut
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.action }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Action à effectuer
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.action_result }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Résultat de l&apos;action
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.delay }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Retard
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.contact }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Contact
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.project_manager }}>
        <Typography
          noWrap
          sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}
        />
      </TableCell>
      <TableCell sx={{ width: columnsWidth.city }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Ville
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.zip_code }}>
        <Typography noWrap sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
          Dpt
        </Typography>
      </TableCell>
    </TableRow>
  );
});

function fixedHeaderContent(index) {
  return <FixedHeaderContent index={index} />;
}

const copyLeadInfo = (lead, enqueueSnackbar) => {
  if ('clipboard' in navigator) {
    const information = leadInfo(lead);
    navigator.clipboard
      .writeText(information)
      .then(() => {
        enqueueSnackbar('Adresse copiée !', { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
      });
  }
};

const isDueDateReached = (dueDate) => {
  const currentDate = new Date();
  const dueDateObj = new Date(dueDate);
  return dueDateObj >= currentDate;
};

const getDaysOfDelay = (dueDate) => {
  const currentDate = moment().startOf('day');
  const dueDateMoment = moment(dueDate).startOf('day');
  return Math.abs(dueDateMoment.diff(currentDate, 'days'));
};

const filterLeadsByScheduledOrDueDate = (leads) => {
  const today = new Date();

  return leads; // TODO: remove for prod
  // return leads.filter((lead) => {
  //   const scheduledDate = lead.follow_up_meeting?.scheduled_date
  //     ? new Date(lead.follow_up_meeting.scheduled_date)
  //     : null;
  //   const dueDate = lead.follow_up_task?.due_date
  //     ? new Date(lead.follow_up_task.due_date)
  //     : null;
  //
  //   if (scheduledDate && scheduledDate > today) {
  //     return false;
  //   }
  //
  //   return (
  //     (scheduledDate && scheduledDate <= today) || (dueDate && dueDate <= today)
  //   );
  // });
};

function ItemContent({ index, lead }) {
  const { onLeadChange, reloadAllData } = useContext(DashboardContext);
  const { hover, setModalOpened } = useContext(RowContext);
  const [showInlineEventActions, setShowInlineEventActions] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isFollowUpDialogOpen, setIsFollowUpDialogOpen] = useState(false);
  const [followUpDialogSection, setFollowUpDialogSection] = useState(null);

  const navigate = useNavigate();

  const confirmTechnicalVisitNoR3Set = () => {
    // setLoading(true);
    const data = {
      lead: lead.id,
      is_confirmed: true,
      has_planned_next_meeting: false,
      has_signed: false,
    };
    api
      .post(`/follow-up/${lead.id}/confirm_meeting/`, {}, data)
      .then(() => {
        // setLoading(false);
        // handleClose();
        if (reloadAllData) {
          reloadAllData();
        }
        enqueueSnackbar('Les informations ont été mises à jour', {
          variant: 'success',
        });
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
        // setLoading(false);
      });
  };

  const signContract = () => {
    api
      .post(`/follow-up/${lead.id}/sign_contract/`, {}, {})
      .then(() => {
        navigate(`/lead/${lead.id}/quote`);
        enqueueSnackbar('Les informations ont été mises à jour', {
          variant: 'success',
        });
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
      });
  };

  const getFollowUpStatusIcon = (status) => {
    if (status.is_contact) return faAddressBook;
    if (status.is_follow_up_r1) return faPhone;
    if (status.is_follow_up_r2) return faPhonePlus;
    if (status.is_follow_up_r3) return faHandshakeSlash;
    if (status.is_r1_completed) return faTruckFast;
    if (status.is_confirmation_r1) return faCalendarCheck;
    if (status.is_confirmation_r2) return faCalendarCheck;
    if (status.is_confirmation_technical_visit) return faCalendarCheck;
    if (status.is_confirmation_r3) return faCalendarCheck;
    return null;
  };

  // const getCategoryTooltipContent = (status) => {
  //   if (status.is_contact) return 'En attente de 1er contact';
  //   if (status.is_follow_up_r1) return 'RDV R1 à prendre';
  //   if (status.is_follow_up_r2) return 'RDV R2 à prendre';
  //   if (status.is_follow_up_r3) return 'RDV R3 à prendre';
  //   if (status.is_r1_completed)
  //     return 'R1 effectué - Offre générée - Planifier R2 ou VT';
  //   if (status.is_confirmation_r1)
  //     return 'RDV R1 à effectuer - Devis à envoyer';
  //   if (status.is_confirmation_r2) return 'RDV R2 à effectuer - VT à planifier';
  //   if (status.is_confirmation_technical_visit)
  //     return 'VT à effectuer - Contrat à signer ou R3 à planifier';
  //   if (status.is_confirmation_r3)
  //     return 'RDV R3 à effectuer - Contrat à signer';
  //   return null;
  // };

  const iconColorMapping = (item) => {
    if (item.is_contact) return 'grey';
    if (item.is_follow_up_r1) return 'purple';
    if (item.is_confirmation_r1) return 'darkpurple';
    if (item.is_follow_up_r2) return 'orange';
    if (item.is_confirmation_r2) return 'darkorange';
    if (item.is_follow_up_r3) return 'green';
    if (item.is_confirmation_r3) return 'darkgreen';
    if (item.is_r1_completed) return 'brown';
    if (item.is_confirmation_technical_visit) return 'brown';
    return undefined;
  };

  const actionMapping = {
    1: 'Prendre RDV',
    2: 'Signer ou prendre RDV',
    3: 'Appel',
  };

  const getAction = (item) => {
    if (item.current_follow_up_status.is_r1_completed) {
      return 1;
    }
    if (
      (item.current_follow_up_status.is_confirmation_technical_visit ||
        item.current_follow_up_status.is_follow_up_r3 ||
        item.current_follow_up_status.is_confirmation_r3) &&
      item.follow_up_task.is_meeting_confirmation
    ) {
      return 2;
    }
    return 3;
  };

  // const actionResultMapping = {
  //   // TODO: update values
  //   1: 'Planifier RDV téléphonique', // Agenda
  //   2: 'Planifier VT', // Agenda VT
  //   3: 'Saisir les qualifications du lead', // Devis & études
  //   4: 'Pas de réponse', // Pas de réponse
  //   5: 'Contrat signé', // Contrat signé
  //   6: 'Contrat non signé et pas de R3 planifié à la VT', // Pas de R3 planifié
  // };

  const actionResultMapping = {
    // 1: 'Planifier RDV téléphonique',
    1: () => {
      if (lead.current_follow_up_status.is_contact) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return 'Vous avez le client au téléphone lors du 1er appel, vous planifiez le R1 sur votre agenda';
          case 2:
            return 'Vous avez le client au téléphone lors du 2ème appel, vous planifiez le R1 sur votre agenda';
          case 3:
            return 'Vous avez le client au téléphone lors du 3ème appel, vous planifiez le R1 sur votre agenda';
          case 4:
            return 'Vous avez le client au téléphone lors du 4ème appel, vous planifiez le R1 sur votre agenda';
          case 5:
            return 'Vous avez le client au téléphone lors du 5ème appel, vous planifiez le R1 sur votre agenda';
          default:
            return 'Planifier RDV téléphonique';
        }
      } else if (
        lead.current_follow_up_status.is_follow_up_r1 ||
        lead.current_follow_up_status.is_confirmation_r1
      ) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return 'Vous avez le client au téléphone lors du 1er appel, vous planifiez le R2 sur votre agenda';
          case 2:
            return 'Vous avez le client au téléphone lors du 2ème appel, vous planifiez le R2 sur votre agenda';
          case 3:
            return 'Vous avez le client au téléphone lors du 3ème appel, vous planifiez le R2 sur votre agenda';
          case 4:
            return 'Vous avez le client au téléphone lors du 4ème appel, vous planifiez le R2 sur votre agenda';
          case 5:
            return 'Vous avez le client au téléphone lors du 5ème appel, vous planifiez le R2 sur votre agenda';
          default:
            return 'Planifier RDV téléphonique';
        }
      } else if (
        lead.current_follow_up_status.is_follow_up_r2 ||
        lead.current_follow_up_status.is_confirmation_r2
      ) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return 'Vous avez le client au téléphone lors du 1er appel et ne complétez pas le RDV, vous planifiez un nouveau R2 sur votre agenda';
          case 2:
            return 'Vous avez le client au téléphone lors du 2ème appel et ne complétez pas le RDV, vous planifiez un nouveau R2 sur votre agenda';
          case 3:
            return 'Vous avez le client au téléphone lors du 3ème appel et ne complétez pas le RDV, vous planifiez un nouveau R2 sur votre agenda';
          case 4:
            return 'Vous avez le client au téléphone lors du 4ème appel et ne complétez pas le RDV, vous planifiez un nouveau R2 sur votre agenda';
          case 5:
            return 'Vous avez le client au téléphone lors du 5ème appel et ne complétez pas le RDV, vous planifiez un nouveau R2 sur votre agenda';
          default:
            return 'Planifier RDV téléphonique';
        }
      } else if (
        lead.current_follow_up_status.is_follow_up_r3 ||
        lead.current_follow_up_status.is_confirmation_r3
      ) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return 'Vous avez le client au téléphone lors du 1er appel, vous planifiez un nouveau R3 sur votre agenda';
          case 2:
            return 'Vous avez le client au téléphone lors du 2ème appel, vous planifiez un nouveau R3 sur votre agenda';
          case 3:
            return 'Vous avez le client au téléphone lors du 3ème appel, vous planifiez un nouveau R3 sur votre agenda';
          case 4:
            return 'Vous avez le client au téléphone lors du 4ème appel, vous planifiez un nouveau R3 sur votre agenda';
          case 5:
            return 'Vous avez le client au téléphone lors du 5ème appel, vous planifiez un nouveau R3 sur votre agenda';
          default:
            return 'Planifier RDV téléphonique';
        }
      } else if (lead.current_follow_up_status.is_r1_completed) {
        return 'Vous avez généré une offre, planifiez le R2 en cliquant sur ce bouton';
      } else if (
        lead.current_follow_up_status.is_confirmation_technical_visit
      ) {
        return "Vous avez effectué la VT mais n'avez pas signé le contrat, vous devez planifier le R3 sur votre agenda";
      }
      return 'Planifier RDV téléphonique';
    },
    // 2: 'Planifier VT',
    2: () => {
      if (lead.current_follow_up_status.is_r1_completed) {
        return 'Vous avez généré une offre, planifiez la VT en cliquant sur ce bouton';
      }
      if (
        lead.current_follow_up_status.is_confirmation_r2 ||
        lead.current_follow_up_status.is_follow_up_r2
      ) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return 'Vous avez le client au téléphone lors du 1er appel, vous planifiez une VT sur votre agenda';
          case 2:
            return 'Vous avez le client au téléphone lors du 2ème appel, vous planifiez une VT sur votre agenda';
          case 3:
            return 'Vous avez le client au téléphone lors du 3ème appel, vous planifiez une VT sur votre agenda';
          case 4:
            return 'Vous avez le client au téléphone lors du 4ème appel, vous planifiez une VT sur votre agenda';
          case 5:
            return 'Vous avez le client au téléphone lors du 5ème appel, vous planifiez une VT sur votre agenda';
          default:
            return 'Planifier RDV téléphonique';
        }
      }
      return 'Planifier VT';
    },
    // 3: 'Saisir les qualifications du lead',
    3: 'Vous êtes au téléphone avec le client et vous effectuez le R1 de suite',
    //   4: 'Pas de réponse', // Pas de réponse
    4: () => {
      if (
        lead.current_follow_up_status.is_contact ||
        lead.current_follow_up_status.is_follow_up_r1 ||
        lead.current_follow_up_status.is_confirmation_r1 ||
        lead.current_follow_up_status.is_follow_up_r2 ||
        lead.current_follow_up_status.is_confirmation_r2 ||
        lead.current_follow_up_status.is_follow_up_r3 ||
        lead.current_follow_up_status.is_confirmation_r3
      ) {
        switch (lead.follow_up_call.call_number) {
          case 1:
            return (
              "Vous n'avez pas réussi à avoir le client lors du 1er appel, vous devez lui laisser un message " +
              "sur son répondeur et cliquer sur ce bouton afin d'envoyer un e-mail"
            );
          case 2:
            return (
              "Vous n'avez pas réussi à avoir le client lors du 2ème appel, vous devez lui laisser un message" +
              " sur son répondeur et cliquer sur ce bouton afin d'envoyer un SMS"
            );
          case 3:
            return (
              "Vous n'avez pas réussi à avoir le client lors du 3ème appel, vous devez lui laisser un message " +
              "sur son répondeur et cliquer sur ce bouton afin d'envoyer un SMS"
            );
          case 4:
            return (
              "Vous n'avez pas réussi à avoir le client lors du 4ème appel, signalez cet appel manqué sans envoyer " +
              'de message en cliquant sur ce bouton'
            );
          case 5:
            return (
              "Vous n'avez pas réussi à avoir le client lors du 5ème appel, vous devez lui laisser un message sur son " +
              "répondeur et cliquer sur ce bouton afin d'envoyer un e-mail et de sortir ce client du funnel de vente pour le placer " +
              'dans le programme marketing'
            );
          default:
            return 'Pas de réponse';
        }
      }
      return 'Pas de réponse';
    },
    // 5: 'Contrat signé',
    5: 'Vous avez signé le contrat avec le client, cliquez sur ce bouton pour le sortir du processus de relance',
  };

  const getActionResultTooltip = (result) => {
    const tooltipContent = actionResultMapping[result];
    if (typeof tooltipContent === 'function') {
      return tooltipContent();
    }
    return tooltipContent;
  };

  const actionResultIcons = {
    1: faCalendarAlt,
    2: faTruckFast,
    // 2: faCalendarLinesPen,
    3: faIdCard,
    4: faPhoneSlash,
    5: faFileSignature,
  };

  const actionResultColors = {
    1: 'primary',
    2: 'error',
    // 2: 'secondary',
    3: teal[500],
    4: 'warning',
    5: 'info',
    // 5: indigo[500],
  };

  const isMuiColor = (color) =>
    ['primary', 'secondary', 'warning', 'info', 'error'].includes(color);

  function isFollowUpWithoutMessage() {
    return (
      (lead.current_follow_up_status.is_contact === true &&
        lead.follow_up_call.call_number === 4) ||
      (lead.current_follow_up_status.is_follow_up_r1 === true &&
        lead.follow_up_call.call_number === 4) ||
      (lead.current_follow_up_status.is_follow_up_r2 === true &&
        lead.follow_up_call.call_number === 4) ||
      (lead.current_follow_up_status.is_follow_up_r3 === true &&
        lead.follow_up_call.call_number === 4)
    );
  }

  const updateFollowUpDataVoicemailWithoutMessage = () => {
    const data = {
      lead: lead.id,
      action: 'voicemail',
      result: 'no_response',
    };
    api
      .post(`/follow-up/${lead.id}/call_follow_up/`, {}, data)
      .then(() => {
        if (reloadAllData) {
          reloadAllData();
        }
        enqueueSnackbar('Les information ont été mise à jour', {
          variant: 'success',
        });
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
      });
  };

  const actionResultOnClick = {
    1: () => {
      setFollowUpDialogSection('calendar');
      setIsFollowUpDialogOpen(true);
    }, // Agenda
    2: () => {
      setFollowUpDialogSection('technicalVisitCalendar');
      setIsFollowUpDialogOpen(true);
    }, // Agenda VT
    3: () => navigate(`/lead/${lead.id}/qualification`), // Devis & études
    4: () => {
      if (isFollowUpWithoutMessage()) {
        updateFollowUpDataVoicemailWithoutMessage();
      } else {
        setFollowUpDialogSection('message');
        setIsFollowUpDialogOpen(true);
      }
    }, // Pas de réponse
    5: () => signContract(), // Contrat signé
    6: () => confirmTechnicalVisitNoR3Set(), // Pas de R3 planifié
  };

  function getActionResult(item) {
    const results = [];
    const action = getAction(item);

    if (action === 1) {
      results.push(1, 2);
    } else if (action === 2) {
      if (lead.current_follow_up_status.is_confirmation_technical_visit) {
        results.push(5, 1);
      } else if (
        lead.current_follow_up_status.is_confirmation_r3 ||
        lead.current_follow_up_status.is_follow_up_r3
      ) {
        results.push(5, 1, 4);
      }
    } else if (action === 3) {
      if (lead.current_follow_up_status.is_contact) {
        results.push(1, 3, 4);
      } else if (
        lead.current_follow_up_status.is_follow_up_r1 ||
        lead.current_follow_up_status.is_confirmation_r1
      ) {
        results.push(1, 2, 3, 4);
      } else if (
        lead.current_follow_up_status.is_follow_up_r2 ||
        lead.current_follow_up_status.is_confirmation_r2
      ) {
        results.push(2, 4);
      } else if (
        lead.current_follow_up_status.is_follow_up_r3 ||
        lead.current_follow_up_status.is_confirmation_r3
      ) {
        results.push(5, 1, 4);
      }
    }

    return results;
  }

  useEffect(() => {
    if (!hover) {
      setShowInlineEventActions(false);
    }
  }, [hover]);

  // const handleIconClick = () => {
  //   setIsFollowUpDialogOpen(true);
  // };
  //
  // const isConfirmationStatus =
  //   lead.current_follow_up_status.is_confirmation_r1 ||
  //   lead.current_follow_up_status.is_confirmation_r2 ||
  //   lead.current_follow_up_status.is_confirmation_r3;

  return (
    <>
      <TableCell sx={{ width: columnsWidth.id }}>
        <Typography noWrap={!hover} sx={{ fontSize: 'inherit' }}>
          {lead.id}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.provenance }}>
        <Typography noWrap={!hover} sx={{ fontSize: 'inherit' }}>
          {hover ? (
            <DashboardInlineProvenanceInfo
              lead={lead}
              onChange={onLeadChange}
            />
          ) : (
            <ProvenanceIcon provenanceId={lead.provenance} />
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.last_name }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography noWrap={!hover} sx={{ fontSize: 'inherit' }}>
              <strong>{lead.last_name.toUpperCase()}</strong> {lead.first_name}
            </Typography>
          </Grid>
          {hover ? (
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item>
                  <NewTooltip content="Qualification" placement="top">
                    <IconButton
                      variant="contained"
                      color="primary"
                      size="small"
                      to={`../lead/${lead.id}/qualification`}
                      component={Link}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  </NewTooltip>
                </Grid>
                <Grid item>
                  <NewTooltip content="Devis & études" placement="top">
                    <IconButton
                      variant="contained"
                      color="primary"
                      size="small"
                      to={`../lead/${lead.id}/quote`}
                      component={Link}
                    >
                      <FontAwesomeIcon icon={faFileInvoice} />
                    </IconButton>
                  </NewTooltip>
                </Grid>
                <Grid item>
                  <LeadCommentDialog
                    lead={lead}
                    setModalOpened={setModalOpened}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item>
                  <IconButton variant="contained" color="primary" size="small">
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton variant="contained" color="primary" size="small">
                    <FontAwesomeIcon icon={faFileInvoice} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton variant="contained" color="primary" size="small">
                    <FontAwesomeIcon icon={faComments} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.category }}>
        {lead.current_follow_up_status && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NewTooltip
              content={lead.current_follow_up_status.name}
              // content={getCategoryTooltipContent(lead.current_follow_up_status)}
            >
              <Box>
                <FontAwesomeIcon
                  icon={getFollowUpStatusIcon(lead.current_follow_up_status)}
                  size="xl"
                  // sx={{ color: 'red' }}
                  // color="orange"
                  color={iconColorMapping(lead.current_follow_up_status)}
                />
              </Box>
            </NewTooltip>
            {/* {lead.follow_up_meeting ? ( */}
            {/*  <Box sx={{ ml: 1 }}> */}
            {/*    {`${lead.follow_up_meeting.meeting_type.toUpperCase()}.${ */}
            {/*      lead.follow_up_meeting.meeting_number */}
            {/*    }`} */}
            {/*  </Box> */}
            {/* ) : ( */}
            {/*  <Box sx={{ ml: 1 }}>R1</Box> */}
            {/* )} */}
            {lead.follow_up_meeting &&
              (lead.current_follow_up_status?.is_r1_completed ? (
                <Box sx={{ ml: 1 }}>R2 ou VT</Box>
              ) : (
                <Box sx={{ ml: 1 }}>
                  {MEETING_TYPE_CHOICES[lead.follow_up_meeting.meeting_type]}
                  {lead.follow_up_meeting.meeting_type !== 'technical_visit' &&
                    `.${lead.follow_up_meeting.meeting_number}`}
                </Box>
              ))}
            {!lead.follow_up_meeting &&
              (lead.current_follow_up_status.is_r1_completed ? (
                <Box sx={{ ml: 1 }}>R2 ou VT</Box>
              ) : (
                <Box sx={{ ml: 1 }}>R1</Box>
              ))}
          </Box>
        )}
      </TableCell>
      <TableCell sx={{ width: columnsWidth.action }}>
        <Grid container>
          <Typography sx={{ fontSize: 'inherit', textAlign: 'center' }}>
            {actionMapping[getAction(lead)] === 'Appel'
              ? `${actionMapping[getAction(lead)]} n°${
                  lead.follow_up_call.call_number
                }`
              : actionMapping[getAction(lead)]}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.action_result }}>
        <Grid container spacing={1}>
          {getActionResult(lead).map((result) => (
            <Grid item key={result}>
              {/* <NewTooltip content={actionResultMapping[result]}> */}
              <NewTooltip content={getActionResultTooltip(result)}>
                <Button
                  variant="contained"
                  // color={actionResultColors[result]}
                  // style={{ backgroundColor: actionResultColors[result] }}
                  color={
                    isMuiColor(actionResultColors[result])
                      ? actionResultColors[result]
                      : undefined
                  }
                  style={
                    !isMuiColor(actionResultColors[result])
                      ? { backgroundColor: actionResultColors[result] }
                      : undefined
                  }
                  size="medium"
                  onClick={() => actionResultOnClick[result](lead)}
                  sx={{
                    minWidth: 'auto',
                    width: '50px',
                    height: '40px',
                  }}
                >
                  <FontAwesomeIcon icon={actionResultIcons[result]} size="xl" />
                </Button>
              </NewTooltip>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.delay }}>
        {lead.follow_up_task &&
        isDueDateReached(lead.follow_up_task.due_date) ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faHourglassStart} />
            <Box sx={{ ml: 1 }}>À temps</Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={
                getDaysOfDelay(lead.follow_up_task.due_date) >= 1
                  ? faAlarmExclamation
                  : faHourglassClock
              }
              // icon={faAlarmExclamation}
              color={
                getDaysOfDelay(lead.follow_up_task.due_date) >= 1
                  ? 'red'
                  : 'orange'
              }
              size="xl"
            />
            <Box sx={{ ml: 1 }}>{`${getDaysOfDelay(
              lead.follow_up_task.due_date,
            )} jours`}</Box>
          </Box>
        )}
      </TableCell>
      <TableCell sx={{ width: columnsWidth.contact }}>
        <NewTooltip content={lead.email}>
          <Typography noWrap={!hover} sx={{ fontSize: 'inherit' }}>
            {lead.phone_1 && !lead.phone_1.startsWith('+')
              ? lead.phone_1.replace(
                  /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                  '$1 $2 $3 $4 $5',
                )
              : lead.phone_1 || <FontAwesomeIcon icon={faEmptySet} />}
          </Typography>
        </NewTooltip>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.project_manager, px: 0 }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item>
            <ProjectManagerIcon
              lead={lead}
              onChange={onLeadChange}
              hover={hover}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.city }}>
        <Typography noWrap={!hover} sx={{ fontSize: 'inherit' }}>
          <NewTooltip
            content={
              <>
                {lead.address_1 && lead.address_1 !== '' && (
                  <div>{lead.address_1}</div>
                )}
                {lead.address_2 && lead.address_2 !== '' && (
                  <div>{lead.address_2}</div>
                )}
                <div>
                  {lead.zip_code || ''}
                  {lead.zip_code && lead.zip_code !== '' ? ' ' : ''}
                  {lead.city || ''}
                </div>
                <br />
                <Typography
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    fontStyle: 'italic',
                    color: 'warning.main',
                  }}
                >
                  Cliquez pour copier l&apos;adresse de ce lead.
                </Typography>
              </>
            }
          >
            <Typography
              noWrap
              sx={{ fontSize: 'inherit', cursor: 'pointer' }}
              onClick={() => copyLeadInfo(lead, enqueueSnackbar)}
              role="button"
              component="span"
            >
              {!lead.city ? <FontAwesomeIcon icon={faEmptySet} /> : lead.city}
            </Typography>
          </NewTooltip>
        </Typography>
      </TableCell>
      <TableCell sx={{ width: columnsWidth.zip_code }}>
        {lead.zip_code}
      </TableCell>
      <FollowUpDialog
        lead={lead}
        // disabled={loading}
        open={isFollowUpDialogOpen}
        onClose={() => {
          setIsFollowUpDialogOpen(false);
        }}
        onChange={reloadAllData}
        // isMeetingConfirmation
        section={followUpDialogSection}
      />
    </>
  );
}

ItemContent.propTypes = {
  index: PropTypes.number.isRequired,
  lead: PropTypes.shape().isRequired,
};

const MemoizedItemContent = React.memo(ItemContent);

function itemContent(index, lead) {
  return <MemoizedItemContent index={index} lead={lead} />;
}

function LeadFollowUpDashboard() {
  const savedStatusFilter = useMemo(
    () => localStorage.getItem('dashboard-lead-status-filter') || '0',
    [],
  );
  const savedPMFilter = useMemo(
    () =>
      CacheManager.instance
        .getModel('/users/')
        .get(localStorage.getItem('dashboard-project-manager-filter')) ||
      undefined,
    [],
  );

  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [leads, setLeads] = useState([]);
  const [totalLeadsForBadges, setTotalLeadsForBadges] = useState([]);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [projectManager, setProjectManager] = useState(savedPMFilter);
  const [countLeadByPm, setCountLeadByPm] = useState({ '-----': 0 });
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [loadingCountByPm, setLoadingCountByPm] = useState(false);
  const [counts, setCounts] = useState(null);
  const ref = useRef(null);
  const leadTableRef = useRef(null);
  const resizeObserver = useRef(null);
  const virtuoso = useRef(null);
  const prevLeads = usePrevious(leads);

  const { user } = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const canSelectProjectManager = useMemo(
    () => user.is_admin_leader || user.is_external_accountant,
    [user],
  );
  const searchTimeout = useRef(null);
  const isInitialRenderSearch = useRef(true);
  const isInitialRenderProjectManager = useRef(true);
  const isInitialRenderFilters = useRef(true);
  const isInitialRenderOffset = useRef(true);

  /**
   * Handles search change
   * @param event {Event} Event
   */
  const handleSearchChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    setSearch(value);
  };

  /**
   * Handles project manager change
   * @param _projectManager {Object} Project manager
   */
  const handleProjectManagerChange = (_projectManager) => {
    setProjectManager(_projectManager);
  };

  /**
   * Handles sorting change
   * @param _sorting {Object} Sorting
   */
  const handleSortingChange = (_sorting) => {
    let newSorting = _sorting;
    if (sorting.startsWith('-')) {
      if (_sorting === sorting.replace('-', '')) {
        newSorting = '';
      }
    } else if (_sorting === sorting) {
      newSorting = `-${sorting}`;
    }
    setSorting(newSorting);
  };

  /**
   * Loads stats from the API
   */
  const loadStats = () => {
    setLoadingCountByPm(true);
    return api
      .get('leads/get_count_leads_by_pm/')
      .then((result) => {
        setCountLeadByPm(result.data);
        setLoadingCountByPm(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCountByPm(false);
      });
  };

  /**
   * Clears search
   */
  const clearSearch = () => {
    setSearch('');
  };

  /**
   * Loads lead counts from the API
   * @returns {Promise<T>}
   */
  const loadCounts = () => {
    setLoadingCounts(true);
    return api
      .get(
        `leads/counts${
          projectManager ? `?project_manager=${projectManager.id}` : ''
        }`,
      )
      .then((result) => {
        setCounts(result.data);
        setLoadingCounts(false);
      })
      .catch((err) => {
        console.error(err);
        setCounts(null);
        setLoadingCounts(false);
      });
  };

  /**
   * Loads leads from the API
   * @returns {Promise<T>}
   */
  const loadLeads = (reset) => {
    let tmpSearch = search;
    setLoading(true);
    const r1 = /^0[1-9]/;
    if (search.match(r1)) {
      const r2 = /^0/;
      tmpSearch = tmpSearch.replace(r2, '+33');
    }
    return api
      .get(
        `leads/?filter=True&search=${tmpSearch.split(' ').join(',')}${
          sorting && sorting.length > 0 ? `&ordering=${sorting}` : ''
        }&offset=${reset ? 0 : offset}&limit=50${
          projectManager ? `&project_manager=${projectManager.id}` : ''
        }${
          tmpSearch === '' ? '&is_external_referrer=false' : ''
        }&is_test=false&is_follow_up=true`,
      )
      .then((result) => {
        const filteredLeads = filterLeadsByScheduledOrDueDate(
          result.data.results,
        );
        if (offset && offset > 0 && !reset) {
          setLeads((prevState) => [...prevState, ...filteredLeads]);
        } else {
          setLeads(filteredLeads);
        }
        setTotalRows(filteredLeads.length);
        setTotalLeadsForBadges(result.data.results);
        setLoading(false);
        return filteredLeads;
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };
  /**
   * Loads a specific lead from the API
   * @param id {Number} Lead ID
   * @returns {Promise<T>}
   */
  const loadLead = (id) =>
    api
      .get(`leads/${id}/`)
      .then((result) => result.data)
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });

  /**
   * Handles lead input change
   * @param lead {Object} Lead
   */
  const onLeadInputChange = (lead) => {
    loadLead(lead.id)
      .then((newLead) => {
        setLeads((prevState) => {
          const newLeads = [...prevState];
          const indexClient = newLeads.find((o) => o.id === newLead.id);
          const index = newLeads.indexOf(indexClient);
          newLeads[index] = newLead;
          return newLeads;
        });
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };

  const onTableEndReached = (n) => {
    if (offset + 50 < totalRows) {
      setOffset(offset + 50);
    }
  };

  const reloadAllData = () => {
    Promise.all([loadLeads(true), loadCounts(), loadStats()])
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const dashboardContextData = useMemo(
    () => ({
      projectManager,
      sorting,
      handleSortingChange,
      onLeadChange: onLeadInputChange,
      reloadAllData,
      totalRows,
    }),
    [projectManager, sorting, totalRows],
  );

  const resizeTable = (el) => {
    if (el) {
      const topHeight = el.getBoundingClientRect().top;
      const totalHeight =
        window.screen.availHeight - (window.outerHeight - window.innerHeight);
      const height = totalHeight - topHeight - 20;
      ref.current.style.height = `${height}px`;
    }
  };

  useEffect(() => {
    Promise.all([loadLeads(), loadCounts(), loadStats()])
      .then(() => {
        resizeTable(leadTableRef.current);
        resizeObserver.current = new ResizeObserver((entries) => {
          if (entries.length > 0) {
            resizeTable(leadTableRef.current);
          }
        });
        resizeObserver.current.observe(document.getElementById('root'));
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      if (resizeObserver && resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isInitialRenderSearch.current) {
      isInitialRenderSearch.current = false;
      return;
    }
    clearTimeout(searchTimeout.current);
    if (search.length > 2 || search.length === 0) {
      searchTimeout.current = setTimeout(() => {
        setLoading(true);
        loadLeads(true);
      }, 500);
    }
  }, [search]);

  useEffect(() => {
    if (isInitialRenderProjectManager.current) {
      isInitialRenderProjectManager.current = false;
      return;
    }
    Promise.all([
      localStorage.setItem(
        'dashboard-project-manager-filter',
        projectManager ? projectManager.id : '',
      ),
      loadLeads(true),
      loadCounts(),
      loadStats(),
    ]);
  }, [projectManager]);

  useEffect(() => {
    if (isInitialRenderFilters.current) {
      isInitialRenderFilters.current = false;
      return;
    }
    loadLeads(true);
  }, [sorting]);

  useEffect(() => {
    if (isInitialRenderOffset.current) {
      isInitialRenderOffset.current = false;
      return;
    }
    loadLeads();
  }, [offset]);

  useEffect(() => {
    if (
      prevLeads &&
      prevLeads.current &&
      leads.length !== prevLeads.current.length &&
      leads.length > 0 &&
      prevLeads.current.length > 0 &&
      leads[0].id !== prevLeads.current[0].id
    ) {
      // eslint-disable-next-line no-console
      virtuoso.current.scrollToIndex({
        index: 0,
        align: 'start',
        behavior: 'auto',
      });
    }
  }, [leads]);

  return (
    <ThemeProvider>
      <Box mx={2} mb={2} id="lead-dashboard">
        <Box mb={2}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid
              item
              xs={user.is_admin_leader || user.is_external_accountant ? 7 : 12}
            >
              <BoxLoader loading={loadingCounts}>
                <Paper
                  sx={{
                    bgcolor: 'primary.lighter',
                    px: 2,
                    pt: 4,
                    pb: 1,
                    position: 'relative',
                  }}
                >
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      display: 'inline-block',
                      bgcolor: 'primary.light',
                      color: 'primary.contrastText',
                      fontSize: 10,
                      px: 1,
                      py: 0.3,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                      zIndex: 1000,
                    }}
                  >
                    leads en cours
                    {projectManager && (
                      <>
                        {' '}
                        pour {projectManager.first_name}{' '}
                        {projectManager.last_name}
                      </>
                    )}
                  </Typography>
                  <Grid container justifyContent="space-evenly">
                    <Grid item>
                      <NewTooltip content="En attente">
                        <Badge
                          badgeContent={counts ? counts.column1 : 0}
                          overlap="rectangular"
                          color="primary"
                          max={99999}
                          showZero
                        >
                          <Avatar
                            sx={{
                              bgcolor: 'primary.dark',
                            }}
                          >
                            <FontAwesomeIcon icon={faHourglassClock} />
                          </Avatar>
                        </Badge>
                      </NewTooltip>
                    </Grid>
                    <Grid item>
                      <NewTooltip content="À contacter">
                        <Badge
                          badgeContent={counts ? counts.column2 : 0}
                          overlap="rectangular"
                          color="error"
                          max={99999}
                          showZero
                        >
                          <Avatar
                            sx={{
                              bgcolor: 'primary.dark',
                            }}
                          >
                            <FontAwesomeIcon icon={faPhoneArrowUpRight} />
                          </Avatar>
                        </Badge>
                      </NewTooltip>
                    </Grid>
                    <Grid item>
                      <NewTooltip content="Devis à envoyer">
                        <Badge
                          badgeContent={counts ? counts.column3 : 0}
                          overlap="rectangular"
                          color="error"
                          max={99999}
                          showZero
                        >
                          <Avatar
                            sx={{
                              bgcolor: 'primary.dark',
                            }}
                          >
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Avatar>
                        </Badge>
                      </NewTooltip>
                    </Grid>
                    <Grid item>
                      <NewTooltip content="Offres envoyées">
                        <Badge
                          badgeContent={counts ? counts.column4 : 0}
                          overlap="rectangular"
                          color="primary"
                          max={99999}
                          showZero
                        >
                          <Avatar
                            sx={{
                              bgcolor: 'primary.dark',
                            }}
                          >
                            <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
                          </Avatar>
                        </Badge>
                      </NewTooltip>
                    </Grid>
                    <Grid item>
                      <NewTooltip content="En cours">
                        <Badge
                          badgeContent={
                            counts
                              ? counts.column1 +
                                counts.column2 +
                                counts.column3 +
                                counts.column4
                              : 0
                          }
                          color="primary"
                          max={99999}
                          showZero
                        >
                          <Avatar
                            sx={{
                              bgcolor: 'primary.dark',
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowProgress} />
                          </Avatar>
                        </Badge>
                      </NewTooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </BoxLoader>
            </Grid>
            {(user.is_admin_leader || user.is_external_accountant) && (
              <Grid item xs={5}>
                <BoxLoader loading={loadingCountByPm}>
                  <Paper
                    sx={{
                      bgcolor: 'secondary.lighter',
                      px: 2,
                      pt: 4,
                      pb: 1,
                      position: 'relative',
                    }}
                  >
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'inline-block',
                        bgcolor: 'secondary.light',
                        color: 'secondary.contrastText',
                        fontSize: 10,
                        px: 1,
                        py: 0.3,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        zIndex: 1000,
                      }}
                    >
                      ce mois-ci
                    </Typography>
                    <Grid container justifyContent="space-evenly">
                      {Object.entries(countLeadByPm).map(([key, value]) => (
                        <Grid item key={key}>
                          <NewTooltip content={value.full_name}>
                            <Badge
                              badgeContent={value.count}
                              overlap="rectangular"
                              color="secondary"
                              max={999}
                              showZero
                            >
                              {value.has_avatar ? (
                                <Avatar src={`${getApiUrl()}/avatar/${key}`} />
                              ) : (
                                <Avatar {...stringAvatar(value.full_name)} />
                              )}
                            </Badge>
                          </NewTooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </BoxLoader>
              </Grid>
            )}
          </Grid>
        </Box>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {/* <Grid item xs={(user.is_admin_leader ||
               user.is_external_accountant) ? 4 : 12}> */}
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              fullWidth
              id="search-client"
              label="Rechercher un lead (nom, ID, CP ou tel, 3 caractères min.)"
              name="search"
              value={search}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment:
                  search !== '' ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={clearSearch}
                        onMouseDown={clearSearch}
                        edge="end"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </Grid>
          <Grid item xs />
          <Grid item xs={3}>
            <FollowUpToggleButtonGroup
              leads={totalLeadsForBadges}
              loading={loading}
              view="follow_up"
            />
          </Grid>
          {/* <Grid item xs /> */}

          <Grid item xs>
            <NewTooltip
              content={
                <>
                  <Typography variant="body2">
                    <strong>R1</strong> : RDV téléphonique pour qualifications
                  </Typography>
                  <Typography variant="body2">
                    <strong>R2</strong> : Optionnel RDV téléphonique pré-VT
                  </Typography>
                  <Typography variant="body2">
                    <strong>VT</strong> : VT physique
                  </Typography>
                  <Typography variant="body2">
                    <strong>R3</strong> : RDV si pas de signature à la VT
                  </Typography>
                  <Typography variant="body2" align="center" sx={{ m: 1 }}>
                    <hr />
                  </Typography>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          bgcolor: '#FF0000',
                          borderRadius: '50%',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Leads en retard</Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          bgcolor: 'yellow',
                          borderRadius: '50%',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Leads du jour</Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          bgcolor: 'green',
                          borderRadius: '50%',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        Leads à traiter dans la semaine
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
              arrow
            >
              <Grid container alignItems="center">
                <FontAwesomeIcon icon={faCircleInfo} size="xl" color="green" />
                <Typography variant="body2" sx={{ ml: 0.8 }}>
                  Informations
                </Typography>
              </Grid>
            </NewTooltip>
          </Grid>
          {canSelectProjectManager && (
            <Grid item xs={2}>
              <CustomAutocomplete
                name="projectManager"
                label="Chargé de projet"
                value={projectManager}
                endpoint={(input) =>
                  `/users/?search=${
                    input === 0 || input === undefined ? '' : input
                  }&is_project_manager=True`
                }
                getOptionLabel={(o) => o.full_name || ''}
                onChange={(e, v) => handleProjectManagerChange(v)}
                allowNull
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <BoxLoader loading={loading}>
        <Box mx={2}>
          <Paper
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              px: 2,
              py: 1,
              fontWeight: 'medium',
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Typography
              sx={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
                textAlign: 'right',
              }}
            >
              {totalRows} leads affichés
            </Typography>
          </Paper>
        </Box>
        <Box mx={2} id="lead-table" ref={leadTableRef}>
          <DashboardContext.Provider value={dashboardContextData}>
            <Paper
              sx={{
                width: '100%',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
              ref={ref}
            >
              <TableVirtuoso
                data={leads}
                components={TableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={itemContent}
                overscan={{ main: 50, reverse: 50 }}
                fixedItemHeight={80}
                endReached={onTableEndReached}
                ref={virtuoso}
              />
            </Paper>
          </DashboardContext.Provider>
        </Box>
      </BoxLoader>
    </ThemeProvider>
  );
}

export default LeadFollowUpDashboard;
