/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedQuoteList } from '../models/PaginatedQuoteList';
import type { PatchedQuote } from '../models/PatchedQuote';
import type { Quote } from '../models/Quote';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuotesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedQuoteList
     * @throws ApiError
     */
    public quotesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedQuoteList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/quotes/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this quote.
         */
        id: number,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/quotes/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this quote.
         */
        id: number,
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/quotes/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this quote.
         */
        id: number,
        requestBody?: PatchedQuote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/quotes/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public quotesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this quote.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/quotes/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesExportRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this quote.
         */
        id: number,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/quotes/{id}/export/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesCreateAndSendCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/create_and_send/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handler method for HTTP 'OPTIONS' request.
     * @returns Quote
     * @throws ApiError
     */
    public quotesOptionsCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/options/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesPreviewCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/preview/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesSendQuotesCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/send_quotes/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quote
     * @throws ApiError
     */
    public quotesValidateCreate({
        requestBody,
    }: {
        requestBody: Quote,
    }): CancelablePromise<Quote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/quotes/validate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
