/** @format */

import chroma from 'chroma-js';

export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: `${Number(theme.shape.borderRadius)}px !important`,
        },
        filled: ({ ownerState }) => ({
          backgroundColor: chroma(
            theme.palette[ownerState.color || 'default'].main,
          )
            .alpha(0.3)
            .css(),
          color: theme.palette[ownerState.color || 'default'].dark,
          fontWeight: 'bold',
        }),
      },
    },
  };
}
