/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientInvoice } from '../models/ClientInvoice';
import type { PaginatedClientInvoiceList } from '../models/PaginatedClientInvoiceList';
import type { PatchedClientInvoice } from '../models/PatchedClientInvoice';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsInvoicesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedClientInvoiceList
     * @throws ApiError
     */
    public clientsInvoicesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedClientInvoiceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-invoices/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ClientInvoice
     * @throws ApiError
     */
    public clientsInvoicesCreate({
        requestBody,
    }: {
        requestBody: ClientInvoice,
    }): CancelablePromise<ClientInvoice> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-invoices/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientInvoice
     * @throws ApiError
     */
    public clientsInvoicesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this client invoice.
         */
        id: number,
    }): CancelablePromise<ClientInvoice> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-invoices/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientInvoice
     * @throws ApiError
     */
    public clientsInvoicesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client invoice.
         */
        id: number,
        requestBody: ClientInvoice,
    }): CancelablePromise<ClientInvoice> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-invoices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientInvoice
     * @throws ApiError
     */
    public clientsInvoicesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this client invoice.
         */
        id: number,
        requestBody?: PatchedClientInvoice,
    }): CancelablePromise<ClientInvoice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-invoices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsInvoicesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this client invoice.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-invoices/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
