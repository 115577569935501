/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadStatus } from '../models/LeadStatus';
import type { PaginatedLeadStatusList } from '../models/PaginatedLeadStatusList';
import type { PatchedLeadStatus } from '../models/PatchedLeadStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadStatusList
     * @throws ApiError
     */
    public leadsStatusList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLeadStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-status/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns LeadStatus
     * @throws ApiError
     */
    public leadsStatusCreate({
        requestBody,
    }: {
        requestBody: LeadStatus,
    }): CancelablePromise<LeadStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadStatus
     * @throws ApiError
     */
    public leadsStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead status.
         */
        id: number,
    }): CancelablePromise<LeadStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadStatus
     * @throws ApiError
     */
    public leadsStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead status.
         */
        id: number,
        requestBody: LeadStatus,
    }): CancelablePromise<LeadStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadStatus
     * @throws ApiError
     */
    public leadsStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead status.
         */
        id: number,
        requestBody?: PatchedLeadStatus,
    }): CancelablePromise<LeadStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lead status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
