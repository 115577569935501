/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRemoteSiteDeviceList } from '../models/PaginatedRemoteSiteDeviceList';
import type { PatchedRemoteSiteDevice } from '../models/PatchedRemoteSiteDevice';
import type { RemoteSiteDevice } from '../models/RemoteSiteDevice';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsRemoteSiteDevicesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedRemoteSiteDeviceList
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedRemoteSiteDeviceList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-remote-site-devices/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns RemoteSiteDevice
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesCreate({
        requestBody,
    }: {
        requestBody: RemoteSiteDevice,
    }): CancelablePromise<RemoteSiteDevice> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-remote-site-devices/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemoteSiteDevice
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this remote site device.
         */
        id: number,
    }): CancelablePromise<RemoteSiteDevice> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-remote-site-devices/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RemoteSiteDevice
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this remote site device.
         */
        id: number,
        requestBody: RemoteSiteDevice,
    }): CancelablePromise<RemoteSiteDevice> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-remote-site-devices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemoteSiteDevice
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this remote site device.
         */
        id: number,
        requestBody?: PatchedRemoteSiteDevice,
    }): CancelablePromise<RemoteSiteDevice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-remote-site-devices/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsRemoteSiteDevicesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this remote site device.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-remote-site-devices/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
