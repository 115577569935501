/**
 * @format
 */
/* eslint-disable react/jsx-props-no-spreading */

import { Tooltip } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { api } from '../../api.ts';
import CacheManager from '../../utils/cache';
import RequiredFieldAdornment from '../app/field/RequiredFieldAdornment';

const filter = createFilterOptions();

// TODO: Prevent useless API calls (mount/unmount)

function CustomAutocomplete({
  endpoint,
  getOptionLabel,
  name,
  label,
  onChange,
  onCreated,
  onFocus,
  value,
  size,
  required,
  disabled,
  autoLoad,
  allowNull,
  error,
  helperText,
  requiredFor,
  multiple,
  cache,
  creatable,
  tooltip,
  inputStyle,
}) {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let timeout = null;

  let cacheInstance = null;
  if (cache) {
    cacheInstance = CacheManager.instance.getModel(endpoint(''));
  }

  const loadOptions = () => {
    if (cache) {
      setOptions(cacheInstance.list());
      setLoading(false);
    }
    setLoading(true);
    return api
      .get(endpoint(input))
      .then(
        (result) =>
          new Promise((resolve) => {
            setOptions(result.data.results);
            setLoading(false);
            resolve();
          }),
      )
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const createOption = (option) => {
    setLoading(true);
    return api
      .post(endpoint(), {}, option)
      .then((result) => result)
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    if (!event) return;
    const { value: localValue } = event.target;
    setInput(localValue);
    setLoading(true);
    setOpen(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (localValue !== input) {
        loadOptions();
      }
    }, 500);
  };

  const handleChange = (event, newValue) => {
    if (creatable) {
      if (typeof newValue === 'string') {
        event.preventDefault();
        return null;
      }
      if (newValue && newValue.inputValue) {
        return createOption({
          name: newValue.inputValue,
          title: newValue.inputValue,
          label: newValue.inputValue,
        })
          .then((result) => (onChange ? onChange(event, result.data) : null))
          .then(() => (onCreated ? onCreated() : null));
      }
    }
    return onChange ? onChange(event, newValue) : null;
  };

  useEffect(() => {
    if (autoLoad) {
      loadOptions();
    }
  }, []);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
        setLoading(!autoLoad);
        if (!autoLoad) {
          loadOptions();
        }
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      isOptionEqualToValue={(o, v) => o.id === (cache ? v : v.id)}
      getOptionLabel={(o) => getOptionLabel(o) || ''}
      options={[...new Set(options)]}
      loading={loading}
      value={
        // eslint-disable-next-line no-nested-ternary
        multiple
          ? value.map((v) =>
              cacheInstance ? cacheInstance.get(v) : v || '',
            ) || []
          : cacheInstance
          ? cacheInstance.get(value) || ''
          : value || ''
      }
      onChange={handleChange}
      disabled={disabled}
      onInputChange={handleInputChange}
      noOptionsText="Aucun résultat trouvé"
      loadingText="Chargement..."
      size={size}
      filterOptions={
        creatable
          ? (opts, params) => {
              const filtered = filter(opts, params);
              if (creatable) {
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Ajouter « ${params.inputValue} »`,
                    name: `Ajouter « ${params.inputValue} »`,
                    label: `Ajouter « ${params.inputValue} »`,
                  });
                }
              }
              return filtered;
            }
          : undefined
      }
      disableClearable={!allowNull}
      multiple={multiple}
      freeSolo={creatable}
      blurOnSelect
      renderInput={(params) => (
        <Tooltip
          title={tooltip.content}
          placement={tooltip.placement || 'top'}
          disableFocusListener={tooltip.disabled}
          disableHoverListener={tooltip.disabled}
          disableTouchListener={tooltip.disabled}
          arrow
        >
          <TextField
            {...params}
            variant="outlined"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            value={input || ''}
            error={error}
            helperText={helperText}
            onFocus={onFocus}
            InputProps={{
              ...params.InputProps,
              style: inputStyle || null,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="default" size={20} />
                  ) : (
                    <>
                      {requiredFor && (
                        <RequiredFieldAdornment
                          type={value ? 'valid' : requiredFor}
                        />
                      )}
                    </>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </Tooltip>
      )}
    />
  );
}

CustomAutocomplete.propTypes = {
  endpoint: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onCreated: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.number]),
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoLoad: PropTypes.bool,
  allowNull: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  requiredFor: PropTypes.oneOf(['quote', 'study']),
  multiple: PropTypes.bool,
  cache: PropTypes.bool,
  creatable: PropTypes.bool,
  tooltip: PropTypes.shape(),
  inputStyle: PropTypes.shape(),
};

CustomAutocomplete.defaultProps = {
  name: null,
  label: null,
  onChange: null,
  onCreated: null,
  onFocus: null,
  value: null,
  size: 'medium',
  disabled: false,
  required: false,
  autoLoad: false,
  allowNull: false,
  error: false,
  helperText: null,
  requiredFor: null,
  multiple: false,
  cache: false,
  creatable: false,
  tooltip: {
    content: '',
    placement: '',
    disabled: true,
  },
  inputStyle: null,
};

export default CustomAutocomplete;
