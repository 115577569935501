/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadSite } from '../models/LeadSite';
import type { PaginatedLeadSiteList } from '../models/PaginatedLeadSiteList';
import type { PatchedLeadSite } from '../models/PatchedLeadSite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeadsSitesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedLeadSiteList
     * @throws ApiError
     */
    public leadsSitesList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLeadSiteList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesCreate({
        requestBody,
    }: {
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leads-sites/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody?: PatchedLeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/leads-sites/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSitesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-sites/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesAddImagesCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/add_images/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesCreateAfterSaleVisitCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/create_after_sale_visit/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesCreateTechnicalVisitCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/create_technical_visit/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public leadsSitesDeleteImageDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leads-sites/{id}/delete_image/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesGetHorizonRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/get_horizon/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesJobListRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/job_list/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesLandRegisterFileRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/land_register_file/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesNoteListRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/note_list/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesRelatedClientsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/related_clients/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesRelatedClientsProductsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/related_clients_products/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesRelatedQuotesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leads-sites/{id}/related_quotes/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesSaveToBigchangeCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/save_to_bigchange/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesUpdateOwnTechnicalVisitCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/update_own_technical_visit/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LeadSite
     * @throws ApiError
     */
    public leadsSitesUploadAttachmentsCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this lead site.
         */
        id: number,
        requestBody: LeadSite,
    }): CancelablePromise<LeadSite> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leads-sites/{id}/upload_attachments/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
