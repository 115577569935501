/** @format */

import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { api } from '../../../api.ts';
import { ThemeProvider } from '../../../theme';
import BetaBadge from '../../misc/BetaBadge';
import BoxLoader from '../../misc/BoxLoader';
import CustomAutocomplete from '../../misc/CustomAutocomplete';

function MessageQueueDashboard() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const generateResetLink = () => {
    if (!selectedUser) return;
    api
      .post(`/users/${selectedUser.id}/generate_customer_area_reset_link/`)
      .then((response) => {
        console.log(response);
        enqueueSnackbar('Le lien a bien été envoyé', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar("Une erreur est survenue lors de l'envoi du lien", {
          variant: 'error',
        });
      });
  };

  return (
    <ThemeProvider>
      <Container>
        <Card>
          <CardHeader
            title={
              <BetaBadge>
                <span>
                  Créer des identifiants admin pour l&apos;espace client
                </span>
              </BetaBadge>
            }
          />
          <CardContent>
            <BoxLoader loading={loading}>
              <Paper>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <CustomAutocomplete
                      name="user"
                      label="Utilisateur Manywatt"
                      value={selectedUser}
                      endpoint={(input) =>
                        `/users/?search=${
                          input === 0 || input === undefined ? '' : input
                        }`
                      }
                      getOptionLabel={(o) =>
                        o
                          ? `${o.full_name} (${o.email})`
                          : 'Sélectionnez un utilisateur...'
                      }
                      onChange={(e, v) => setSelectedUser(v)}
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!selectedUser}
                      onClick={generateResetLink}
                      fullWidth
                    >
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{
                          paddingRight: '10px',
                        }}
                      />
                      Envoyer un lien de réinitialisation
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </BoxLoader>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}

export default MessageQueueDashboard;
