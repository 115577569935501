/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElecInstallPhotoStatus } from '../models/ElecInstallPhotoStatus';
import type { PaginatedElecInstallPhotoStatusList } from '../models/PaginatedElecInstallPhotoStatusList';
import type { PatchedElecInstallPhotoStatus } from '../models/PatchedElecInstallPhotoStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsElecInstallPhotoStatusService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedElecInstallPhotoStatusList
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedElecInstallPhotoStatusList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-elec-install-photo-status/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ElecInstallPhotoStatus
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusCreate({
        requestBody,
    }: {
        requestBody: ElecInstallPhotoStatus,
    }): CancelablePromise<ElecInstallPhotoStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients-elec-install-photo-status/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElecInstallPhotoStatus
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this elec install photo status.
         */
        id: number,
    }): CancelablePromise<ElecInstallPhotoStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients-elec-install-photo-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ElecInstallPhotoStatus
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this elec install photo status.
         */
        id: number,
        requestBody: ElecInstallPhotoStatus,
    }): CancelablePromise<ElecInstallPhotoStatus> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/clients-elec-install-photo-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ElecInstallPhotoStatus
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this elec install photo status.
         */
        id: number,
        requestBody?: PatchedElecInstallPhotoStatus,
    }): CancelablePromise<ElecInstallPhotoStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients-elec-install-photo-status/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clientsElecInstallPhotoStatusDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this elec install photo status.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients-elec-install-photo-status/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
