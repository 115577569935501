/** @format */

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLineUp,
  faCalendarAlt,
  faPhoneAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import { useNavigate } from 'react-router';
import { api } from '../../../api.ts';
import { parseFieldError } from '../../../utils/errors';
import BoxLoader from '../../misc/BoxLoader';
import MultiDropzone from '../../misc/MultiDropzone';
import CustomAutocomplete from '../../misc/CustomAutocomplete';
import { MEETING_TYPE_CHOICES } from '../../../utils/constants';

function FollowUpDialog({ lead, open, onClose, action, onChange, section }) {
  const firstLeadSite =
    lead && lead.sites && lead.sites.length === 1 ? lead.sites[0] : null;

  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState(lead.email);
  const [pmMail, setPmMail] = useState('');
  const [adminMail, setAdminMail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [meetingDate, setMeetingDate] = useState('');
  const [meetingTime, setMeetingTime] = useState('');
  const [isSMS, setIsSMS] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const [selectedSite, setSelectedSite] = useState(firstLeadSite);
  const [selectedJobType, setSelectedJobType] = useState('');
  const [actionComment, setActionComment] = useState('');
  const [actionFiles, setActionFiles] = useState([]);
  const [editingJob, setEditingJob] = useState(null);
  const [jobTypes, setJobTypes] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const loadTemplateMessage = () => {
    setLoading(true);
    api
      .get(`follow-up/${lead.id}/get_follow_up_message_content`)
      .then((result) => {
        setSubject(result.data.subject);
        setBody(result.data.body);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const loadMails = () => {
    setLoading(true);
    api
      .get('/user-configs/')
      .then((result) => {
        if (result.data.results[0]) {
          setAdminMail(result.data.results[0].admin_user.email);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        const { state, message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setFieldErrors(state || {});
        setLoading(false);
      });
  };

  const messageMapping = {
    is_contact: {
      1: 'FOLLOW_UP_M1_1',
      2: 'FOLLOW_UP_S1_1',
      3: 'FOLLOW_UP_S1_2',
      5: 'FOLLOW_UP_M1_2',
    },
    is_follow_up_r1: {
      1: 'FOLLOW_UP_M2_1',
      2: 'FOLLOW_UP_S2_1',
      3: 'FOLLOW_UP_S2_2',
      5: 'FOLLOW_UP_M2_2',
    },
    is_follow_up_r2: {
      1: 'FOLLOW_UP_M3_1',
      2: 'FOLLOW_UP_S3_1',
      3: 'FOLLOW_UP_S3_2',
      5: 'FOLLOW_UP_M3_2',
    },
    is_follow_up_r3: {
      1: 'FOLLOW_UP_M4_1',
      2: 'FOLLOW_UP_S4_1',
      3: 'FOLLOW_UP_S4_2',
      5: 'FOLLOW_UP_M4_2',
    },
  };

  function setMessageType() {
    let status = null;
    if (
      lead.current_follow_up_status &&
      lead.current_follow_up_status.is_contact
    ) {
      status = 'is_contact';
    } else if (
      lead.current_follow_up_status &&
      (lead.current_follow_up_status.is_follow_up_r1 ||
        lead.current_follow_up_status.is_confirmation_r1)
    ) {
      status = 'is_follow_up_r1';
    } else if (
      lead.current_follow_up_status &&
      (lead.current_follow_up_status.is_follow_up_r2 ||
        lead.current_follow_up_status.is_confirmation_r2)
    ) {
      status = 'is_follow_up_r2';
    } else if (
      lead.current_follow_up_status &&
      (lead.current_follow_up_status.is_follow_up_r3 ||
        lead.current_follow_up_status.is_confirmation_r3)
    ) {
      status = 'is_follow_up_r3';
    }

    const callNumber = lead.follow_up_call.call_number;
    if (messageMapping[status] && messageMapping[status][callNumber]) {
      const messageType = messageMapping[status][callNumber];
      if (messageType.startsWith('FOLLOW_UP_S')) {
        setIsSMS(true);
        setIsEmail(false);
      } else if (messageType.startsWith('FOLLOW_UP_M')) {
        setIsEmail(true);
        setIsSMS(false);
      }
    }
  }

  const handleOpenEmail = () => {
    setTo(lead.email);
    setFieldErrors({});
    loadMails();
  };

  const handleOpenSMS = () => {
    setTo(lead.phone_1);
    setFieldErrors({});
  };

  useEffect(() => {
    if (open && section === 'message') {
      setMessageType();
      if (isEmail) {
        handleOpenEmail();
      } else if (isSMS) {
        handleOpenSMS();
      }
      loadTemplateMessage();
    }
  }, [open, action, isEmail, isSMS]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setMeetingDate('');
      setMeetingTime('');
      setTo(lead.email);
      setSubject('');
      setBody('');
      setFieldErrors({});
      setActionComment('');
      setSelectedJobType('');
      setActionFiles([]);
      setEditingJob(null);
      setFieldErrors({});
    }, 500);
  };

  const updateFollowUpData = (result, isSetR2 = false) => {
    setLoading(true);
    const data = {
      lead: lead.id,
      action: section === 'message' ? 'voicemail' : 'communication',
      result,
      is_set_r2: isSetR2,
      call_date: new Date().toISOString(),
      meeting_date: moment(`${meetingDate}T${meetingTime}:00`).toDate(),
    };
    api
      .post(`/follow-up/${lead.id}/call_follow_up/`, {}, data)
      .then(() => {
        setLoading(false);
        handleClose();
        if (onChange) {
          onChange();
        }
        enqueueSnackbar('Les information ont été mise à jour', {
          variant: 'success',
        });
        if (section === 'calendar' || section === 'technicalVisitCalendar') {
          enqueueSnackbar(
            "L'e-mail de confirmation de RDV a bien été envoyé.",
            {
              variant: 'success',
            },
          );
        }
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
        setFieldErrors(state || {});
        setLoading(false);
      });
  };

  const confirmMeeting = (
    isConfirmed,
    hasPlannedNextMeeting,
    hasSigned,
    isSetR2 = false,
  ) => {
    setLoading(true);
    const data = {
      lead: lead.id,
      is_confirmed: isConfirmed,
      has_planned_next_meeting: hasPlannedNextMeeting,
      has_signed: hasSigned,
      is_set_r2: isSetR2,
      meeting_date: moment(`${meetingDate}T${meetingTime}:00`).toDate(),
    };

    api
      .post(`/follow-up/${lead.id}/confirm_meeting/`, {}, data)
      .then(() => {
        setLoading(false);
        handleClose();
        if (onChange) {
          onChange();
        }
        enqueueSnackbar('Les informations ont été mises à jour', {
          variant: 'success',
        });
        if (section === 'calendar' || section === 'technicalVisitCalendar') {
          enqueueSnackbar(
            "L'e-mail de confirmation de RDV a bien été envoyé.",
            {
              variant: 'success',
            },
          );
        }
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        enqueueSnackbar(
          message ?? 'Impossible de mettre à jour les informations',
          {
            variant: 'error',
          },
        );
        setFieldErrors(state || {});
        setLoading(false);
      });
  };

  const sendFollowUpMessage = () => {
    if (loading) return;
    if (isEmail && (!to || !subject || !body)) {
      enqueueSnackbar("Les champs obligatoires n'ont pas été remplis !", {
        variant: 'error',
      });
      return;
    }
    if (isSMS && (!to || !body)) {
      enqueueSnackbar("Les champs obligatoires n'ont pas été remplis !", {
        variant: 'error',
      });
      return;
    }
    setLoading(true);
    let message = null;
    if (isEmail) {
      message = 'mail_sent';
    } else if (isSMS) {
      message = 'text_message_sent';
    }
    const data = {
      to,
      subject,
      body,
      copy: [pmMail, adminMail],
      action: message,
    };
    api
      .post(`/follow-up/${lead.id}/send_follow_up_message/`, {}, data)
      .then(() => {
        updateFollowUpData('no_response');
        setLoading(false);
        enqueueSnackbar(
          isEmail ? "L'e-mail a bien été envoyé." : 'Le SMS a bien été envoyé.',
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        const { state, errorMessage } = parseFieldError(err);
        enqueueSnackbar(errorMessage ?? "Impossible d'envoyer ce message.", {
          variant: 'error',
        });
        setFieldErrors(state || {});
        setLoading(false);
      });
  };

  // TECHNICAL VISIT

  /**
   * Fetch job types
   */
  const updateJobTypes = () => {
    setLoading(true);
    api
      .get('/bigchange-job-types/')
      .then((result) => {
        let localJobTypes = [];
        if (Array.isArray(result.data)) {
          localJobTypes = result.data.filter((jobType) =>
            jobType.label.startsWith('Visite '),
          );
        }
        setSelectedJobType(localJobTypes[2].label);
        setJobTypes(localJobTypes);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
      });
  };

  useEffect(() => {
    if (section === 'technicalVisitCalendar') {
      updateJobTypes();
    }
  }, [section, open]);

  /**
   * Upload attachment
   * @param type {string} Attachment type
   * @param id {number} Attachment id
   * @param file {File} File to upload
   * @returns {Promise<AxiosResponse<any> | void>|null} API response
   */
  const uploadAttachment = (type, id, file) => {
    const attachmentBody = new FormData();
    attachmentBody.append('type', type);
    attachmentBody.append('id', id);
    attachmentBody.append('file', file);
    return api.post(
      `/leads-sites/${selectedSite.id}/upload_attachments/`,
      {},
      attachmentBody,
      { 'Content-Type': 'multipart/form-data' },
    );
  };

  /**
   * Create technical visit job
   * @param internalType {string} Job internal type, can be 'project_manager' or 'technician'
   * @param comment {string} Job comment
   * @param type {number} Job type id
   * @param date {string} Job date, format DD/MM/YYYY
   * @param hour {string} Job hour, format HH:mm
   * @param quote {number} Job quote
   * @param files {Array} Job files
   * @param projectManagerPresence {boolean} Project manager presence
   * @param priority {boolean} Job priority
   * @param update {boolean} Update job
   */
  const createTechnicalVisit = (
    internalType,
    comment,
    type,
    date,
    hour,
    quote,
    files,
    projectManagerPresence = false,
    priority = false,
    update = false,
  ) => {
    if (!selectedSite) return;
    setFieldErrors({});
    setLoading(true);
    const isProjectManager = internalType === 'project_manager';
    const isTechnician = internalType === 'technician';
    const technicalVisitBody = {
      comment,
      type,
      // date: isProjectManager
      //   ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      //   : null,
      date: isProjectManager
        ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null,
      hour: isProjectManager ? moment(hour, 'HH:mm').format('HH:mm') : null,
      quote: isTechnician ? quote && quote.id : null,
      project_manager_presence: isTechnician ? projectManagerPresence : null,
      priority: isTechnician ? priority : null,
      internal_type: internalType,
      selected_client: null,
      is_follow_up: true,
    };
    if (update) {
      delete technicalVisitBody.type;
      technicalVisitBody.job_id = lead.follow_up_meeting.bc_job_id;
    }
    const endpoint = update
      ? `/leads-sites/${selectedSite.id}/update_own_technical_visit/`
      : `/leads-sites/${selectedSite.id}/create_technical_visit/`;
    api
      .post(endpoint, {}, technicalVisitBody)
      .then((result) => {
        const promises = files.map((file) =>
          uploadAttachment(
            'job',
            update ? lead.follow_up_meeting.bc_job_id : result.data.jobid,
            file,
          ),
        );
        return Promise.all(promises);
      })
      .then(() => {
        enqueueSnackbar(
          `Une visite technique a bien été ${
            date && hour ? 'programmée' : 'demandée'
          }.`,
          { variant: 'success' },
        );
        handleClose();
        if (onChange) {
          onChange();
        }
      })
      .catch((err) => {
        const { state, message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        loading(false);
        setFieldErrors(state || {});
      });
  };

  const isMeetingConfirmation =
    lead.current_follow_up_status?.is_confirmation_r1 ||
    lead.current_follow_up_status?.is_confirmation_r2 ||
    lead.current_follow_up_status?.is_confirmation_r3 ||
    lead.current_follow_up_status?.is_r1_completed ||
    lead.current_follow_up_status?.is_confirmation_technical_visit;

  const handleValidation = () => {
    if (section === 'calendar' && (!meetingDate || !meetingTime)) {
      enqueueSnackbar("Les champs obligatoires n'ont pas été remplis !", {
        variant: 'error',
      });
      return;
    }
    if (
      section === 'technicalVisitCalendar' &&
      (!selectedSite || !selectedJobType || !meetingDate || !meetingTime)
    ) {
      enqueueSnackbar("Les champs obligatoires n'ont pas été remplis !", {
        variant: 'error',
      });
      return;
    }

    const firstRequest = new Promise((resolve, reject) => {
      if (isMeetingConfirmation) {
        if (
          (lead.current_follow_up_status.is_confirmation_r1 ||
            lead.current_follow_up_status.is_r1_completed) &&
          section === 'calendar'
        ) {
          confirmMeeting(true, true, false, true);
        } else {
          confirmMeeting(true, true, false);
        }
        resolve();
      } else {
        if (section === 'calendar') {
          updateFollowUpData('scheduled', true);
        } else {
          updateFollowUpData('scheduled');
        }
        resolve();
      }
    });

    firstRequest.then(() => {
      // if (
      //   (lead.follow_up_meeting?.meeting_type === 'r2' &&
      //     !isMeetingConfirmation) ||
      //   (isMeetingConfirmation && lead.follow_up_meeting?.meeting_type === 'r1')
      //
      // ) {
      if (section === 'technicalVisitCalendar') {
        if (!selectedSite) return;
        createTechnicalVisit(
          'project_manager',
          actionComment,
          selectedJobType,
          meetingDate,
          meetingTime,
          null,
          actionFiles,
          false,
          false,
          lead.follow_up_meeting.bc_job_id !== null,
        );
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      scroll="paper"
      fullWidth
    >
      <DialogTitle
        sx={{
          backgroundColor: '#408B6A66',
          color: '#000',
          padding: '1em',
          borderRadius: '4px',
          textAlign: 'center',
        }}
      >
        Suivi du lead
      </DialogTitle>

      <DialogContent dividers>
        <BoxLoader loading={loading} whiteBackground>
          <Grid container justifyContent="center" spacing={2}>
            {/* <Grid item xs={12}> */}
            {/*  <Box */}
            {/*    display="flex" */}
            {/*    alignItems="center" */}
            {/*    justifyContent="space-evenly" */}
            {/*  > */}
            {/*    <Box display="flex" alignItems="center"> */}
            {/*      <FontAwesomeIcon */}
            {/*        icon={faChartLineUp} */}
            {/*        style={{ color: 'green' }} */}
            {/*      /> */}
            {/*      <Typography variant="subtitle1" sx={{ ml: 1 }}> */}
            {/*        {lead.current_follow_up_status?.name} */}
            {/*      </Typography> */}
            {/*    </Box> */}
            {/*    {lead.follow_up_meeting && ( */}
            {/*      <Box display="flex" alignItems="center" sx={{ ml: 2 }}> */}
            {/*        <FontAwesomeIcon */}
            {/*          icon={faCalendarAlt} */}
            {/*          style={{ color: 'indianred' }} */}
            {/*        /> */}
            {/*        <Typography variant="subtitle2" sx={{ ml: 1 }}> */}
            {/*          { */}
            {/*            MEETING_TYPE_CHOICES[ */}
            {/*              lead.follow_up_meeting.meeting_type */}
            {/*            ] */}
            {/*          } */}
            {/*          {lead.follow_up_meeting.meeting_type !== */}
            {/*            'technical_visit' && */}
            {/*            `.${lead.follow_up_meeting.meeting_number}`} */}
            {/*        </Typography> */}
            {/*      </Box> */}
            {/*    )} */}
            {/*    {lead.follow_up_meeting?.meeting_type !== 'technical_visit' && ( */}
            {/*      <Box display="flex" alignItems="center" sx={{ ml: 2 }}> */}
            {/*        <FontAwesomeIcon */}
            {/*          icon={faPhoneAlt} */}
            {/*          style={{ color: 'deepskyblue' }} */}
            {/*        /> */}
            {/*        <Typography variant="subtitle2" sx={{ ml: 1 }}> */}
            {/*          {lead.follow_up_call?.call_number} */}
            {/*        </Typography> */}
            {/*      </Box> */}
            {/*    )} */}
            {/*  </Box> */}
            {/* </Grid> */}

            {section === 'calendar' && (
              <>
                <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                  Date et heure du RDV
                </Typography>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Date du RDV"
                        format="DD/MM/YYYY"
                        value={moment(meetingDate)}
                        required
                        allowSameDateSelection
                        onChange={(newValue) =>
                          setMeetingDate(newValue.format('YYYY-MM-DD'))
                        }
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            size: 'small',
                            error: Boolean(fieldErrors.dateError),
                            helperText: fieldErrors.dateError,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        label="Heure du RDV"
                        format="HH:mm"
                        value={moment(meetingTime, 'HH:mm')}
                        required
                        ampm={false}
                        onChange={(newValue) =>
                          setMeetingTime(newValue.format('HH:mm'))
                        }
                        minTime={moment('07:00', 'HH:mm')}
                        maxTime={moment('20:00', 'HH:mm')}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            size: 'small',
                            error: Boolean(fieldErrors.hourError),
                            helperText: fieldErrors.hourError,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {section === 'technicalVisitCalendar' && (
              <>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    <AlertTitle>
                      <strong>Attention</strong>
                    </AlertTitle>
                    Votre lead va recevoir un e-mail de confirmation, il est
                    important qu&apos;il clique sur le lien à l&apos;intérieur
                    pour confirmer sa présence.
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete
                    name="site"
                    label="Choix du site concerné"
                    value={selectedSite}
                    required
                    endpoint={(input) =>
                      `/leads-sites/?search=${
                        input === 0 || input === undefined ? '' : input
                      }&lead=${lead.id}&page_size=20&public=True`
                    }
                    getOptionLabel={(o) =>
                      o === '' ? null : `${o.address_1} ${o.zip_code} ${o.city}`
                    }
                    onChange={(e, v) => setSelectedSite(v)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <TextField
                      select
                      label="Type de mission"
                      fullWidth
                      required
                      id="job-type"
                      value={selectedJobType}
                      error={Boolean(fieldErrors.typeError)}
                      helperText={fieldErrors.typeError}
                      onChange={(e) => setSelectedJobType(e.target.value)}
                      size="small"
                      disabled={Boolean(editingJob)}
                    >
                      {jobTypes.map((jobType) => (
                        <MenuItem value={jobType.label} key={jobType.id}>
                          {jobType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <TextField
                      label="Description"
                      fullWidth
                      multiline
                      rows={4}
                      value={actionComment}
                      error={Boolean(fieldErrors.commentError)}
                      helperText={fieldErrors.commentError}
                      onChange={(e) => setActionComment(e.target.value)}
                      size="small"
                      inputProps={{
                        maxLength: 1000,
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Date du RDV"
                        format="DD/MM/YYYY"
                        value={moment(meetingDate)}
                        allowSameDateSelection
                        required
                        onChange={(newValue) =>
                          setMeetingDate(newValue.format('YYYY-MM-DD'))
                        }
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            size: 'small',
                            error: Boolean(fieldErrors.dateError),
                            helperText: fieldErrors.dateError,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        label="Heure du RDV"
                        format="HH:mm"
                        value={moment(meetingTime, 'HH:mm')}
                        required
                        ampm={false}
                        onChange={(newValue) =>
                          setMeetingTime(newValue.format('HH:mm'))
                        }
                        minTime={moment('07:00', 'HH:mm')}
                        maxTime={moment('20:00', 'HH:mm')}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            size: 'small',
                            error: Boolean(fieldErrors.hourError),
                            helperText: fieldErrors.hourError,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <MultiDropzone
                      type="file"
                      onFileChange={setActionFiles}
                      selectedFiles={actionFiles}
                    />
                  </Paper>
                </Grid>
              </>
            )}

            {section === 'message' && (
              <>
                {isSMS && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required={isSMS}
                      fullWidth
                      id="to"
                      label="Destinataire"
                      name="to"
                      value={to}
                      onChange={(event) => setTo(event.target.value)}
                      error={Boolean(fieldErrors.toError)}
                      helperText={fieldErrors.toError}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {isEmail && (
                    <>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required={isEmail}
                            fullWidth
                            id="to"
                            type="email"
                            label="Destinataire"
                            name="to"
                            value={to}
                            onChange={(event) => setTo(event.target.value)}
                            error={Boolean(fieldErrors.toError)}
                            helperText={fieldErrors.toError}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="pmMail"
                            type="email"
                            label="Chargé de projet (copie cachée)"
                            name="pmMail"
                            value={pmMail}
                            onChange={(event) => setPmMail(event.target.value)}
                            error={Boolean(fieldErrors.pmMailError)}
                            helperText={fieldErrors.pmMailError}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="adminMail"
                            type="email"
                            label="Administration (copie cachée)"
                            name="adminMail"
                            value={adminMail}
                            onChange={(event) =>
                              setAdminMail(event.target.value)
                            }
                            error={Boolean(fieldErrors.adminMailError)}
                            helperText={fieldErrors.adminMailError}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <TextField
                          variant="outlined"
                          required={isEmail}
                          fullWidth
                          id="subject"
                          label="Sujet"
                          name="subject"
                          value={subject}
                          onChange={(event) => setSubject(event.target.value)}
                          error={Boolean(fieldErrors.subjectError)}
                          helperText={fieldErrors.subjectError}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={body}
                    onChange={(event, editor) => setBody(editor.getData())}
                    error={Boolean(fieldErrors.bodyError)}
                    helperText={fieldErrors.bodyError}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </BoxLoader>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              color="inherit"
            >
              Annuler
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={
                section === 'message' ? sendFollowUpMessage : handleValidation
              }
            >
              {section === 'message' ? 'Envoyer' : 'Valider'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

FollowUpDialog.propTypes = {
  lead: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    phone_1: PropTypes.string.isRequired,
    current_follow_up_status: PropTypes.shape({
      name: PropTypes.string.isRequired,
      is_contact: PropTypes.bool.isRequired,
      is_follow_up_r1: PropTypes.bool.isRequired,
      is_follow_up_r2: PropTypes.bool.isRequired,
      is_follow_up_r3: PropTypes.bool.isRequired,
      is_confirmation_r1: PropTypes.bool.isRequired,
      is_confirmation_r2: PropTypes.bool.isRequired,
      is_confirmation_r3: PropTypes.bool.isRequired,
      is_confirmation_technical_visit: PropTypes.bool.isRequired,
      is_r1_completed: PropTypes.bool.isRequired,
    }).isRequired,
    follow_up_meeting: PropTypes.shape({
      meeting_type: PropTypes.string.isRequired,
      meeting_number: PropTypes.number.isRequired,
      bc_job_id: PropTypes.number,
    }).isRequired,
    follow_up_call: PropTypes.shape({
      call_number: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string,
  onChange: PropTypes.func,
  section: PropTypes.string,
};

FollowUpDialog.defaultProps = {
  action: null,
  onChange: null,
  section: null,
};

export default FollowUpDialog;
