/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductInverterAccessoryList } from '../models/PaginatedProductInverterAccessoryList';
import type { PatchedProductInverterAccessory } from '../models/PatchedProductInverterAccessory';
import type { ProductInverterAccessory } from '../models/ProductInverterAccessory';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsInverterAccessoriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedProductInverterAccessoryList
     * @throws ApiError
     */
    public productsInverterAccessoriesList({
        page,
        pageSize,
        search,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductInverterAccessoryList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-inverter-accessories/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns ProductInverterAccessory
     * @throws ApiError
     */
    public productsInverterAccessoriesCreate({
        requestBody,
    }: {
        requestBody: ProductInverterAccessory,
    }): CancelablePromise<ProductInverterAccessory> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products-inverter-accessories/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductInverterAccessory
     * @throws ApiError
     */
    public productsInverterAccessoriesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product inverter accessory.
         */
        id: number,
    }): CancelablePromise<ProductInverterAccessory> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products-inverter-accessories/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductInverterAccessory
     * @throws ApiError
     */
    public productsInverterAccessoriesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product inverter accessory.
         */
        id: number,
        requestBody: ProductInverterAccessory,
    }): CancelablePromise<ProductInverterAccessory> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products-inverter-accessories/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductInverterAccessory
     * @throws ApiError
     */
    public productsInverterAccessoriesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product inverter accessory.
         */
        id: number,
        requestBody?: PatchedProductInverterAccessory,
    }): CancelablePromise<ProductInverterAccessory> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products-inverter-accessories/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public productsInverterAccessoriesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product inverter accessory.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products-inverter-accessories/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
