/** @format */

import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/700.css';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider as ProLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import FlagProvider from '@unleash/proxy-client-react';
import Lottie from 'lottie-react';
import React from 'react';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import sun from './assets/animations/sun.json';
import './assets/css/honeybadger.css';
import Main from './components/Main';
import ErrorComponent from './components/misc/ErrorComponent';
import { store } from './redux/_store';
import { mainTheme } from './themes';
import CacheManager from './utils/cache';
import ManywattContext from './utils/context';

CacheManager.instance.addModel('leads-status', 'status');
CacheManager.instance.addModel(
  'leads-electricity-distributors',
  'electricitydistributor',
);
CacheManager.instance.addModel('leads-provenances', 'provenance');
CacheManager.instance.addModel(
  'leads-business-introducers',
  'businessintroducer',
);
CacheManager.instance.addModel(
  'clients-construction-hyper-types',
  'constructionhypertype',
);
CacheManager.instance.addModel(
  'clients-construction-types',
  'constructiontype',
);
CacheManager.instance.addModel(
  'clients-construction-sub-types',
  'constructionsubtype',
);
CacheManager.instance.addModel(
  'clients-construction-status',
  'constructionstatus',
);
CacheManager.instance.addModel('clients-check-sent-status', 'checksentstatus');
CacheManager.instance.addModel('clients-consuel-status', 'consuelstatus');
CacheManager.instance.addModel(
  'clients-prior-request-status',
  'priorrequeststatus',
);
CacheManager.instance.addModel(
  'clients-edf-oa-contract-action-status',
  'edfoacontractactionstatus',
);
// CacheManager.instance.addModel('clients-edf-oa-mandate-status', 'edfoamandatestatus');
CacheManager.instance.addModel('clients-elios-types', 'eliostype');
CacheManager.instance.addModel('clients-enedis-status', 'enedisstatus');
// CacheManager.instance.addModel('clients-paid-invoice-status', 'paidinvoicestatus');
CacheManager.instance.addModel(
  'clients-google-review-request-status',
  'googlereviewrequeststatus',
);
CacheManager.instance.addModel('clients-mandate-status', 'mandatestatus');
CacheManager.instance.addModel(
  'clients-layout-photo-status',
  'layoutphotostatus',
);
CacheManager.instance.addModel(
  'clients-elios-photo-status',
  'eliosphotostatus',
);
CacheManager.instance.addModel(
  'clients-elec-install-photo-status',
  'elecinstallphotostatus',
);
CacheManager.instance.addModel(
  'clients-install-photo-status',
  'installphotostatus',
);
CacheManager.instance.addModel('clients-photo-status', 'photostatus');
CacheManager.instance.addModel(
  'clients-signed-transcript-status',
  'signedtranscriptstatus',
);
CacheManager.instance.addModel('clients-receipt-status', 'receiptstatus');
CacheManager.instance.addModel(
  'clients-references-contact-status',
  'referencescontactstatus',
);
CacheManager.instance.addModel('clients-abf-status', 'abfstatus');
CacheManager.instance.addModel('clients-refusal-types', 'refusaltype');
CacheManager.instance.addModel('clients-appeal-types', 'appealtype');
CacheManager.instance.addModel(
  'clients-solar-edge-management-alert-status',
  'solaredgemanagementalertstatus',
);
CacheManager.instance.addModel('clients-planning-status', 'planningstatus');
CacheManager.instance.addModel('clients-invoice-status', 'invoicestatus');
CacheManager.instance.addModel('users', 'user');

/* Fix for Google Maps default font */
const head = document.getElementsByTagName('head')[0];
const { insertBefore } = head;
head.insertBefore = (newElement, referenceElement) => {
  if (
    newElement.href &&
    newElement.href.indexOf(
      'https://fonts.googleapis.com/css?family=Roboto',
    ) === 0
  ) {
    return;
  }
  insertBefore.call(head, newElement, referenceElement);
};

const resizeErrors = [
  'ResizeObserver loop completed with undelivered notifications.', // Firefox
  'ResizeObserver loop limit exceeded', // Chrome
  'ResizeObserver loop completed with undelivered notifications.', // Safari
];

window.addEventListener('error', (e) => {
  if (resizeErrors.includes(e.message)) {
    e.stopImmediatePropagation();
  }
});

const environment = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT.replace('web-', '')
  : 'development';
const unleashConfig = {
  url: `https://unleash.manywatt.com/${environment}/proxy`,
  appName: environment,
  clientKey: 'iJ4qB4bM1yF1cQ6l',
  refreshInterval: 60,
  disableMetrics: true,
};

const honeybadger = Honeybadger.configure({
  apiKey: 'hbp_ZsWqGMEgoRHtwLQ8cUZa4WY6tcnVVq41OJOb',
  environment: process.env.REACT_APP_ENVIRONMENT || 'web-development',
  revision: process.env.REACT_APP_VERSION || '0.0.0-snapshot',
  developmentEnvironments: ['development', 'web-development', 'web-testing'],
  paramsFilters: ['password', 'password_confirmation', 'passwordConfirmation'],
});

honeybadger.afterNotify((err, notice) => {
  honeybadger.showUserFeedbackForm({
    messages: {
      heading: 'Feedback utilisateur',
      explanation: "Aidez-nous à comprendre ce qu'il s'est passé.",
      thanks:
        'Merci pour votre précieux retour !\n\nVous pouvez maintenant actualiser la page.',
    },
    buttons: {
      submit: 'Envoyer',
      cancel: 'Annuler',
    },
    labels: {
      name: 'Prénom',
      email: 'Adresse e-mail',
      comment: 'Commentaire',
    },
  });
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HoneybadgerErrorBoundary
    honeybadger={honeybadger}
    ErrorComponent={ErrorComponent}
  >
    <ManywattContext.Provider value={{ honeybadger }}>
      <ClearBrowserCacheBoundary
        auto={false}
        fallback={
          <LoadingOverlay
            active
            spinner={
              <Lottie
                animationData={sun}
                loop
                autoplay
                style={{
                  height: 200,
                  width: 200,
                }}
              />
            }
          />
        }
        duration={30000}
      >
        <Provider store={store}>
          <FlagProvider config={unleashConfig}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mainTheme}>
                <ProLocalizationProvider dateAdapter={AdapterMoment}>
                  <Wrapper
                    apiKey="AIzaSyA0hlZK80Z2U82FlQ-1TObbBFVjzpEpA6Q"
                    libraries={['places', 'geometry', 'drawing']}
                  >
                    <Main />
                  </Wrapper>
                </ProLocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </FlagProvider>
        </Provider>
      </ClearBrowserCacheBoundary>
    </ManywattContext.Provider>
  </HoneybadgerErrorBoundary>,
);
